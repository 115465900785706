<div class="row collapse">
	<div class="small-10 columns">
		<input type="search" [(ngModel)]="searchQuery" name="searchQuery" #searchQueryInput placeholder="{{'search'|translate}}: {{'zone' |translate}}">
	</div>
	<div class="small-2 columns">
		<span class="postfix"><i class="fi-magnifying-glass"></i></span>
	</div>
</div>
<table class="full-width" role="grid">
	<tbody>
		<tr *ngFor="let hotkeyCfg of hotkeys | filter:searchQuery; let i = index">
			<td>
				<span *ngIf="i === 0 || hotkeys[i - 1].category !== hotkeyCfg.category">
					{{ hotkeyCfg.category }}
				</span>
			</td>
			<td>
				<span class="sequence" *ngFor="let hotkey of hotkeyCfg.keySeq">
					<span *ngIf="hotkey.modifiers?.length &gt; 0">
						<span class="modifier" *ngFor="let mod of hotkey.modifiers">
							<kbd>{{ mod }}</kbd>
						</span>
						&nbsp;
					</span>
					<span>
						<kbd>{{ hotkey.key }}</kbd>
					</span>
				</span>
			</td>
			<td>{{ hotkeyCfg.command }}</td>
			<td><small>{{ hotkeyCfg.description }}</small></td>
		</tr>
	</tbody>
</table>
