import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

/** Draws icon data URLs using canvas */
@Component({
	selector: 'canvas-to-png',
	templateUrl: './canvas-to-png.component.html',
})
export class CanvasToPngComponent implements AfterViewInit {
	@ViewChild('canvas') canvas: ElementRef;
	cx: CanvasRenderingContext2D;

	ngAfterViewInit() {
		// get the context to draw on
		const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
		this.cx = canvasEl.getContext('2d');
	}

	/** Draw a circle with text and return as png
	 * @param radiusAddPixel the number of pixels between the text and the circle border (default 3)
	 */
	public fillTextCircleToPng(text: string, fontColour: string, fontSize: number, bgColour: string, borderWidth: number, borderColour: string, radiusAddPixel?: number): {width: number, height: number, img: string} {
		radiusAddPixel = radiusAddPixel ? radiusAddPixel : 3
		const cEl = this.canvas.nativeElement;

		// Measure the text size
		this.cx.font = `${fontSize}px Verdana`;
		this.cx.strokeStyle = fontColour;
		const metrics = this.cx.measureText(text);
		const textWidth = Math.abs(metrics.actualBoundingBoxLeft) + Math.abs(metrics.actualBoundingBoxRight);
		const textHeight = Math.abs(metrics.actualBoundingBoxAscent) + Math.abs(metrics.actualBoundingBoxDescent);

		// set the width and height
		let maxWidthHeight = Math.ceil(Math.max(textWidth, textHeight)) + 2 * radiusAddPixel + 2 * borderWidth;
		maxWidthHeight = maxWidthHeight % 2 === 0 ? maxWidthHeight : maxWidthHeight + 1; // Round up to the next even number (makes drawing things in the center easier)
		const center = Math.round(maxWidthHeight / 2);
		cEl.width = maxWidthHeight;
		cEl.height = maxWidthHeight;

		// Now draw the circle
		this.cx.fillStyle = bgColour;
		this.cx.strokeStyle = borderColour
		this.cx.lineWidth = borderWidth;
		this.cx.lineCap = 'round';
		this.cx.beginPath();
		const circleRadius = Math.floor(center - borderWidth / 2);
		this.cx.arc(center, center, circleRadius, 0, Math.PI * 2, true); // x, y, radius, startDeg, stopDeg, counterclockwise
		this.cx.fill(); // Draw the circle background
		this.cx.stroke(); // Draw the circle line

		// Draw the text on top
		const textOffsetLeft = Math.ceil(center - textWidth / 2); // center text in the middle
		const textOffsetTop = center + Math.round(metrics.actualBoundingBoxAscent / 2);
		this.cx.font = `${fontSize}px Verdana`;
		this.cx.strokeStyle = fontColour;
		this.cx.fillStyle = fontColour;
		// this.cx.textBaseline = 'middle';
		this.cx.fillText(text, textOffsetLeft, textOffsetTop);

		return { width: cEl.width, height: cEl.height, img: cEl.toDataURL('image/png') };
	}
}
