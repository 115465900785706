import jQuery from 'jquery';
import { Injectable, Inject, Output, Input, EventEmitter } from '@angular/core';
import { config } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { StateParams, StateService } from '@uirouter/core';
import { Location } from '@angular/common';;
import moment from 'moment';
import { MessagesService } from './messages.service';
import { LocalStorageService } from './LocalStorage.service';
import { BackendRestService } from './Restangular.service';
import { LoginService } from './login.service';
import { Restangular } from 'ngx-restangular';
import { BaseComponent } from '../base.component';
import { InfostarsToolsService } from './InfostarsTools.service';

export interface IMapPageApi {
	getMapCenter():google.maps.LatLng;
	getMapZoom():number;
}
@Injectable()
/**
 * @ngdoc function
 * @name infostars.service:DispoSettings
 * @description
 * # DispoSettings
 * Everything related to settings for the disposition part of the software
 */
export class DispoSettingsService extends BaseComponent {
	private settingsIdHashKeyPrefix = 'dispoSettingsId'; // The last activated settings
	private filterHashKey = 'infostarsFilter';
	private lastDateResetKey = 'infostarsLastDateReset'; // We reset the start/end date on day-change or after a long time has passed
	private settingsPromise:Promise<any> = null;
	private vehicleTreePromise:Promise<any[]> = null;
	private dispoActiveSettingsId = 0; // Which one of dispoSettings is active
	private dispoSettings:any[] = null; // All of DispoUserSettings
	private searchFilter:any = null;
	private defaultFilter:any = { // Will be set on top of DispoUserSettings whenever a filter is chosen
		start: moment().locale(config.BACKEND_DATE_LANG).subtract(1, 'days').startOf('day'),
		end: moment().locale(config.BACKEND_DATE_LANG).endOf('day').milliseconds(0), // 0 milli, as the input time widget will otherwise display milliseconds (ugly)
	};
	private searchFilterWatchEnabled = false;
	private vehicleTree:any[] = null;
	private mapPage:IMapPageApi;

	public searchFilter$ = new EventEmitter<any>();
	public vehicleTree$ = new EventEmitter<any[]>();

	constructor(
		@Inject(MessagesService) public Messages: MessagesService,
		@Inject(LocalStorageService) public localStorageService: LocalStorageService,
		@Inject(BackendRestService) public BackendRest: Restangular,
		@Inject(LoginService) public Login: LoginService,
		@Inject(InfostarsToolsService) public InfostarsTools: InfostarsToolsService,
	) {
		super(InfostarsTools);
		this.searchFilter = {...this.defaultFilter};
		let storedFilter = null;
		try {
			storedFilter = this.localStorageService.get(this.filterHashKey);
		}catch(e) {
			// Do nothing
		}
		if(storedFilter != null && Object.prototype.toString.call(storedFilter) === "[object Object]") {
			this.searchFilter = {...this.searchFilter, ...this.restoreFromLocalStorage(storedFilter)};
		}else {
			this.localStorageService.remove(this.filterHashKey);
		}
		this.subscribe(Login.logout$, () => this.clearData());
		this.subscribe(Login.accountId$, () => this.clearData());
	}
	private restoreFromLocalStorage(filter:any) {
		filter.start = moment(filter.start).locale(config.BACKEND_DATE_LANG);
		filter.end = moment(filter.end).locale(config.BACKEND_DATE_LANG);
		return filter;
	}
	// Transform the format we got from the backend into the frontend format and store it
	private saveSettingsData(result:any) {
		let standard = null; // The standard profile
		let activeDispoSettings = false; // The active DispoUserSettings
		result.forEach((res:any) => { // For each settings profile
			let isStandard = false;
			if(res.id === this.dispoActiveSettingsId)
				activeDispoSettings = res;
			if(res.name === 'Standard') {
				standard = res;
				isStandard = true;
			}

			// Transform the data a little bit, so it's easier for other code to send it to the backend as parameters
			res.vehicles = (res.vehicles || []).map((veh:any) => {
				if(veh.indexOf && veh.indexOf('v_') === 0) // There's also 'g_' for groups, TODO support that
					return Number(veh.substring(2));
				else if(!veh.indexOf) // Already a number (saveSettingsData called processed data)
					return Number(veh);
				return veh;
			});
			// Transform the data a little bit, so it's easier for other code to send it to the backend as parameters
			res.drivers = isStandard ? [] : (res.drivers || []).map((d:any) => d.id ? d.id : d); // The standard profile doesn't save drivers, all drivers are selected in that profile
			// static routes are a list of ids in the search filter
			res.staticRoutes = (res.staticRoutes || []).map((sr:any) => Number(sr.id));
			// geo areas are a list of ids in the search filter
			res.geoAreas = (res.geoAreas || []).map((ga:any) => Number(ga.id));
			res.showGeoAreas = (res.showGeoAreas || []).map((sGa:any) => Number(sGa.id));
			// Match the google map type strings
			res.mapType = res.mapViewType === 'Road' ? 'ROADMAP' : (res.mapViewType === 'Aerial' ? 'SATELLITE' : res.mapViewType);
			res.mapRefreshMinutes = 0;
			if(res.timerId > -1) {
				res.mapRefreshMinutes = Math.round(res.timerId / 60);
			}else if(res.customTimerIntervalSec > 0) {
				if(this.Login.hasAllRights('UNRESTRICTED_REFRESH')) {
					res.mapRefreshMinutes = res.customTimerIntervalSec / 60.0;
				}else {
					res.mapRefreshMinutes = Math.round(res.customTimerIntervalSec / 60);
				}
			}
		});
		this.searchFilter = {...this.searchFilter, ...(activeDispoSettings || standard || result[0]) };
		this.dispoSettings = result;
		this.localStorageService.add(this.settingsIdHashKeyPrefix + this.Login.getUser().id, this.dispoActiveSettingsId);
		// Check whether we have to reset start/end to the default
		let lastReset = this.localStorageService.get(this.lastDateResetKey);
		let shouldReset = true;
		if(lastReset) {
			shouldReset = moment().startOf('day').isAfter(moment(lastReset)) &&
					moment().diff(moment(lastReset)) > moment.duration(4, 'hours').as('milliseconds');
		}
		if(shouldReset) {
			Object.keys(this.defaultFilter).forEach((key:string) => { // Copy start/end
				this.searchFilter[key] = this.defaultFilter[key];
			});
			this.localStorageService.add(this.lastDateResetKey, new Date());
		}
		this.searchFilter$.emit(this.searchFilter);
	}
	private clearData() {
		this.dispoSettings = null;
		this.dispoActiveSettingsId = 0;
		this.settingsPromise = null;
		this.vehicleTreePromise = null;
		this.localStorageService.remove(this.filterHashKey);
		this.localStorageService.remove(this.settingsIdHashKeyPrefix); // The old way of storing this
		if (this.Login.getUser())
			this.localStorageService.remove(this.settingsIdHashKeyPrefix + this.Login.getUser().id); // The new way of storing this
	}
	private getData() {
		this.settingsPromise = this.Login.getLoginSuccessPromise().then(() => {
			let userId = this.Login.getUser().id;
			let storedSettingsId = this.localStorageService.get(this.settingsIdHashKeyPrefix + userId);
			if(!storedSettingsId) // Check the old way of storing this, for backwards compatibility
				storedSettingsId = this.localStorageService.get(this.settingsIdHashKeyPrefix);
			if(storedSettingsId)
				this.dispoActiveSettingsId = storedSettingsId;
			let params = {userId: userId};
			return new Promise<any>((resolve, reject) => {
				this.BackendRest.one('dispolight/settings').get(params).subscribe((result:any) =>  {
					this.saveSettingsData(result);
					this.searchFilterWatchEnabled = true;
					resolve(this.searchFilter);
				}, () => {
					this.clearData();
					reject();
				});
			});
		});
		return this.settingsPromise;
	}
	private getDataVehicleTree() {
		let isByAccount = this.Login.getAccountId() !== this.Login.getAccount().id;
		this.vehicleTreePromise = (isByAccount
				? this.BackendRest.one('vehiclegroup/vehicles/tree/byAccount').get({accountId: this.Login.getAccountId()})
				: this.BackendRest.one('vehiclegroup/vehicles/tree').get())
				.toPromise().then((tree:any) => {
						return [tree];
				});
		return this.vehicleTreePromise;
	}
	private transformToBackendSettings(settings:any) {
		let s = settings;
		delete s.start;
		delete s.end;
		let vehicles:any = [];
		settings.vehicles.forEach((v:string) => {
			vehicles.push('v_' + v);
		});
		s.vehicles = vehicles;
		let drivers:any = [];
		settings.drivers.forEach((d:any) => {
			drivers.push({id:d});
		});
		s.drivers = drivers;
		let staticRoutes:any = [];
		settings.staticRoutes.forEach((s:any) => {
			staticRoutes.push({id: s});
		});
		s.staticRoutes = staticRoutes;
		let geoAreas:any = [];
		settings.geoAreas.forEach((g:any) => {
			geoAreas.push({id: g});
		});
		s.geoAreas = geoAreas;
		let showGeoAreas:any = [];
		settings.showGeoAreas.forEach((g:any) => {
			showGeoAreas.push({id: g});
		});
		s.showGeoAreas = showGeoAreas;
		s.mapViewType = s.mapType === 'ROADMAP' ? 'Road' : (s.mapType === 'SATELLITE' ? 'Aerial' : s.mapType);
		delete s.mapType;
		s.timerId = -1;
		s.customTimerIntervalSec = 0;
		if(!s.mapRefreshMinutes || s.mapRefreshMinutes === 0) {
			s.timerId = -1;
		}else if(s.mapRefreshMinutes === 1) {
			s.timerId = 60;
		}else if(s.mapRefreshMinutes === 2) {
			s.timerId = 120;
		}else if(s.mapRefreshMinutes === 5) {
			s.timerId = 300;
		}else if(s.mapRefreshMinutes && s.mapRefreshMinutes > 0){
			s.customTimerIntervalSec = s.mapRefreshMinutes * 60;
		}
		delete s.mapRefreshMinutes;
		return s;
	}
	/** To be called after modifying the searchFilter object */
	public filterUpdated() {
		if(!this.searchFilterWatchEnabled) {
			return; // Only run this once the settings have been loaded
		}
		if(this.Login.loggedIn) // Storing when logged-out would break our initialisation with DispoSettings below
			this.localStorageService.add(this.filterHashKey, this.searchFilter);
		this.searchFilter$.emit(this.searchFilter);
	}
	/** To be called when backend data was modified and the vehicle tree needs to be reloaded (i.e. a vehicle or vehicle-group was added) */
	public vehicleTreeUpdated() {
		this.getDataVehicleTree().then(() => this.vehicleTree$.emit(this.vehicleTree));
	}
	// Save the currently active if name is false, save a new one with a name given
	public saveProfile(name?:string) {
		let settings = this.transformToBackendSettings(JSON.parse(JSON.stringify(this.searchFilter)));
		if(name) {
			delete settings.id;
			settings.name = name;
		}
		return new Promise<any>((resolve, reject) => {
			this.BackendRest.all('dispolight/settings').post(settings).subscribe((entity:any) => {
				if(name) {
					this.dispoActiveSettingsId = entity.id;
					this.dispoSettings.push(entity);
				}else {
					const i = this.dispoSettings.findIndex(x => x.id === entity.id)
					this.dispoSettings[i] = entity;
				}
				this.saveSettingsData(this.dispoSettings);
				resolve(this.searchFilter);
			}, () => {
				this.Messages.error('Error while saving settings');
				reject();
			});
		});
	}
	public setActive(id:number) {
		if(this.dispoActiveSettingsId === id)
			return;
		this.dispoSettings.some((dispoSett:any) => {
			let curr = dispoSett;
			if(dispoSett.id === id) {
				this.dispoActiveSettingsId = dispoSett.id;
				Object.keys(this.defaultFilter).forEach((key) => { // Copy start/end
					curr[key] = this.searchFilter[key];
				});
				this.searchFilter = dispoSett;
				this.localStorageService.add(this.settingsIdHashKeyPrefix + this.Login.getUser().id, dispoSett.id); // Store which settings was last activated
				this.filterUpdated();
				return true; // break
			}
			return false;
		});
	}
	public deleteActive() {
		if(this.searchFilter.name === 'Standard' && this.searchFilter.id) // Can't delete the standard settings profile
			return Promise.reject(false);
		return new Promise<any>((resolve, reject) => {
			this.BackendRest.one('dispolight/settings', this.dispoActiveSettingsId).remove().subscribe(() =>  {
				this.dispoActiveSettingsId = 0;
				this.dispoSettings.splice(this.dispoSettings.indexOf(this.searchFilter), 1);
				this.searchFilter = {};
				this.saveSettingsData(this.dispoSettings); // Will reset to the standarf settings profile
				resolve(this.searchFilter);
			}, () => {
				this.Messages.error('Error while deleting settings');
				reject();
			});
		});
	}
	/** Returns a promise to the search filter (gets data if not available) */
	public getPromise() {
		if(this.settingsPromise)
			return this.settingsPromise;
		return this.getData();
	}
	public getVehicleTreePromise() {
		if(this.vehicleTreePromise)
			return this.vehicleTreePromise;
		return this.getDataVehicleTree();
	}
	/** Returns the search filter (can be null) */
	public getSettings() {
		return this.searchFilter;
	}
	/** Returns the dispoSettings (can be null) */
	public getAllSettings() {
		return this.dispoSettings;
	}
	/** Returns the vehicle tree (can be null) */
	public getVehicleTree() {
		return this.vehicleTree;
	}
	public saveProfileAs(name:string) {
		return this.saveProfile(name);
	}
	public isDateLimitReached() {
		if(!this.searchFilter.end || !this.searchFilter.start)
			return false;
		return moment.duration(this.searchFilter.end.diff(this.searchFilter.start)).asDays() >= config.maxFilterDurationDays;
	}
	public resetStartEndDate() {
		return this.getPromise().then(() =>  {
			this.searchFilter.start = moment().locale(config.BACKEND_DATE_LANG).subtract(1, 'day').startOf('day');
			this.searchFilter.end = moment().locale(config.BACKEND_DATE_LANG).endOf('day');
			this.localStorageService.add(this.lastDateResetKey, new Date());
			this.filterUpdated();
		});
	}
	public setMapPage(mapPage:IMapPageApi) {
		this.mapPage = mapPage;
	}
	public clearMapPage() {
		this.mapPage = null;
	}
	public getMapCenter():google.maps.LatLng {
		if(this.mapPage)
			return this.mapPage.getMapCenter();
		return null;
	}
	public getMapZoom():number {
		if(this.mapPage)
			return this.mapPage.getMapZoom();
		return null;
	}
}
