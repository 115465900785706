import { Inject, EventEmitter, Injectable } from "@angular/core";
import { Router, ActivatedRoute, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd } from '@angular/router';
import { BaseComponent } from "../base.component";
import { InfostarsToolsService } from "./InfostarsTools.service";
import { filter } from "rxjs/operators";
import { Destroyable } from "../directives/destroyable.directive";

/** Disables the navigation top bar for the current page */
@Injectable()
export class ShowNavService extends Destroyable { // Can't extends BaseComponent, as it would create a cyclic dependency with InfostarsTools (depends on us)
	public showNav: boolean = true;
	public showNav$ = new EventEmitter<boolean>();

	constructor(
		@Inject(Router) public $router: Router,
	) {
		super();
		this.subscribe(this.$router.events.pipe(filter(event => event instanceof NavigationEnd)), (ev: NavigationEnd) => {
			const data = this.getAllRouteData(this.$router.routerState.snapshot.root);
			const newShowNav = data['hideNav'] ? false : true;
			if(newShowNav === this.showNav)
				return; // No change, prevent flickering the navbar when switching between /en and /en/video
			this.showNav = newShowNav;
			this.showNav$.emit(this.showNav);
		});
	}
	/** Hide the navigation bar until the next page load */
	public hideNav() {
		this.showNav = false;
		this.showNav$.emit(this.showNav);
	}
	public getAllRouteData(route: ActivatedRouteSnapshot): {[name: string]: any} {
		const data = {};
		return this.getAllRouteDataInternal(route, data);
	}
	private getAllRouteDataInternal(route: ActivatedRouteSnapshot, data: {[name: string]: any}): {[name: string]: any} {
		Object.keys(route.data).forEach(k => data[k] = route.data[k]);
		route.children.forEach(c => this.getAllRouteDataInternal(c, data));
		return data;
	}
}
