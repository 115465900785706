import { Component, ViewChild, Inject, Output, Input, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { config } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { StateParams, StateService } from '@uirouter/core';
import { Location } from '@angular/common';
import { BaseComponent } from '../base.component';
import { BackendRestService } from 'src/app/services/Restangular.service';

import moment from 'moment';
import { InfostarsToolsService } from '../services/InfostarsTools.service';

export interface TimeUiDetails {
	dateTime: moment.Moment;
}

// Must use Moment
@Component({
	selector: 'time-ui',
	templateUrl: './time-ui.html',
})
export class TimeUiComponent extends BaseComponent implements OnInit, OnDestroy {
	@Input()
	get details(): TimeUiDetails { return this._details; }
	set details(details:TimeUiDetails) { this.updateDetails(details, this._details); this._details = details; }
	_details:TimeUiDetails; // Needs to contain a .dateTime property which will be a momentjs date object
	@Input() prefix:string; // The label for the time input
	@Input() name:string; // The name attribute of the input field
	@Input() disableInput = false; // Whether to set the input to disabled
	@Output() timeChange = new EventEmitter<TimeUiDetails>(); // Emitted when UI has been changed (this.details is already updated when called)
	@Output() uiChange = new EventEmitter<void>(); // Emitted when UI has been changed (before updating the model)

	time = { at: null as string }; // time.at will contain e.g. '16:45' see <input type="time"> HTML docs

	constructor(
		protected InfostarsTools: InfostarsToolsService,
	) { super(InfostarsTools); }

	ngOnInit() {
		//TODO
		// this.$watch('time.at', onNgModelChange);
		// this.$watch('details', updateDetails);
	}
	ngOnDestroy(): void {
		super.ngOnDestroy();
	}
	// The UI model has changed, update the external date model
	public onNgModelChange(newVal:any) {
		if (newVal && this.time.at){
			this.details.dateTime = moment(moment().format('YYYY-MM-DD') + 'T' + this.time.at + ':00');
		} else if(newVal === null){
			this.details.dateTime = null;
		}
		this.timeChange.emit(this.details);
	}
	// Handler for (change) on <input> in template
	onChange() {
		this.uiChange.emit();
	}
	// The external date model has changed, update the internal model and UI
	private updateDetails(newVal:TimeUiDetails, oldVal:TimeUiDetails) {
		if (newVal === oldVal)
			return;
		if (this.isNotPresent(newVal) || this.isNotPresent(newVal.dateTime))
			this.time.at = null;
		else
			this.time.at = moment.isMoment(newVal.dateTime) ? newVal.dateTime.format('HH:mm') : moment(newVal.dateTime).format('HH:mm');
	}
	private isNotPresent(value:any) {
		return (typeof (value) === 'undefined' || value === null);
	}
}
