import { Pipe, PipeTransform, Inject, Input } from "@angular/core";

/** Filters a list of vehicle items by a map of vehicle ids to show. Keeps vehicles which are 'loose', i.e. have no vehicle groups */
@Pipe({
	name: 'vehicleFilter',
	pure: true,
})
export class VehicleFilterPipe implements PipeTransform {
	transform(array:any, vehicleIds:{[key:number]:boolean}) {
		if(!array || !array.length || !vehicleIds || jQuery.isEmptyObject(vehicleIds))
			return array;
		return array.filter((item:any) => {
			return item.loose || vehicleIds[item.id];
		});
	}
}
