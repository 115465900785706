<nav class="top-bar row" data-topbar role="navigation">
	<ul class="title-area">
		<li class="name">
			<h1 class="menu-icon">
				<!-- XXXAngularIO <a (click)="onOpenMenu($event)" [ngClass]="{'hide': !$root.filterControl.openMenu}"><span>{{ 'filter' | translate }}</span></a> -->
				<a (click)="onOpenMenu($event)" [ngClass]="{'hide': !enableFilter}"><span>{{ 'filter' | translate }}</span></a>
			</h1>
		</li>
		<li class="toggle-topbar menu-icon">
			<a><span>{{'menu'|translate}}</span></a>
		</li>
	</ul>

	<section class="top-bar-section">
		<ul class="right">
			<li routerLinkActive="active" [hidden]="loggedIn"><a routerLink="/{{ activeLang }}" (click)="fixTopbarMobile($event)">Home</a></li>
			<li routerLinkActive="active" [hidden]="loggedIn"><a routerLink="/{{ activeLang }}/login" (click)="fixTopbarMobile($event)">{{ 'login' | translate }}</a></li>
			<li routerLinkActive="active" [hidden]="!(loggedIn &amp;&amp; !isAnonLogin)">
				<a routerLink="/{{ activeLang }}/login" (click)="fixTopbarMobile($event)">
					<menu-label [label]="username" [hotkeyCfg]="{ keys: 'm→u', category: 'cmdCatMenu', command: 'cmdPageUser', description: 'cmdDescPageUser'}"></menu-label>
					<span [hidden]="!forcedAccountName" class="forced-account-username">({{ forcedAccountName }})</span>
				</a>
			</li>
			<li [hidden]="!(loggedIn &amp;&amp; isAnonLogin)"><a (click)="onLogout()">{{ 'logout' | translate }}</a></li>
			<!-- Main menu for desktop -->
			<li class="show-for-large-up" routerLinkActive="active" *ngIf="loggedIn &amp;&amp; (userRights | hasPermission:'SHOW_DISPO_TAB')">
				<a routerLink="/{{ activeLang }}/dispolight">
					<menu-label labelTransl="dispoLight" highlightChar="d" [hotkeyCfg]="{ keys: 'm→d', category: 'cmdCatMenu', command: 'dispoLight', description: 'cmdDescPageDispoLight'}"></menu-label>
				</a>
			</li>
			<li class="show-for-large-up has-dropdown not-click" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_DISPO_TAB AND SHOW_DISPO_LOGBOOK'">
				<a>{{'logbook'|translate}}</a>
				<ul class="dropdown">
					<li class="show-for-large-up" routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="(userRights | hasPermission:'SHOW_DISPO_TAB AND SHOW_DISPO_LOGBOOK') &amp;&amp; (trackType !== 'ANIMAL' || (userRights | hasPermission:'ROLE_SUPER_SYSTEM_ADMINISTRATOR'))">
						<a routerLink="/{{ activeLang }}/logbook">
							<menu-label labelTransl="logbook" [trackType]="trackType" highlightChar="l" [hotkeyCfg]="{ keys: 'm→l', category: 'cmdCatMenu', command: 'logbook', description: 'cmdDescPageLogbook'}"></menu-label>
						</a>
					</li>
					<li class="show-for-large-up" routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="(userRights | hasPermission:'SHOW_DISPO_TAB AND SHOW_DISPO_LOGBOOK') &amp;&amp; (trackType === 'ANIMAL' || (userRights | hasPermission:'ROLE_SUPER_SYSTEM_ADMINISTRATOR'))">
						<a routerLink="/{{ activeLang }}/assetlogbook">
							<menu-label labelTransl="logbook_animal" highlightChar="l" [hotkeyCfg]="{ keys: (userRights | hasPermission:'ROLE_SUPER_SYSTEM_ADMINISTRATOR') ? 'm→shift.l' : 'm→l', category: 'cmdCatMenu', command: 'logbook_animal', description: 'cmdDescPageLogbook_animal'}"></menu-label>
						</a>
					</li>
					<li class="show-for-large-up" routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_DISPO_TAB AND SHOW_DISPO_LOGBOOK'">
						<a routerLink="/{{ activeLang }}/zonelogbook">
							<menu-label labelTransl="zoneLogbook" highlightChar="z" [hotkeyCfg]="{ keys: 'm→z', category: 'cmdCatMenu', command: 'zoneLogbook', description: 'cmdDescPageZoneLogbook', trackType: trackType}"></menu-label>
						</a>
					</li>
				</ul>
			<li>
			<li class="show-for-large-up" routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_ALARMSTAR'">
				<a routerLink="/{{ activeLang }}/alarmstar">
					<menu-label labelTransl="alarmStar" highlightChar="a" [hotkeyCfg]="{ keys: 'm→a', category: 'cmdCatMenu', command: 'alarmStar', description: 'cmdDescPageAlarmStar'}"></menu-label>
				</a>
			</li>
			<li class="show-for-large-up" routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_FAFB'">
				<a routerLink="/{{ activeLang }}/falogbook">
					<menu-label labelTransl="faLogbook" highlightChar="f" [hotkeyCfg]="{ keys: 'm→f', category: 'cmdCatMenu', command: 'faLogbook', description: 'cmdDescPageFaLogbook'}"></menu-label>
				</a>
			</li>
			<li class="show-for-large-up" routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_FUEL_TAB'">
				<a routerLink="/{{ activeLang }}/fuelstar">
					<menu-label labelTransl="fuelStar" highlightChar="e" [hotkeyCfg]="{ keys: 'm→e', category: 'cmdCatMenu', command: 'fuelStar', description: 'cmdDescPageFuelStar'}"></menu-label>
				</a>
			</li>
			<li class="show-for-large-up" routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_TAXI_TOURS'">
				<a routerLink="/{{ activeLang }}/taxilog">
					<menu-label labelTransl="ytTaxilog" highlightChar="t" [hotkeyCfg]="{ keys: 'm→t', category: 'cmdCatMenu', command: 'ytTaxilog', description: 'cmdDescPageTaxiLog'}"></menu-label>
				</a>
			</li>
			<li class="show-for-large-up" routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_MAINTENANCE'">
				<a routerLink="/{{ activeLang }}/maintenance">
					<menu-label labelTransl="maintenanceTool" highlightChar="m" [hotkeyCfg]="{ keys: 'm→m', category: 'cmdCatMenu', command: 'maintenanceTool', description: 'cmdDescPageMaintenanceTool'}"></menu-label>
				</a>
			</li>
			<!-- == Main menu for mobile == -->
			<li class="has-dropdown not-click hide-for-large-up" [hidden]="!(loggedIn)">
				<a>{{'overview'|translate}}</a>
				<ul class="dropdown">
					<li routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_DISPO_TAB'">
						<a routerLink="/{{ activeLang }}/dispolight" (click)="fixTopbarMobile($event)">{{ 'dispoLight' | translate }}</a>
					</li>
					<li routerLinkActive="active" [hidden]="!(loggedIn &amp;&amp; trackType !== 'ANIMAL' &amp;&amp; !(userRights | hasPermission:'ROLE_SUPER_SYSTEM_ADMINISTRATOR'))" *ngIf="userRights | hasPermission:'SHOW_DISPO_TAB AND SHOW_DISPO_LOGBOOK'">
						<a routerLink="/{{ activeLang }}/logbook" (click)="fixTopbarMobile($event)">{{ 'logbook' | byTrackType:trackType | translate }}</a>
					</li>
					<li routerLinkActive="active" [hidden]="!(loggedIn &amp;&amp; trackType === 'ANIMAL' &amp;&amp; !(userRights | hasPermission:'ROLE_SUPER_SYSTEM_ADMINISTRATOR'))" *ngIf="userRights | hasPermission:'SHOW_DISPO_TAB AND SHOW_DISPO_LOGBOOK'">
						<a routerLink="/{{ activeLang }}/assetlogbook" (click)="fixTopbarMobile($event)">{{ 'logbook' | byTrackType:trackType | translate }}</a>
					</li>
					<li class="has-dropdown not-click" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'ROLE_SUPER_SYSTEM_ADMINISTRATOR'">
						<a>{{'logbook'|translate}}</a>
						<ul class="dropdown">
							<li routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_DISPO_TAB AND SHOW_DISPO_LOGBOOK'">
								<a routerLink="/{{ activeLang }}/logbook" (click)="fixTopbarMobile($event)">{{ 'logbook' | translate }}</a>
							</li>
							<li routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_DISPO_TAB AND SHOW_DISPO_LOGBOOK'">
								<a routerLink="/{{ activeLang }}/assetlogbook" (click)="fixTopbarMobile($event)">{{ 'logbook_animal' | translate }}</a>
							</li>
							<li routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_DISPO_TAB AND SHOW_DISPO_LOGBOOK'">
								<a routerLink="/{{ activeLang }}/zonelogbook">{{ 'zoneLogbook' | translate }}</a>
							</li>
						</ul>
					<li>
					<li routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_ALARMSTAR'">
						<a routerLink="/{{ activeLang }}/alarmstar" (click)="fixTopbarMobile($event)">{{ 'alarmStar' | translate }}</a>
					</li>
					<li routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_FAFB'">
						<a routerLink="/{{ activeLang }}/falogbook" (click)="fixTopbarMobile($event)">{{ 'faLogbook' | translate }}</a>
					</li>
					<li routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_FUEL_TAB'">
						<a routerLink="/{{ activeLang }}/fuelstar" (click)="fixTopbarMobile($event)">{{ 'fuelStar' | translate }}</a>
					</li>
					<li routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_TAXI_TOURS'">
						<a routerLink="/{{ activeLang }}/taxilog-noparam" (click)="fixTopbarMobile($event)">{{ 'ytTaxilog' | translate }}</a>
					</li>
					<li routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_MAINTENANCE'">
						<a routerLink="/{{ activeLang }}/maintenance" (click)="fixTopbarMobile($event)">{{ 'maintenanceTool' | translate }}</a>
					</li>
				</ul>
			</li>
			<!-- ========================== -->
			<li class="has-dropdown not-click" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_ADMIN_TAB'" #linkSettingsMenu>
				<a><menu-label labelTransl="settings" [target]="linkSettingsMenu" [addHover]="true" highlightChar="s" [hotkeyCfg]="{ keys: 'm→s', noFeedback: true, category: 'cmdCatMenu', command: 'cmdMenuSettings', description: 'cmdDescMenuSettings'}"></menu-label></a>
				<ul class="dropdown">
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_ADDITIONAL_SETTINGS'">
						<a routerLink="/{{ activeLang }}/admin/usersettings" (click)="fixTopbarMobile($event)">
							<menu-label labelTransl="settings" highlightChar="s" [hotkeyCfg]="{ keys: 'm→s→s', category: 'cmdCatAdminMenu', command: 'settings', description: 'cmdDescAdminMenuSettings'}"></menu-label>
						</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_ZONES'">
						<a routerLink="/{{ activeLang }}/admin/zone" (click)="fixTopbarMobile($event)">
							<menu-label labelTransl="geoAreas" highlightChar="z" [hotkeyCfg]="{ keys: 'm→s→z', category: 'cmdCatAdminMenu', command: 'geoAreas', description: 'cmdDescAdminMenuGeoAreas'}"></menu-label>
						</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_STATIC_ROUTE'">
						<a routerLink="/{{ activeLang }}/admin/staticroute" (click)="fixTopbarMobile($event)">
							<menu-label labelTransl="staticRoutes" highlightChar="o" [hotkeyCfg]="{ keys: 'm→s→o', category: 'cmdCatAdminMenu', command: 'staticRoutes', description: 'cmdDescAdminMenuStaticRoutes'}"></menu-label>
						</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_ROUTINES'">
						<a routerLink="/{{ activeLang }}/admin/routine" (click)="fixTopbarMobile($event)">
							<menu-label labelTransl="routines" highlightChar="r" [hotkeyCfg]="{ keys: 'm→s→r', category: 'cmdCatAdminMenu', command: 'routines', description: 'cmdDescAdminMenuRoutines'}"></menu-label>
						</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_TOUR_STATUS_CUSTOM'">
						<a routerLink="/{{ activeLang }}/admin/tourstatuscustom" (click)="fixTopbarMobile($event)">
							<menu-label labelTransl="tourStatusCustom" highlightChar="t" [hotkeyCfg]="{ keys: 'm→s→t', category: 'cmdCatAdminMenu', command: 'tourStatusCustom', description: 'cmdDescAdminMenuTourStatusCustom'}"></menu-label>
						</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_VEHICLES'">
						<a routerLink="/{{ activeLang }}/admin/vehicle" (click)="fixTopbarMobile($event)">
							<menu-label labelTransl="vehicles" [trackType]="trackType" highlightChar="v" [hotkeyCfg]="{ keys: 'm→s→v', category: 'cmdCatAdminMenu', command: 'vehicles', description: 'cmdDescAdminMenuVehicles', trackType: trackType}"></menu-label>
						</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_VEHICLE_GROUPS'">
						<a routerLink="/{{ activeLang }}/admin/vehiclegroup" (click)="fixTopbarMobile($event)">
							<menu-label labelTransl="vehicleGroups" [trackType]="trackType" highlightChar="v" [hotkeyCfg]="{ keys: 'm→s→shift.v', category: 'cmdCatAdminMenu', command: 'vehicleGroups', description: 'cmdDescAdminMenuVehicleGroups', trackType: trackType}"></menu-label>
						</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_DRIVERS'">
						<a routerLink="/{{ activeLang }}/admin/driver" (click)="fixTopbarMobile($event)">
							<menu-label labelTransl="vehicleDriver" highlightChar="d" [hotkeyCfg]="{ keys: 'm→s→d', category: 'cmdCatAdminMenu', command: 'vehicleDriver', description: 'cmdDescAdminMenuDrivers'}"></menu-label>
						</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_DRIVER_GROUPS'">
						<a routerLink="/{{ activeLang }}/admin/drivergroup" (click)="fixTopbarMobile($event)">
							<menu-label labelTransl="driverGroups" highlightChar="d" [hotkeyCfg]="{ keys: 'm→s→shift.d', category: 'cmdCatAdminMenu', command: 'driverGroups', description: 'cmdDescAdminMenuDriverGroups'}"></menu-label>
						</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_DRIVER_CARDS'">
						<a routerLink="/{{ activeLang }}/admin/drivercard" (click)="fixTopbarMobile($event)">
							<menu-label labelTransl="driverCards" highlightChar="c" [hotkeyCfg]="{ keys: 'm→s→c', category: 'cmdCatAdminMenu', command: 'driverCards', description: 'cmdDescAdminMenuDriverCards'}"></menu-label>
						</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_GPS_EQUIPMENTS'">
						<a routerLink="/{{ activeLang }}/admin/gpsequipment" (click)="fixTopbarMobile($event)">
							<menu-label labelTransl="gpsDevices" highlightChar="g" [hotkeyCfg]="{ keys: 'm→s→g', category: 'cmdCatAdminMenu', command: 'gpsDevices', description: 'cmdDescAdminMenuGpsDevices'}"></menu-label>
						</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_VEHICLE_EQUIPMENT_MAPPINGS'">
						<a routerLink="/{{ activeLang }}/admin/vehicleequipment" (click)="fixTopbarMobile($event)">
							<menu-label labelTransl="vehicleEquipmentMapping" [trackType]="trackType" highlightChar="m" [hotkeyCfg]="{ keys: 'm→s→m', category: 'cmdCatAdminMenu', command: 'vehicleEquipmentMapping', description: 'cmdDescAdminMenuVehicleEquipmentMapping', trackType: trackType}"></menu-label>
						</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_USERS'">
						<a routerLink="/{{ activeLang }}/admin/user" (click)="fixTopbarMobile($event)">
							<menu-label labelTransl="user" highlightChar="u" [hotkeyCfg]="{ keys: 'm→s→u', category: 'cmdCatAdminMenu', command: 'user', description: 'cmdDescAdminMenuUsers'}"></menu-label>
						</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_USER_RIGHT_GROUPS'">
						<a routerLink="/{{ activeLang }}/admin/userrightgroup" (click)="fixTopbarMobile($event)">
							<menu-label labelTransl="userRights" highlightChar="u" [hotkeyCfg]="{ keys: 'm→s→shift.u', category: 'cmdCatAdminMenu', command: 'userRights', description: 'cmdDescAdminMenuUserRights'}"></menu-label>
						</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_ACCOUNTS'">
						<a routerLink="/{{ activeLang }}/admin/account" (click)="fixTopbarMobile($event)">
							<menu-label labelTransl="account" highlightChar="a" [hotkeyCfg]="{ keys: 'm→s→a', category: 'cmdCatAdminMenu', command: 'account', description: 'cmdDescAdminMenuAccounts'}"></menu-label>
						</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_DATA_INTERFACE'">
						<a routerLink="/{{ activeLang }}/admin/datainterface" (click)="fixTopbarMobile($event)">
							<menu-label labelTransl="dataInterface" highlightChar="i" [hotkeyCfg]="{ keys: 'm→s→i', category: 'cmdCatAdminMenu', command: 'dataInterface', description: 'cmdDescAdminMenuDataInterface'}"></menu-label>
						</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_USER_TRANSLATION'">
						<a routerLink="/{{ activeLang }}/admin/usertranslation" (click)="fixTopbarMobile($event)">
							<menu-label labelTransl="userTranslation" highlightChar="t" [hotkeyCfg]="{ keys: 'm→s→shift.t', category: 'cmdCatAdminMenu', command: 'userTranslation', description: 'cmdDescAdminMenuUserTranslation'}"></menu-label>
						</a>
					</li>
				</ul>
			</li>
			<!-- ========================== -->
			<li class="has-dropdown not-click" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'ROLE_SUPER_SYSTEM_ADMINISTRATOR'" #linkSetupMenu>
				<a><menu-label labelTransl="setup" [target]="linkSetupMenu" [addHover]="true" highlightChar="p" [hotkeyCfg]="{ keys: 'm→p', noFeedback: true, category: 'cmdCatMenu', command: 'setup', description: 'cmdDescMenuSetup'}"></menu-label></a>
				<ul class="dropdown">
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_STATI'">
						<a routerLink="/{{ activeLang }}/admin/status" (click)="fixTopbarMobile($event)">
							<menu-label labelTransl="status" highlightChar="s" [hotkeyCfg]="{ keys: 'm→p→s', category: 'cmdCatSetupMenu', command: 'status', description: 'cmdDescSetupMenuStatus'}"></menu-label>
						</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'ADD_GPS_EQUIPMENTS'">
						<a routerLink="/{{ activeLang }}/admin/equipmentaccsetup" (click)="fixTopbarMobile($event)">
							<menu-label labelTransl="setupTitle" highlightChar="e" [hotkeyCfg]="{ keys: 'm→p→e', category: 'cmdCatSetupMenu', command: 'setupTitle', description: 'cmdDescSetupMenuEquipAcc'}"></menu-label>
						</a>
					</li>
				</ul>
			</li>
			<li class="active" [hidden]="!updateAvailable"> <!-- active hightlights it, it's a bit of a misuse but visually fine -->
				<a (click)="onUpdateApp()" title="{{ 'newVersionAvailablePleaseUpdate' | translate }}"><i class="fi-refresh"></i>&nbsp;</a>
			</li>
		</ul>
	</section>
</nav>
