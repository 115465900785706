import { Injectable } from "@angular/core";

@Injectable()
export class MapToolsService {
	public createCircle(id:number, lat:number, lon:number, radius:number) {
		const center = new google.maps.LatLng(lat, lon);
		return {
			id: 'circle' + id,
			center: center,
			bounds: new google.maps.Circle({
				center: center,
				radius: radius
			}).getBounds(),
			radius: radius,
			fillColor: '#008000',
			fillOpacity: 0.3,
			strokeWeight: 3,
			strokeColor: '#0000FF',
			strokeOpacity: 0.5,
			draggable: false
		};
	}

	public createRectangle(id:number, latitude:number, longitude:number, width:number, height:number) {
		const center = new google.maps.LatLng(latitude, longitude);
		const ne = google.maps.geometry.spherical.computeOffset(google.maps.geometry.spherical.computeOffset(center, width, 90), height, 0);
		const sw = google.maps.geometry.spherical.computeOffset(google.maps.geometry.spherical.computeOffset(center, width, 270), height, 180);
		const bounds = new google.maps.LatLngBounds(sw, ne);
		return {
			id: 'rect' + id,
			center: center,
			bounds: bounds,
			fillColor: '#008000',
			fillOpacity: 0.3,
			strokeWeight: 3,
			strokeColor: '#0000FF',
			strokeOpacity: 0.5
		};
	}
}
