import { Pipe, PipeTransform, Inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({name: 'translateEnum'})
export class TranslateEnumPipe implements PipeTransform {
	constructor(@Inject(TranslateService) private $translate:TranslateService) {}

	transform(value:string, enumName:string, params?:any) {
		return this.$translate.instant('ENUM_' + enumName + '_' + value, params);
	}
}