<div class="vehicle-tree" [ngStyle]="groupMargin">
	<form id="driver-filter-form" *ngIf="level==0">
		<input type="text" [(ngModel)]="driverFilter.name" #nameInput="ngModel" name="driver-filter-name" (ngModelChange)="onFilterChange()" />
	</form>
	<div class="group-node">
		<div>
			<img class="open-image" [hidden]="!(driverNode.opened)" (click)="driverNode.opened=false" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACfElEQVR4Xu3Yz6tIQRQH8M+LsMAGEVnJUlkiRUJJSn4k2SlRFqJEbBUWSnoLip1CSkkhyQJF+QdkwY6i2JHyq9F9m+fl3Tt35r5bd2Z9zvl+z3e+587MHTHwNTLw/hUBigMGrkAZgYEboHwEywiUERi4AmUEBm6AcgqUESgjMHAFyggM3ADlFIgZge24jIU9c88HHMS9JrxiBPiE+U1AOoz92HRjYgQISi/qsKkmUO+xpElCjABbcRfTmgB1EPsD2/CwCVaMAKH+YYw2Aeog9gCuNsWJFSDgXMCxpoCZ4s/iVEztNgKE3NvYGQOcMOcG9uF3TM02AgS8WXiC1THgCXKeYjO+x9ZqK0DAXYAXWBZLIjLvNdbgS2T+37QUAoQ6yysR5rUh0yA3nPer8K5BzoShqQQIxdfiMWa2JTVJ/lesx6sUOCkFCHz2IHyUUtcd6/UXdlT3kBT9ZyF6AueSsPu3yBFcSlk7106Fx1J4mKRcF3E0ZcGUH8HxvMI1ObzKtiQifAe7EUYg6crlgEByNp5hZUvGL7EB31rWyX4KTASwGKGBpZHk31bHXXiCZ1k5HTBGeAWeY27DDj5XN8w3DfMahXchQCC0CfcxvSa7cLXdWAlXMyUurCsBArv9uFaDZnjU7MWtGrGtQ7oUIJA9g9OTsD6J8607q1mgawECrevV83UiildwqCb3JGFTIcAMPMK6cR08qH5p/UzSWc0iUyFAoDan+qO0q7rc3MTxXGf9/7SYKgFq7k/+sCJAfo37jVAc0O/9yc+uOCC/xv1GKA7o9/7kZ1cckF/jfiMUB/R7f/KzKw7Ir3G/EYoD+r0/+dkVB+TXuN8Ig3fAH8alQUH85V+3AAAAAElFTkSuQmCC" />
			<img class="open-image" [hidden]="driverNode.opened" (click)="driverNode.opened=true" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACZElEQVR4Xu2aPUsdQRSGn/sbUgT/UohR0xkt/ECJpgghpLFQCBapgoig+AGCBK2UkCIhICHEQhFbMYEYbIImoDYqfqByYG538c7inD1zmdl2Z8+873POzO7MbIXEr0ri/skAcgUkTiAPgcQLIE+CeQjkIZA4gTwEFAqgFZgBroAe4LNCH8FCalTAPvDQKRQIgw5IMNEhA2kAuKkh8B0wBNS6F9JP4VhlARBhi0A3cF5YpeIDZQIQGz8AmSMOFT0VCl02ABH3E3gE7BZSqtTYAoBY+Qc8ATaUfHmHtQIgAs+ADmDFW61CQ0sAYucaeA2MKXjzCmkNoCpyHHjlgHgJD9UoFgDi5yPwDDgNZc4nTkwARO8m0Awc+IgP0SY2AOLpj3tN7oQwWC9GjABE8xHQBnyvZ+C+92MFIL4u3Gryw31N3vV8zABEtyyehoFRLQixA6j6ngOeuz2GoCwaBYCY/go8BU5CEmgkAOJ70m2wBGPQaABkGf0gmHtQORjR3PVJHsAUMJBqBawCLalOgvNAP3AZMvsSqxEmwRHgbWjj1XgxA5Bs9wILWuZjroBj99HzTdN8rAD23HJ4W9t8jAC2gMeAHK+VcsU0B3wC2kO/5upRjAXABPAyxU1R2RZ/A7yvlymt+5YVIAcjncCyljmfuFYA/rujsXUfkZptLAD8cq+535rGfGOXDWDNLWiSPB5fArpS/UEiqV9k/gJNbgzKT1IvgGnfMVl2O405QM72Zt3BRh/wpWxTRfrTAFCkf/O2GYB5CowF5AowToB597kCzFNgLCBXgHECzLvPFWCeAmMByVfALU00c0FQsGn7AAAAAElFTkSuQmCC" />
			<input type="checkbox" [(ngModel)]="driverNode.checked" #checkedInput="ngModel" (ngModelChange)="api.onDriverGroupToggled(driverNode)">
			<label class="node-title" (click)="driverNode.opened=!driverNode.opened">{{ driverNode?.groupName}}</label>
		</div>
		<div *ngIf="driverNode?.groups" [hidden]="!(driverNode?.opened)" class="child-nodes">
			<driver-tree *ngFor="let childNode of driverNode.groups" [driverNode]="childNode" [level]="level+1" [api]="api"></driver-tree>
		</div>
		<div class="vehicle-element" *ngFor="let driver of driverNode?.drivers" [hidden]="!(driverNode?.opened &amp;&amp; driver?.filtered)">
			<input type="checkbox" [(ngModel)]="driver.checked" #checkedInput="ngModel" (ngModelChange)="api.onDriverToggled(driver)">
			<label class="vehicle-title" (click)="onDriverClick(driver)">{{ driver?.name}}</label>
		</div>
	</div>
</div>
