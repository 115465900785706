import { Input, OnInit, Directive, ElementRef, OnDestroy, Output, EventEmitter, HostListener } from '@angular/core';

import Tether from "tether";

export interface OpenInfo {
	ev: MouseEvent;
	closeCb: () => void;
}

/** Directive for the https://github.com/shipshapecode/tether dropdown library */
@Directive({
	selector: '[tetherDrop]',
})
export class TetherDropDirective implements OnInit, OnDestroy {
	@Input() tetherElement:string;
	@Input() tetherOptions:any;
	@Input() tetherNoShowHide:boolean;
	@Output() beforeOpen = new EventEmitter<OpenInfo>();

	private tetherObj:any = false;
	private ignoreMenuClickEv:MouseEvent; // Set to the event that caused a menu state change, so that other ev handlers can ignore it

	constructor(protected element: ElementRef) {
	}
	ngOnInit(): void {
		if(!this.tetherNoShowHide)
			jQuery(this.tetherElement).attr('hidden', ''); // Hide the dropdown content
	}
	ngOnDestroy(): void {
		this.destroy();
		jQuery(this.element.nativeElement).add(jQuery(this.tetherElement)).off('click.tetherDrop');
	}
	private disable() {
		if(!this.tetherNoShowHide)
			jQuery(this.tetherElement).attr('hidden', ''); // Hide the dropdown content
		if(this.tetherObj) { // Close the dropdown
			this.tetherObj.disable();
			this.tetherObj = false;
		}
	}
	private destroy() {
		if(!this.tetherNoShowHide)
			jQuery(this.tetherElement).attr('hidden', ''); // Hide the dropdown content
		if(this.tetherObj) { // Close the dropdown and destroy all references
			this.tetherObj.destroy();
			this.tetherObj = false;
		}
	}

	@HostListener('click', ['$event'])
	protected onClickDrop(ev:MouseEvent) {
		if(this.tetherObj) { // Close the dropdown
			this.disable();
			return;
		}
		this.ignoreMenuClickEv = ev;
		this.beforeOpen.emit({ev: ev, closeCb: () => this.disable()});
		setTimeout(() => { // Do this to process closing event handlers first, as the user might open the menu and then click on another button that is supposed to open the same menu (same HTML elements for the popup) but with different data.
			// Open the dropdown and position it
			if(!this.tetherNoShowHide)
				jQuery(this.tetherElement).removeAttr('hidden');
			jQuery(this.tetherElement).off('click.tetherDrop');
			let tParams:any = {
				attachment: 'top left',
				targetAttachment: 'bottom left',
			};
			jQuery.extend(tParams, this.tetherOptions);
			tParams.element = this.tetherElement;
			tParams.target = this.element.nativeElement;
			this.tetherObj = new Tether(tParams); // position the dropdown
		})
	}
	@HostListener('document:click', ['$event'])
	protected onClickDocument(ev:MouseEvent) {
		if(ev === this.ignoreMenuClickEv)
			return; // We can't use ev.stopPropagation/preventDefault as we need the tether-drop directive to react to the click event still
		if(this.tetherObj) { // Close the dropdown
			this.disable();
			return;
		}
	}
}
