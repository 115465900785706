import { Component, Inject, OnInit, OnDestroy, ChangeDetectorRef, KeyValueDiffer, KeyValueDiffers } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent, BaseComponentWithPermission } from '../../base.component';
import { MessagesService } from 'src/app/services/messages.service';
import { Restangular } from 'ngx-restangular';
import { LoginService } from 'src/app/services/login.service';
import { InfostarsToolsService } from 'src/app/services/InfostarsTools.service';
import { DispoSettingsService } from 'src/app/services/disposettings.service';
import { forkJoin } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, ActivationStart, NavigationEnd, Router } from '@angular/router';
import { UserTranslationsService } from 'src/app/services/usertranslations.service';
import { BackendRestService } from 'src/app/services/Restangular.service';
import { FilterOverrideService } from 'src/app/services/filteroverride.service';
import { VehicleTreeApi } from '../vehicle-tree.component';
import { DriverTreeApi } from '../driver-tree.component';
import { NavbarFilterApi } from '../navbar/navbar.controller';
import { CanvasToPngService } from 'src/app/services/canvas-to-png.service';

/** A side menu UI that allows the user to select which data other components should show (dispolight, logbook, alarmstar etc.) */
@Component({
	selector: 'filter',
	templateUrl: './filter.html',
})
export class FilterComponent extends BaseComponentWithPermission implements OnInit, OnDestroy, VehicleTreeApi, DriverTreeApi, NavbarFilterApi {
	public searchFilter:any = { ...this.DispoSettings.getSettings(),
		vehicles: [],
		vehicleMainCondition: {},
		mapType: {},
		mapShowTraffic: false,
		disableClusteringOnMap: false,
		enableTracking: false,
		mapSection: '',
		mapRefreshMinutes: 1,
		rearrangeMapOnAutoRefresh: true,
	}; // XXXAngularIO Kept up-to date with DispoSettingService
	public enableFilter = false; // Whether to show the filter at all
	public menuOpen = false;
	public accordionState = 'vehicleTree';
	// The data model for the filter
	public searchFilterStartEndError = false;
	public isShowAllVehicles = false;
	public isAnimalType = false;
	public vehicleTree:any[] = [];
	public settingsProfile = null as any;
	public selSettingsProfiles:any[] = [];
	public selBoolMainStati:any[] = [];
	public selBoolSecStati:any[] = [];
	public selMapSections:any[] = [];
	public selMapTypes:any[] = [];
	public gpsTrackingAvail = true; // false when the browser denies GPS tracking, true if we havent checked or it's allowed
	public selTourStatus:any[] = [];
	public selZones:any[] = [];
	public selDisplayZones:any[] = [];
	public selStaticRoutes:any[] = [];
	public searchQueryZone = ''; // For the list of zones which filter the dispo light data
	public searchQueryGeoArea = ''; // For the list of geo areas for a radio select for the zone logbook
	public searchQueryGeoAreas = ''; // For the list of geo areas to show on the map
	public refreshStep = '1';
	public drivers:any[] = []; // Drivers in the Root driver Group
	public driverGroups:any[] = [];
	public driverData = {} as any; // All driver groups in this account with .drivers array of all drivers in this group
	private hideDefault = { // For hiding various UI parts of the filter
		startTime: false,
		endTime: false,
		vehicles: false,
		drivers: false,
		vehConditions: false,
		tourStati: false,
		zones: false,
		geoArea: false,
		geoAreas: false,
		staticRoutes: false,
		mileage: false,
		mapSettings: false,
		mapSection: false,
		mapRefresh: false,
	};
	public hide = {...this.hideDefault};
	public api = {};

	private jqEvtNs = 'infostarsFilter_' + this.InfostarsTools.randomString(); // the $(...).on(..., ns) might happen before the $destroy event when the filter is used on multiple pages, therefore we need a unique namespace for every instance
	private dataLoaded = false;
	private inhibitFilterUpdated = false;
	private routeFilterConfig:{[name: string]: any};
	protected searchFilterDiffer: KeyValueDiffer<string, any>;

	constructor(
		protected Messages: MessagesService,
		protected UserTranslations: UserTranslationsService,
		protected $translate: TranslateService,
		@Inject(BackendRestService) protected BackendRest: Restangular,
		protected Login: LoginService,
		protected InfostarsTools: InfostarsToolsService,
		protected $route: ActivatedRoute,
		public $router: Router,
		protected FilterOverride: FilterOverrideService,
		protected DispoSettings: DispoSettingsService,
		protected cvToPng: CanvasToPngService,
		protected changeDetector: ChangeDetectorRef,
		protected differs: KeyValueDiffers,
	) {
		super(InfostarsTools, Login);
		this.searchFilterDiffer = this.differs.find(this.searchFilter).create();
	}

	///// Dummy methods to be replaced by createSelect/ToggledHandler() /////
	public onSecStatusToggled():void {}
	public onZoneToggled():void {}
	public onZoneSelectAllNone(selAll:boolean):void {}
	public onTourStatiToggled():void {}
	public onTourStatiSelectAllNone(selAll:boolean):void {}
	public onDisplayZoneToggled():void {}
	public onDisplayZoneSelectAllNone(selAll:boolean):void {}
	public onStaticRouteToggled():void {}
	/////////////////////////////////

	ngOnInit() {
		(this as any).onSecStatusToggled = this.createToggledHandler('selBoolSecStati', 'vehicleSecondaryConditions', 'abbreviation');
		(this as any).onZoneToggled = this.createToggledHandler('selZones', 'geoAreas');
		(this as any).onZoneSelectAllNone = this.createSelectAllNoneHandler('selZones', 'geoAreas');
		(this as any).onTourStatiToggled = this.createToggledHandler('selTourStatus', 'tourStati', 'value');
		(this as any).onTourStatiSelectAllNone = this.createSelectAllNoneHandler('selTourStatus', 'tourStati', 'value');
		(this as any).onDisplayZoneToggled = this.createToggledHandler('selDisplayZones', 'showGeoAreas');
		(this as any).onDisplayZoneSelectAllNone = this.createSelectAllNoneHandler('selDisplayZones', 'showGeoAreas');
		(this as any).onStaticRouteToggled = this.createToggledHandler('selStaticRoutes', 'staticRoutes');

		// Fill this.hide properties according to router state data
		this.$router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((ev: NavigationEnd) => {
			const rData = this.InfostarsTools.getAllRouteData(this.$router.routerState.snapshot.root);
			this.routeFilterConfig = rData.filterConfig;
			this.enableFilter = this.isEnableFilter();
			if(rData.filterConfig && rData.filterConfig.hide)
				this.hide = { ...this.hideDefault, ...(rData.filterConfig.hide) };
			else
				this.hide = { ...this.hideDefault };
		});
		// Animal accounts don't have drivers at all
		this.hide.drivers = this.Login.getTrackType() === 'ANIMAL' ? true : this.hide.drivers;
		this.subscribe(this.DispoSettings.searchFilter$, (searchFilter:any) => {
			this.searchFilter = searchFilter;
			this.searchFilterStartEndError = this.searchFilter.start && this.searchFilter.end && this.searchFilter.start.isAfter(this.searchFilter.end);
			if(this.inhibitFilterUpdated) { // Ignore our own call to DispoSettings.filterUpdated()
				this.inhibitFilterUpdated = false;
				return;
			}
			this.searchFilterDiffer = this.differs.find(this.searchFilter).create();
			if(this.dataLoaded)
				this.applySearchFilterToUi();
			this.changeDetector.detectChanges(); // Need to run this manually, as the searchFilter object is never changed itself
		});

		// XXX We have to do this with jQuery as an ng-click breaks the foundation topbar on mobile (dispolight)
		// Need to detach on $destroy
		$('.st-container .st-pusher').on('click.' + this.jqEvtNs, (event) => {
			// Disable the menu (need to filter events)
			if($(event.target).parents('.st-menu').length === 0 && this.menuOpen) { // Ignore clicks from within the menu
				this.menuOpen = false;
			}
		});

		this.subscribe(this.Login.accountId$, () => { this.loadData(); })
		this.subscribe(this.Login.userSettings$, () => { this.loadData(); })
		this.subscribe(this.DispoSettings.vehicleTree$, () => { this.loadData(); })

		this.loadData();

		this.Login.getLoginPromise().then(() => {
			this.enableFilter = this.isEnableFilter();
			let types = [{id: 'ROADMAP', name: this.$translate.instant('mapTypeRoad')}, {id: 'SATELLITE', name: this.$translate.instant('mapTypeSatellite')}];
			if (this.Login.hasAnyRight('USE_MAP_BASEMAP'))
				types.push({id: 'basemap', name: this.$translate.instant('mapTypeBasemap')});
			this.selMapTypes = types;
		});
	}
	ngOnDestroy(): void {
		$('.st-container .st-pusher').off('click.' + this.jqEvtNs);
	}
	ngDoCheck(): void {
		const changes = this.searchFilterDiffer.diff(this.searchFilter);
		if(changes) {
			this.inhibitFilterUpdated = true;
			this.DispoSettings.filterUpdated();
		}
	}

	private isEnableFilter() {
		return (this.routeFilterConfig && this.Login.hasAllRights('SHOW_DISPO_FILTER')) ? true : false;
	}

	private findCheckedIdsInTree_processNode(checkedIds:any, node:any, childrenProp:string, groupsProp:string, isNodeCb:(node:any) => boolean) {
		if(node.checked && isNodeCb(node))
			checkedIds[node.id] = true;
		(node[childrenProp] || []).forEach((n:any) => this.findCheckedIdsInTree_processNode(checkedIds, n, childrenProp, groupsProp, isNodeCb));
		(node[groupsProp] || []).forEach((n:any) => this.findCheckedIdsInTree_processNode(checkedIds, n, childrenProp, groupsProp, isNodeCb));
	}
	private findCheckedVehicleIdsInTree() {
		let checkedIds = {};
		this.findCheckedIdsInTree_processNode(checkedIds, this.vehicleTree[0], 'vehicles', 'childGroups', (n) => n.pictureURL ? true : false); // if picture, this is a vehicle
		return Object.keys(checkedIds).map(Number);
	}
	private findCheckedDriverIdsInTree() {
		let checkedIds = {};
		this.findCheckedIdsInTree_processNode(checkedIds, this.driverData, 'drivers', 'groups', (n) => n.groupName ? false : true);
		return Object.keys(checkedIds).map(Number);
	}
	private createToggledHandler(selArrayProp:string, filterProp:string, idProp?:string) {
		// Transforms a list of checked (property checked = true) items into a list of ids on a filter property
		return () => {
			this.searchFilter[filterProp] = [];
			(this as any)[selArrayProp].forEach((e:any) =>  {
				if(e.checked)
					this.searchFilter[filterProp].push(e[idProp || 'id']);
			});
		};
	}
	private createSelectAllNoneHandler(selArrayProp:string, filterProp:string, idProp?:string) {
		return (selAll:boolean) => {
			this.searchFilter[filterProp] = [];
			(this as any)[selArrayProp].forEach((e:any) =>  {
				e.checked = selAll ? true : false;
				if(!e.checked)
					return;
				this.searchFilter[filterProp].push(e[idProp || 'id']);
			})
		}
	}
	/** Filter an array of data depending on whether the corresponding rights in reqRights are all granted
	 * Calls dataFunc with an array of true / false values, and expects back an array with data back (same
	 * length as reqRights.
	 * This is useful with forkJoin([]), when you only want to request some resources, depending on granted user rights.
	 * forkJoin doesn't like null values in the array, so we'll have to re-expand the results in a second step.
	 * @see resultsByRights For the next step */
	private filterByRights(reqRights:any[], dataFunc:(isOn:any[])=>any[]) {
		let isOn:any[] = [];
		for(let i = 0; i < reqRights.length; i++)
			isOn.push(this.Login.hasAllRights(reqRights[i]) ? true : false);
		let data = dataFunc(isOn);
		let filteredData:any[] = [];
		(data || []).forEach((dataEl, i) => {
			if(isOn[i])
				filteredData.push(dataEl);
		});
		return filteredData;
	}
	/** Transform a smaller results array to the size of reqRights, assuming that results contains an item
	 * if all corresponding rights in reqRights are granted.
	 * This is useful to pre-process the results of a set of promises filtered by filterByRights
	 * @see filterByRights Use this as a previous step */
	private resultsByRights(reqRights:any[], results:any[]): any[] {
		let filteredResults:any[] = [];
		let resIdx = 0;
		reqRights.forEach((reqRight) => {
			if(!this.Login.hasAllRights(reqRight)) {
				filteredResults.push(null);
			}else {
				filteredResults.push(results[resIdx++]);
			}
		});
		return filteredResults;
	}

	private processOverrideProperties() {
		let overrideProperties = this.FilterOverride.getAndClearOverrideProperties();
		if (!overrideProperties) return;

		if (overrideProperties.drivers) {
			this.drivers.forEach((driver) =>  {
				if (!driver.checked && overrideProperties.drivers.indexOf(driver.id) !== -1) {
					driver.checked = true;
				}
			});
			this.driverGroups.forEach((dGroup) =>  {
				this.drivers.forEach((driver) =>  {
					if (!driver.checked && overrideProperties.drivers.indexOf(driver.id) !== -1)
						driver.checked = true;
				});
			});
		}
	}

	private applySearchFilterToUi_processNode(uSettings:any, forceSelectAll:boolean, vehicleIds:any, selectedAndAllowed:number[], node:any) {
		if(!node)
			return; // Empty vehicleTree
		if(node.pictureURL && (vehicleIds[node.id] || uSettings.showAllVehicles || forceSelectAll)) {
			node.checked = true;
			selectedAndAllowed.push(node.id);
		}
		(node.vehicles || []).forEach((n:any) => this.applySearchFilterToUi_processNode(uSettings, forceSelectAll, vehicleIds, selectedAndAllowed, n));
		(node.childGroups || []).forEach((n:any) => this.applySearchFilterToUi_processNode(uSettings, forceSelectAll, vehicleIds, selectedAndAllowed, n));
	}
	private applySearchFilterToUi() {
		let uSettings = this.Login.getUserSettings();
		this.isShowAllVehicles = uSettings.showAllVehicles;
		let isStandard = this.searchFilter.name === 'Standard';
		let forceSelectAll = this.Login.forceSelectAll();
		if (forceSelectAll)
			this.Login.setForceSelectAll(false);
		// Mark currently selected vehicles as checked
		let vehicleIds:any = {};
		this.searchFilter.vehicles.forEach((v:any) =>  { // Collect all vehicle ids
			vehicleIds[v] = true;
		});
		let selectedAndAllowed:any[] = []; // Vehicle ids that are selected and actually in the users tree
		this.InfostarsTools.vehicleTreeAddPngIcons(this.vehicleTree, this.cvToPng);
		this.vehicleTree.forEach((n:any) => this.applySearchFilterToUi_processNode(uSettings, forceSelectAll, vehicleIds, selectedAndAllowed, n));

		this.processOverrideProperties();
		if (forceSelectAll || isStandard) { // forceSelect all is true after account switching; The standard profile always has all drivers selected.
			this.drivers.forEach(d =>  d.checked = true);
			this.driverGroups.forEach(dG => dG.drivers.forEach((d:any) => d.checked = true));
		}else {
			this.InfostarsTools.setChecked(this.drivers, this.searchFilter.drivers);
			this.driverGroups.forEach(dG => this.InfostarsTools.setChecked(dG.drivers, this.searchFilter.drivers));
		}
		this.updateDriversFromUI();

		this.searchFilter.vehicles = selectedAndAllowed;
		this.InfostarsTools.setChecked(this.selBoolSecStati, this.searchFilter.vehicleSecondaryConditions, 'abbreviation');
		this.InfostarsTools.setChecked(this.selZones, this.searchFilter.geoAreas);
		this.InfostarsTools.setChecked(this.selDisplayZones, this.searchFilter.showGeoAreas);
		this.InfostarsTools.setChecked(this.selStaticRoutes, this.searchFilter.staticRoutes);
		this.searchFilter.mapSection = this.InfostarsTools.findSelected(this.selMapSections, this.searchFilter.mapSection);
		this.searchFilter.geoArea = this.InfostarsTools.findSelected(this.selZones, this.searchFilter.geoArea);
		if(this.searchFilter.enableTracking && navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((pos) => { // Check if the permission is granted and warn the user if not
				this.gpsTrackingAvail = true;
			}, (err) => {
				this.gpsTrackingAvail = false;
			});
		}else {
			this.gpsTrackingAvail = true;
		}
	}

	private processDrivers(allDrivers:any[]) {
		let driverGroups:any[] = [];
		let drivers:any[] = [];
		(allDrivers || []).forEach((driver:any) =>  {
			if (driver.driverGroup) {
				let found = false;
				driverGroups.some((dGroup) => { // Find the group of this driver and add the driver there
					if (dGroup.id === driver.driverGroup.id) {
						dGroup.drivers.push(driver);
						found = true;
						return true; // break
					}
					return false;
				});
				if (!found) { // We see this group for the first time
					driver.driverGroup.drivers = [driver];
					driver.driverGroup.checked = false;
					driverGroups.push(driver.driverGroup);
				}
			} else {
				drivers.push(driver);
			}
		});
		this.drivers = drivers;
		this.driverGroups = driverGroups;
		this.driverData = {groups: this.driverGroups, drivers: this.drivers, groupName: 'ROOT', checked: false};
	}

	private loadData() {
		let reqRights = [
			[],
			['SHOW_ZONES'],
			['SHOW_STATIC_ROUTE'],
			['SHOW_MAP_SECTIONS'],
			['SHOW_DRIVERS'],
			['SHOW_TOUR_STATUS_CUSTOM'],
			[],
			[],
		];
		this.Login.getLoginPromise().then(() =>  {
			this.isAnimalType = this.Login.getTrackType() === 'ANIMAL';
			this.refreshStep = this.Login.hasAllRights('UNRESTRICTED_REFRESH') ? '0.1' : '1';
			return forkJoin(this.filterByRights(reqRights, (on) => {return [
				this.DispoSettings.getVehicleTreePromise(),
				on[1] ? this.BackendRest.one('defaultvalue/zone').get({accountId: this.Login.getAccountId()}) : null,
				on[2] ? this.BackendRest.one('defaultvalue/staticroute').get({accountId: this.Login.getAccountId()}) : null,
				on[3] ? this.BackendRest.one('defaultvalue/maps').get() : null,
				on[4] ? this.BackendRest.one('driver/filter').get({accountId: this.Login.getAccountId()}) : null,
				on[5] ? this.BackendRest.one('tour/status/custom').get({accountId: this.Login.getAccountId()}) : null,
				this.UserTranslations.getPromise(),
				this.DispoSettings.getPromise(),
			];})).toPromise();
		}).then((results:any[]) =>  {
			results = this.resultsByRights(reqRights, results);
			this.vehicleTree = results[0];
			let zones = results[1] ? results[1].sort((a:any, b:any) => a.name.localeCompare(b.name)) : [];
			let routes = results[2]; // Selected areas on the map (center & zoom level)
			let maps = results[3];
			this.processDrivers(results[4]);
			this.selTourStatus = this.InfostarsTools.getTourStatusOptions(this.Login.getTourStatusTypes(), results[5]);
			this.InfostarsTools.openVehicleTree(this.vehicleTree);
			this.InfostarsTools.openDriverTree([this.driverData]);
			// Fill the selection models
			this.selBoolMainStati = $.map(this.UserTranslations.getDataInterfaceTypesUsedBool(), (val) => { return val; });
			this.selBoolMainStati.sort((a, b) => {
				return a.localeName.localeCompare(b.localeName);
			});
			this.selBoolSecStati = JSON.parse(JSON.stringify(this.selBoolMainStati));
			this.selBoolMainStati.unshift({abbreviation: null, localeName: '-- ' + this.$translate.instant('noSelection') + ' --'});
			this.selZones = zones;
			this.selDisplayZones = JSON.parse(JSON.stringify(zones));
			this.selStaticRoutes = routes;
			this.selMapSections = maps;
			this.selSettingsProfiles = JSON.parse(JSON.stringify(this.DispoSettings.getAllSettings()));
			this.settingsProfile = this.InfostarsTools.findSelected(this.selSettingsProfiles, this.DispoSettings.getSettings());
			this.applySearchFilterToUi();
			this.dataLoaded = true;
		}, () => {
			// Nothing todo, we are simply not logged in
		});
	}
	public onToggleAccordion(state:string) {
		this.accordionState = this.accordionState === state ? '' : state;
	}
	public onSettingsProfileChanged(newProfile:any) {
		this.DispoSettings.setActive(newProfile.id);
		this.applySearchFilterToUi(); // Apply this.searchFilter to the UI
	}
	public onSaveSettingsProfile() {
		this.DispoSettings.saveProfile().then(() => {
			this.Messages.success(this.$translate.instant('savedSuccessfully'));
		});
	}
	public onSaveSettingsProfileAs() {
		let name = window.prompt(this.$translate.instant('pleaseEnterName'));
		if(name) {
			this.DispoSettings.saveProfile(name).then(() =>  {
				this.selSettingsProfiles = JSON.parse(JSON.stringify(this.DispoSettings.getAllSettings()));
				this.settingsProfile = this.InfostarsTools.findSelected(this.selSettingsProfiles, this.DispoSettings.getSettings());
			});
		}
	}
	public onDeleteSettingsProfile() {
		if(!window.confirm(this.$translate.instant('deleteEntryMsg')))
			return;
		this.DispoSettings.deleteActive().then(() =>  {
			this.selSettingsProfiles = JSON.parse(JSON.stringify(this.DispoSettings.getAllSettings()));
			this.settingsProfile = this.InfostarsTools.findSelected(this.selSettingsProfiles, this.DispoSettings.getSettings());
		});
	}
	public onCreateMapSection() {
		let center = this.DispoSettings.getMapCenter();
		let zoom = this.DispoSettings.getMapZoom();
		if(!center || !zoom)
			return;
		let name = window.prompt(this.$translate.instant('pleaseEnterName'));
		if(name) {
			let existingSection = (this.selMapSections || []).filter((val:any) => { return val.name === name; })[0];
			let section = (existingSection ? JSON.parse(JSON.stringify(existingSection)) : undefined) || { name: name };
			section.latitude = center.lat(); section.longitude = center.lng();
			section.zoomLevel = zoom;
			this.BackendRest.all('dispolight/mapsection').post(section).subscribe((entity:any) => {
				// TODO splice based on name
				if(existingSection) {
					this.selMapSections.splice(this.selMapSections.indexOf(existingSection), 1, entity);
					if(this.searchFilter.mapSection && this.searchFilter.mapSection.name === name)
						this.searchFilter.mapSection = entity;
				}else {
					this.selMapSections.push(entity);
				}
			}, () => {
				this.Messages.error('Error while saving map section');
			});
		}
	}
	public onDeleteMapSection(mapSection:any) {
		if(!window.confirm(this.$translate.instant('deleteEntryMsg')))
			return;
		this.BackendRest.one('dispolight/mapsection', mapSection.id).remove().subscribe(() =>  {
			this.selMapSections.splice(this.selMapSections.indexOf(mapSection), 1);
		}, (result:any) => {
			this.Messages.error(this.$translate.instant('alert') + ': ' + result.statusText);
		});
	}

	/** Update the checked property for groups if all/none drivers are selected */
	public checkAllSelected () {
		let allChecked = true;
		this.driverData.groups.forEach((group:any) =>  {
			let driverGroupSelected = true;
			group.drivers.forEach((driver:any) =>  {
				allChecked = allChecked && driver.checked;
				driverGroupSelected = driverGroupSelected && driver.checked;
			});
			group.checked = driverGroupSelected;
		});
		this.driverData.drivers.forEach((driver:any) =>  {
			allChecked = allChecked && driver.checked;
		});
		this.driverData.checked = allChecked;
	}

	/** Updates this.searchFilter.drivers from the UI bound properties. Assumes this.DispoSettings promise is already resolved.
	 * @return Whether there were changes
	 */
	protected updateDriversFromUI(): boolean {
		this.checkAllSelected();
		let oldDriversStr = JSON.stringify(this.searchFilter.drivers);
		this.searchFilter.drivers = this.findCheckedDriverIdsInTree();
		if(oldDriversStr !== JSON.stringify(this.searchFilter.drivers))
			return true;
		return false;
	}

	protected toggleRecursive(node:any, nodeChecked:boolean, childrenProp:string, groupsProp:string) {
		node.checked = nodeChecked;
		(node[childrenProp] || []).forEach((n:any) => this.toggleRecursive(n, nodeChecked, childrenProp, groupsProp));
		(node[groupsProp] || []).forEach((n:any) => this.toggleRecursive(n, nodeChecked, childrenProp, groupsProp));
	}

	//// PUBLIC API (DriverTreeApi, VehicleTreeApi) ////
	public onDriverToggled (driver?:any) {
		this.DispoSettings.getPromise().then(() =>  {
			// Add / remove the driver id from the list of drivers to query. Just adding / removing from the id based on checked (true/false) does not work as drivers can be in the tree multiple times in different groups
			if(this.updateDriversFromUI()) {
				this.inhibitFilterUpdated = true;
				this.DispoSettings.filterUpdated();
			}
		});
	}
	public onDriverGroupToggled (node:any) {
		let nodeChecked = node.checked;
		// Toggle all child groups and drivers
		this.toggleRecursive(node, nodeChecked, 'drivers', 'groups');
		this.onDriverToggled();
	}
	public getChecked(node: any): boolean {
		return node.checked;
	}
	public setChecked(node: any, checked: boolean): void {
		node.checked = checked;
	}
	public onVehicleToggled(vehicle?:any) {
		this.DispoSettings.getPromise().then(() =>  {
			// Add / remove the vehicle id from the list of vehicles to query. Just adding / removing from the id based on checked (true/false) does not work as vehicles can be in the tree multiple times in different groups
			this.searchFilter.vehicles = this.findCheckedVehicleIdsInTree();
			this.inhibitFilterUpdated = true;
			this.DispoSettings.filterUpdated();
		});
	}
	public onVehicleGroupToggled(node:any) {
		let nodeChecked = node.checked;
		// Toggle all child groups and vehicles
		this.toggleRecursive(node, nodeChecked, 'vehicles', 'childGroups');
		this.onVehicleToggled();
	}
	////////////////////

	//// PUBLIC API FOR NAVBAR (NavbarFilterApi )////
	public onOpenMenu() {
		if(this.enableFilter)
			this.menuOpen = true;
	}
	////////////////////
}
