import { DOCUMENT } from "@angular/common";
import { EventEmitter, Inject, Injectable } from "@angular/core";
import { EventManager } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { InfostarsToolsService } from "./InfostarsTools.service";
import { MessagesService } from "./messages.service";
import { TranslateService } from "@ngx-translate/core";
import { ByTrackTypePipe } from "../pipes/ByTrackType.pipe";

export type HotkeyConfig = {
	element: any;
	keys: string;
	category: string;
	command: string;
	description: string;
	trackType: string;
	noFeedback: boolean;
}

export type HotkeyConfigExtended = HotkeyConfig & {
	categoryPrio: number;
}

export const KEY_SEQ_SEPARATOR = '→';
export const KEY_SEQ_TIMEOUT_MS = 2000;

/** Register hotkeys as Observable and provide a hotkeys reference
 * Hotkeys are context dependent, so you're supposed to unsubscribe from the observable that
 * addShortcut returns when destroying your component / page.
 */
@Injectable()
export class HotkeysService {
	public hotkeys$ = new EventEmitter<Partial<HotkeyConfigExtended>[]>(); // Emitted whenever hotkeys are added / removed
	hotkeys = new Map<string, Partial<HotkeyConfigExtended>>(); // All currently configured hotkeys
	catPrios = new Map<string, number>(); // Maps from category translation to priority of the category
	defaults: Partial<HotkeyConfig> = {
		element: this.document
	}
	textAcceptingInputTypes = new Set([
		"textarea", "input", "select", // And those jquery.hotkeys.js does not bind to at all
		// From https://github.com/jeresig/jquery.hotkeys/blob/f24f1da275aab7881ab501055c256add6f690de4/jquery.hotkeys.js#L116
		"text", "password", "number", "email", "url", "range", "date", "month", "week", "time", "datetime",
		"datetime-local", "search", "color", "tel"
	]);

	constructor(
			protected InfostarsTools: InfostarsToolsService,
			protected Messages: MessagesService,
			protected ByTrackTypePipe: ByTrackTypePipe,
			protected eventManager: EventManager,
			protected $translate: TranslateService,
			@Inject(DOCUMENT) private document: Document) {
		this.addShortcut({ keys: 'shift.?', category: 'cmdCatGlobal', command: 'cmdHotkeys', description: 'cmdDescHotkeys', noFeedback: true }).subscribe(() => {
			this.openHelpModal();
		});
		this.setCategoryPriorities([{c: 'cmdCatGlobal', prio: 1000}, {c: 'cmdCatCurrentPage', prio: 900}, {c: 'cmdCatMenu', prio: -100}, {c: 'cmdCatAdminMenu', prio: -200}, {c: 'cmdCatSetupMenu', prio: -300}])
	}

	setCategoryPriorities(catPrios: { c: string, prio: number }[]) {
		catPrios.forEach(({ c: category, prio }) => {
			this.catPrios.set(this.$translate.instant(category), prio);
		});
	}

	/** Sets up a hotkey or a hotkey sequence
	 * Modifiers are 'alt', 'control', 'meta', 'shift'
	 * See https://www.w3.org/TR/DOM-Level-3-Events-key/#named-key-attribute-values for special key names like 'Tab', 'ArrowLeft'
	 * See also https://github.com/angular/angular/blob/main/packages/platform-browser/src/dom/events/key_events.ts
	 * To use a hotkeys sequence, separate the hotkeys with → (it's the HTML right arrow &rarr; just copy it from here)
	 * Unsubscribe from the observable that is returned when destroying your compoment!
	 * @param options keys is of format: MODIFIER.[MODIFIER.].KEY where you can use 'space' and 'dot' instead of ' ' and '.' as . is part of the syntax. Modifiers are named
	 */
	addShortcut(options: Partial<HotkeyConfig>) {
		const merged = { ...this.defaults, ...options } as HotkeyConfigExtended;
		if(this.hotkeys.has(merged.keys))
			throw new Error(`Shortcut ${merged.keys} is already defined`);
		merged.category = merged.category ? this.transl(merged.category, merged.trackType) : undefined;
		merged.categoryPrio = this.catPrios.get(merged.category) || 0;
		merged.command = merged.command ? this.transl(merged.command, merged.trackType) : undefined;
		merged.description = merged.description ? this.transl(merged.description, merged.trackType) : undefined;
		const hotkeyConfig = { ... merged };
		delete hotkeyConfig.element; // Can't store an HTML element in the list of hotkeys, as that would create cyclic dependencies and mess up with angular if it's used by other components (Expresseion has changed after it was checked https://v17.angular.io/errors/NG0100)
		this.hotkeys.set(merged.keys, hotkeyConfig);
		this.hotkeys$.emit(Array.from(this.hotkeys.values()));

		return new Observable(observer => { // The caller to addShortcut subscribes to this
			const finalHandler = (e: Event) => { // This handler will call back to the caller of addShortcut
				e.preventDefault();
				if(merged.command && !merged.noFeedback)
					this.Messages.info(merged.command, 400, undefined, { noClose: true });
				observer.next(e);
			};
			const keySeq = merged.keys.split(KEY_SEQ_SEPARATOR);
			let keySeqIdx = 0;
			let currSeqDispose: Function; // Disposes the currently active key handler in a key sequence
			const firstKeysEvent = this.keysToEvent(keySeq[keySeqIdx++]);
			const keySeqHandler = (e: Event) => { // Will be used for the second key squence element on
				if(this.isInInput(e))
					return; // Ignore this event
				currSeqDispose();
				e.preventDefault();
				console.debug(`Continue handling hotkey sequence ${keySeq} with key ${(e as KeyboardEvent).key}`);
				if(keySeqIdx == keySeq.length) {
					finalHandler(e);
					return;
				}
				const nextKeyEvent = this.keysToEvent(keySeq[keySeqIdx++]);
				currSeqDispose = this.eventManager.addEventListener(merged.element, nextKeyEvent, keySeqHandler);
			}
			const dispose = this.eventManager.addEventListener(merged.element, firstKeysEvent, (e: Event) => { // Handles the first keys
				if(keySeqIdx > 1)
					return; // Key sequence active
				if(this.isInInput(e))
					return; // Ignore this event
				if(keySeq.length === 1) { // If we have no sequence, just call the final handler
					console.debug(`Finished handling hotkey sequence ${keySeq}, invoking action`);
					finalHandler(e);
					return;
				}
				console.debug(`Start handling hotkey sequence ${keySeq}`);
				e.preventDefault();
				const secondKeysEvent = this.keysToEvent(keySeq[keySeqIdx++]);
				currSeqDispose = this.eventManager.addEventListener(merged.element, secondKeysEvent, keySeqHandler);
				setTimeout(() => { // Abort the sequence after a while
					currSeqDispose();
					keySeqIdx = 1;
				}, KEY_SEQ_TIMEOUT_MS);
			});

			return () => {
				this.hotkeys.delete(merged.keys);
				this.hotkeys$.emit(Array.from(this.hotkeys.values()));
				dispose(); // detach the keyboard handler from the DOM
			};
		})
	}
	protected keysToEvent(keys: string) {
		// See angular KeyEventsPlugin for what it can do. It format is basically: HTMLEVENTNAME.MODIFIER.KEY
		return `keydown.${keys}`;
	}
	protected transl(key:string, trackType:string): string {
		return this.$translate.instant(trackType ? this.ByTrackTypePipe.transform(key, trackType) : key);
	}
	protected isInInput(e: Event) {
		const targetEl = (e?.target as HTMLElement);
		const targetElName = targetEl?.nodeName;
		if(!targetElName)
			return false;
		if(this.textAcceptingInputTypes.has(targetElName.toLowerCase()) || targetEl.getAttribute('contenteditable'))
			return true;
		return false;
	}
	getHotKeys() {
		return Array.from(this.hotkeys.values());
	}
	openHelpModal() {
		this.InfostarsTools.openHotkeysModal(); // You can listen on the InfostarsTools.dialogOpened$ if you need to be informed
	}
}
