import { Inject, Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { BackendRestService } from './Restangular.service';
import { LoginService } from './login.service';
import { DispoSettingsService } from './disposettings.service';
import { InfostarsToolsService } from './InfostarsTools.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { BaseComponent, distinctUntilChangedObjectCmp } from '../base.component';
import { forkJoin } from 'rxjs';

/** Based on an array of ids on $rootScope.searchFilter, loads the respective
 * objects from the backend, caches them and makes them available as a
 * promise.
 * Should be wrapped by an angular service with a single method which invokes
 * .getPromise. The service has to call init() from their constructor
 */
class SimpleSearchFilterIdDataCache extends BaseComponent {
	protected infos:any = {}; // Maps from id to entity object
	protected searchFilter:any = {};
	protected requestInProgress = false;
	protected dataPromise:Promise<any> = null;
	protected preGetPromise:Promise<unknown>;

	constructor(
		protected restPath:string,
		protected searchFilterProp:string,
		protected BackendRest:Restangular,
		protected DispoSettings:DispoSettingsService,
		protected InfostarsTools:InfostarsToolsService,
	){ super(InfostarsTools); }

	public init(promise:Promise<unknown>, isEqual:(prevSearchFilter:any, currSearchFilter:any) => boolean) {
		this.preGetPromise = promise;
		this.getData();
		this.subscribe(this.DispoSettings.searchFilter$.pipe(distinctUntilChangedObjectCmp((prev, curr) => {
			return isEqual(prev, curr);
		})), (searchFilter) => {
			this.getData();
		});
	}

	public saveData(result:any[]) {
		(result || []).forEach((r) => {
			this.infos[r.id] = r;
		});
	};
	public getSelectedInfos() {
		var selectedInfos:any = {};
		(this.searchFilter[this.searchFilterProp] || []).forEach((e:any) => { // Fill selected (ids in this.searchFilter) entity objects into result map
			selectedInfos[e] = this.infos[e];
		});
		return selectedInfos;
	};
	public clearData() {
		this.infos = {};
		this.dataPromise = null;
	};
	public getData() {
		let deferred = new Promise<any>((resolve, reject) => {
			this.requestInProgress = true;
			this.preGetPromise.then(() => {
				this.searchFilter = this.DispoSettings.getSettings();
				var reqs:any[] = [];
				(this.searchFilter[this.searchFilterProp] || []).forEach((id:any) => {
					if(!this.infos[id])
						reqs.push(this.BackendRest.one(this.restPath, id).get());
				});
				if(reqs.length > 0) {
					forkJoin(reqs).subscribe(result => {
						this.saveData(result);
						resolve(this.getSelectedInfos());
						this.requestInProgress = false;
					}, (err) => {
						console.log(`Failed to retrieve data for searchFilter ${this.searchFilterProp} property`, err);
						this.clearData();
						reject(false);
						this.requestInProgress = false;
					});
				}else {
					resolve(this.getSelectedInfos());
				}

			})
		});
		this.dataPromise = deferred;
		return this.dataPromise;
	};
	public getPromise(/*update*/) {
		if(this.dataPromise)
			return this.dataPromise;
		return Promise.reject(false);
	};
}

/** Keeps static routes cached locally as the searchfilter changes */
@Injectable()
export class StaticRoutesService extends BaseComponent {
	private sr = new SimpleSearchFilterIdDataCache('staticroute', 'staticRoutes', this.BackendRest, this.DispoSettings, this.InfostarsTools);

	constructor(
		@Inject(BackendRestService) public BackendRest:Restangular,
		protected Login:LoginService,
		protected InfostarsTools:InfostarsToolsService,
		public DispoSettings:DispoSettingsService,
	) {
		super(InfostarsTools);
		this.sr.init(forkJoin([this.Login.getLoginPromise(), this.DispoSettings.getPromise()]).toPromise(), (prev, curr) => {
			return isEqual(prev.staticRoutes, curr.staticRoutes);
		});
	}
	public getRoutes() {
		return this.sr.getPromise();
	}
}
