import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../base.component';
import { InfostarsToolsService } from '../services/InfostarsTools.service';

export interface DriverTreeApi {
	onDriverToggled(driver?:any):void;
	onDriverGroupToggled(node:any):void;
}

@Component({
	selector: 'driver-tree',
	templateUrl: './driver-tree.html',
})
export class DriverTreeComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
	@Input() driverNode:any;
	@Input() level:number;
	@Input() api:DriverTreeApi;

	public groupMargin = {'margin-left': 10 + 'px'};
	public driverFilter = {name: ''};

	constructor(
		protected InfostarsTools: InfostarsToolsService,
	) { super(InfostarsTools); }

	ngOnInit() {
		this.groupMargin['margin-left'] = this.level * 10 + 'px';
		this.onFilterChange();
	}
	ngOnDestroy(): void {
		super.ngOnDestroy();
	}
	ngOnChanges(changes: SimpleChanges): void {
		let drvNodeChg = changes['driverNode'];
		if(drvNodeChg && drvNodeChg.previousValue) {
			this.driverFilter.name = '';
			this.onFilterChange();
		}
	}

	public onDriverClick (driver:any) {
		driver.checked = !driver.checked;
		this.api.onDriverToggled(driver);
	}

	private filterDriver(node:any, filterText:string) {
		if(!node)
			return;
		(node.drivers || []).forEach((driver:any) =>  {
			driver.filtered = filterText === '' || driver.name.toLocaleLowerCase().includes(filterText);
		});
		(node.groups || []).forEach((group: any) =>  {
			this.filterDriver(group, filterText);
		});
	}

	public onFilterChange () {
		let filterText = this.driverFilter.name.toLocaleLowerCase();
		this.filterDriver(this.driverNode, filterText);
	}
}


