import 'zone.js';
import 'reflect-metadata';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { HttpClientJsonpModule, HttpClientModule, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RestangularModule, Restangular } from 'ngx-restangular';
import { CookieService } from 'ngx-cookie-service';
import { config } from './environments/environment';
import { InfostarsToolsService } from './services/InfostarsTools.service';
import { LanguageRouteComponent } from './language-route.component';
import { MessagesService } from './services/messages.service';
import { ShowNavService } from './services/ShowNav.service';
import { RoutineService } from './services/routine.service';
import { LocalStorageService } from './services/LocalStorage.service';
import { SEOService } from './services/SEO.service';
import { AppInitService } from './services/AppInit.service';
import { RestangularConfigFactory, BackendRestService, RestangularBackendFactory, FullResponseBackendRestService, RestangularFullResponseFactory, BackendRestNoAuthService, RestangularBackendNoAuthFactory } from './services/Restangular.service';
import { AppComponent } from './App.component';
import { angularRouterRoutes } from './routing-angular';
import { AppSharedModule } from './appShared.module';
import { MainCtrl } from './main/main.controller';
import { LoginService } from './services/login.service';
import { ConfigService } from './services/config.service';
import { DispoSettingsService } from './services/disposettings.service';
import { ProtoBufService } from './services/protobuf.service';
import { ZonesService } from './services/zones.service'
import { UserTranslationsService } from './services/usertranslations.service';
import { FilterOverrideService } from './services/filteroverride.service';
import { MapToolsService } from './services/maptools.service';
import { StaticRoutesService } from './services/staticroutes.service';
import { AlarmStarService } from './services/alarmstar.service';
import { CanvasToPngComponent } from './components/canvas-to-png.component';
import { CanvasToPngService } from './services/canvas-to-png.service';
import { HotkeysService } from './services/hotkeys.service';
import { HotkeyListComponent } from './hotkey-list/hotkey-list.component';

const infostarsLangs = config.supportedLangMap;

// TODO

// TODOs for later

// The main NgModule for the Angular portion
// This module is statically loaded and needs to contain all components for the app when run on the server (SSR)
@NgModule({
	bootstrap: [
		AppComponent, // Our root component
	],
	entryComponents: [
		// Dynamically used components need to be entry components
		// TncContentCtrl, TncContentItCtrl, PrivacyPolicyContentCtrl, PrivacyPolicyContentItCtrl,
	],
	declarations: [
		AppComponent, // Our root component
		LanguageRouteComponent, // The abstract root state, which handles the language URL param
		// Components for states (routing)
		MainCtrl,
		// Components used in templates (if also used in the appPulic.module.ts it needs to go into appShared.module.ts)
		CanvasToPngComponent, HotkeyListComponent,
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		BrowserTransferStateModule,
		HttpClientModule, HttpClientJsonpModule,
		RestangularModule.forRoot(RestangularConfigFactory), // .forRoot() will load components AND services (services are only required to be loaded once per application)
		CommonModule, FormsModule,
		RouterModule.forRoot(
			angularRouterRoutes,
			{
				enableTracing: config.mode === 'development' ? true : false, // <-- debugging purposes only
				// enableTracing: false, // ATTENTION: Don't commit this line uncommented
				onSameUrlNavigation: 'reload', // e.g. the faq relies on this setting
				initialNavigation: 'enabled', // Will prevent some flicker after loading the server side HTML https://angular.io/api/router/InitialNavigation
			}
		),
		// Third party modules
		TranslateModule.forRoot(), // .forRoot() will load components AND services (services are only required to be loaded once per application)
		// Sub app modules
		AppSharedModule,
	],
	providers: [
		// Provide the SystemJsNgModuleLoader when using Angular lazy loading
		// { provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader },

		// A service that will run once the app is initialised
		AppInitService,
		{
			provide: APP_INITIALIZER,
			useFactory: (initService: AppInitService) =>
				() => initService.init(),
			deps: [AppInitService],
			multi: true
		},

		Restangular,
		CookieService,
		// Customised Restangular services
		{ provide: BackendRestService, useFactory: RestangularBackendFactory, deps: [Restangular, MessagesService, 'TranslateService'] },
		{ provide: BackendRestNoAuthService, useFactory: RestangularBackendNoAuthFactory, deps: [Restangular] },
		{ provide: FullResponseBackendRestService, useFactory: RestangularFullResponseFactory, deps: [BackendRestService] },

		ProtoBufService,
		ConfigService, MessagesService, LoginService, DispoSettingsService,
		SEOService, RoutineService, ZonesService, UserTranslationsService, HotkeysService,
		FilterOverrideService, MapToolsService, StaticRoutesService, AlarmStarService,
		CanvasToPngService,

		// Services which are also needed by name e.g. for signup_client.ts
		{ provide: 'TranslateService', deps: [TranslateService], useFactory: ($s: any) => $s },
		{ provide: 'InfostarsToolsService', deps: [InfostarsToolsService], useFactory: ($s: any) => $s },
		// Services
		InfostarsToolsService,
		ShowNavService, LocalStorageService
	]
})
export class InfostarsAppModuleAngular {
	constructor(private translate: TranslateService) {
		Object.entries(infostarsLangs).map(([k, v]) => {
			this.translate.setTranslation(k, v)
		});
		this.translate.setDefaultLang('en'); // The fallback language
		// this.translate.use('en');
	}
}
