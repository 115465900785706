import {configBase, isProductionServer, rendersInBrowser, setUrlsFromLocation} from './environment_base';
let config = Object.assign(configBase, {
	mode: 'production'
});
if(!rendersInBrowser()) { // The angular Universal node server is running this to render pages
	process.env.INFOSTARS_BASE_URL; // Let the docker image override this on production systems (e.g. test environment), as we have no location object in this environment
	var baseUrl = process && process.env && process.env.INFOSTARS_BASE_URL ? process.env.INFOSTARS_BASE_URL : config.baseUrl;
	setUrlsFromLocation(config, baseUrl + '/app');
}else {
	if(location.host !== 'app.gps-infostars.com' && location.hostname !== 'localhost') { // e.g. test.gps-infostars.com
		setUrlsFromLocation(config, location.href);
	}else {
		// Remove comment only for DEVELOPMENT
		// setUrlsFromLocation(config, 'http://localhost:8080/infostarsWeb/app');
	}
}
export { config, isProductionServer, rendersInBrowser };
