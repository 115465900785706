import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HotkeyConfigExtended, HotkeysService, KEY_SEQ_SEPARATOR } from '../services/hotkeys.service';
import { BaseComponent, distinctUntilChangedObject } from '../base.component';
import { InfostarsToolsService } from '../services/InfostarsTools.service';
import { LoginService } from '../services/login.service';
import { debounceTime } from 'rxjs/operators';

interface HotKey {
	modifiers: string[];
	key: string;
}
interface HotkeyConfigInfo extends HotkeyConfigExtended {
	keySeq: HotKey[];
}

@Component({
	selector: 'hotkey-list',
	templateUrl: './hotkey-list.html',
})
export class HotkeyListComponent extends BaseComponent {
	@ViewChild('searchQueryInput') searchQueryInput: ElementRef<HTMLInputElement>;
	public hotkeys: HotkeyConfigInfo[];
	public searchQuery: string;

	constructor(
			protected InfostarsTools: InfostarsToolsService,
			protected Hotkeys: HotkeysService
	) {
		super(InfostarsTools);
		this.hotkeys = this.processHotkeys(Hotkeys.getHotKeys());
	}

	ngOnInit(): void {
		this.subscribe(this.Hotkeys.hotkeys$.pipe(distinctUntilChangedObject(), debounceTime(200)), (hotkeys:any) => { // Use debounce to prevent "Expression changed after it has been checked" errors, when child compoment register shortcuts, which would update the list hotkeys in the same rendering cycle
			this.hotkeys = this.processHotkeys(hotkeys);
		});
		this.subscribe(this.InfostarsTools.dialogOpened$, (dialogId:string) => { // Need to wait for the dialog to be visible for the focus() to work
			if(dialogId !== this.InfostarsTools.hotkeysModalId)
				return;
			this.onShow();
 		});
	}

	public onShow() {
		this.searchQueryInput.nativeElement.focus();
	}

	private processHotkeys(hotkeys: Partial<HotkeyConfigExtended>[]) {
		return hotkeys.map(hk => {
			const keySeq = hk.keys.split(KEY_SEQ_SEPARATOR);
			const keySeqObj = keySeq.map(hk => {
				const modAndKeys = hk.split('.');
				return {
					modifiers: modAndKeys.slice(0, -1),
					key: modAndKeys[modAndKeys.length - 1],
				} as HotKey;
			});
			return {
				...hk,
				keySeq: keySeqObj,
			} as HotkeyConfigInfo
		}).sort((a, b) => {
			const catPrioCmp = (b.categoryPrio - a.categoryPrio); // Sort descending by category priority
			if(catPrioCmp !== 0)
				return catPrioCmp;
			const catCmp = (a.category || '').localeCompare((b.category || '')); // Sort ascending by category
			if(catCmp !== 0)
				return catCmp;
			return (a.command || '').localeCompare((b.command || '')); // Sort ascending by command
		});
	}
}
