import { Pipe, PipeTransform, Inject, Input, Injectable } from "@angular/core";

/** Handles the translation depending on the trackingType of the account
 * use with BaseComponentWithPermission like:
 * `{ 'stringToTranslate' | byTrackType:trackType }
*/
@Pipe({
	name: 'byTrackType',
	pure: true,
})
export class ByTrackTypePipe implements PipeTransform {
	transform(value:any, trackType:string) {
		return trackType === 'ANIMAL' ? value + '_animal' : value;
	}
}
