import { Injectable, Inject, Output, Input, EventEmitter } from '@angular/core';
import { config } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { StateParams, StateService } from '@uirouter/core';
import { DATE_PIPE_DEFAULT_TIMEZONE, Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import jQuery from 'jquery';
import { LocalStorageService} from './LocalStorage.service';
import { InfostarsToolsService } from './InfostarsTools.service';
import { ActivationEnd, Router } from '@angular/router';
import { DateTime, Settings } from 'luxon';
import { BackendRestService, FullResponseBackendRestService } from './Restangular.service';
import { filter, take } from 'rxjs/operators';

export type UserRights = {
	[key: string]: boolean
}
@Injectable()
/**
 * @ngdoc function
 * @name infostars.service:Login
 * @description
 * # Login
 * Everything related to logging in and holding information about the active user
 * @emitsEvent accountId$ Emitted when system users change the current accountId. Various parts of the UI should reload (vehicle tree etc.)
 * @emitsEvent login$ Emitted when the user is logged in. Various services should reset their state (user translations etc.)
 */
export class LoginService {
	private authHashKey = 'authHash';
	private sessionIdKey = 'sessionId';
	private forcedAccountKey = 'forcedAccount';
	private isInitTry = true;
	private tryLoginPromise:Promise<void> = null;
	private loginSuccessPromise:Promise<void> = null;
	private loginSuccessResolve:Function = null;
	private loginCheckInProgress = false;
	private tncCb:Function = null;
	private redirectPath:string = null;
	private suggestedAccountName:string = null;
	private suggestedUserName:string = null;
	private storedAuthHash = this.LocalStorage.get(this.authHashKey);
	private storedSessionId = this.LocalStorage.get(this.sessionIdKey);
	private sessionId:string = null; // The session ID is just for redirecting the user to legacy parts of the system and for logging out properly, it's not a security measure
	private _forceSelectAll = false; // A temporary override to select all drivers and vehicles (once)
	private user:any = null;
	private account:any = null;
	private _isAdmin = false;
	private defaultDriver:any = null;
	private forcedAccount = this.LocalStorage.get(this.forcedAccountKey);
	private userRoles:any = null;
	private userRights:UserRights = {};
	private userSettings:any = null;
	private drivers:Array<any> = null;
	private trackingTypes:any = null;
	private tourStatusTypes:any = null;
	private tourStatusTypesForCustom:any = null;
	private simProviders:String[] = null;

	public accountId$ = new EventEmitter<number>();
	public login$ = new EventEmitter<void>();
	public logout$ = new EventEmitter<void>();
	public loggedIn$ = new EventEmitter<boolean>();
	public userSettings$ = new EventEmitter<any>();
	public termsAndConditionsHtml$ = new EventEmitter<string>();
	public updateAvailable$ = new EventEmitter<string>();
	public loggedIn = false;
	public timezoneId:string = null;

	constructor(
		@Inject(InfostarsToolsService) public InfostarsTools: InfostarsToolsService,
		@Inject(BackendRestService) public BackendRest: any,
		@Inject(FullResponseBackendRestService) public FullResponseBackendRest: any,
		@Inject(LocalStorageService) public LocalStorage: LocalStorageService,
		@Inject(CookieService) public CookieService: CookieService,
		@Inject(Router) private $router: Router,
	) {
		if(this.storedAuthHash && 'null' !== this.storedAuthHash) {
			if(this.storedSessionId && 'null' !== this.storedAuthHash) {
				this.sessionId = this.storedSessionId;
			}else {
				this.LocalStorage.remove(this.sessionIdKey);
			}
			this.tryLogin(this.storedAuthHash);
		}else {
			this.LocalStorage.remove(this.authHashKey);
			this.LocalStorage.remove(this.sessionIdKey);
			this.$router.events.pipe(filter(event => event instanceof ActivationEnd), take(1)).subscribe((event: ActivationEnd) => {
				const rData = this.InfostarsTools.getAllRouteData(event.snapshot.root);
				if(!rData?.isPublic && !this.$router.getCurrentNavigation().finalUrl?.toString().includes('/login'))
					this.$router.navigate(['/', this.InfostarsTools.activeLang, 'login']);
			});
		}
		// Listen to 401 HTTP response codes (TODO: Filter on URL later on)
		this.BackendRest.setErrorInterceptor((response:any/*, operation, what, url */) => {
			if(!response || !response.status)
				return true;
			switch(response.status) {
				case 403: // Our custom auth failed HTTP code to prevent HTTP auth popups, see infostarsTapestryWeb.config.xml
				case 401:
					this.clearData();
					if(response.request.url.endsWith('/login/user') && response.request.method === 'GET')
						return true; // Don't redirect when we try to login, will take care of the failed promise below
					this.$router.navigate(['/', this.InfostarsTools.activeLang, 'login']);
					return false; // Prevent promise from running
			}
			return true;
		});
	}
	private saveUserSettings(settings:any) {
		// Transform the list of {name: X, value: Y} into a map for quick lookup
		let settingsMap:any = {};
		(settings.settings || []).forEach((val:any) =>  {
			settingsMap[val.name] = val.value;
		});
		settings.settings = settingsMap;
		this.userSettings = settings;
		this.userSettings$.emit(this.userSettings);
	}
	private saveUserData(result:any, hash:string) {
		this.LocalStorage.add(this.authHashKey, hash);
		this.LocalStorage.add(this.sessionIdKey, result.sessionId);
		this.storedAuthHash = hash;
		this.BackendRest.setDefaultHeaders({...this.BackendRest.defaultHeaders, 'X-Infostars-Session-Id': result.sessionId});
		this.FullResponseBackendRest.setDefaultHeaders({...this.FullResponseBackendRest.defaultHeaders, 'X-Infostars-Session-Id': result.sessionId});
		this.user = result.user;
		this.account = result.account;
		// Set the default timezone for the whole app (we should only use luxon for date display)
		let defTzId = Settings.defaultZone;
		Settings.defaultZone = result.timezoneId;
		this.timezoneId = DateTime.local().zoneName === result.timezoneId ? result.timezoneId : defTzId; // Try if setting the timezone worked
		Settings.defaultZone = this.timezoneId;
		this.defaultDriver = result.defaultDriver;
		this.sessionId = result.sessionId;
		this.userRoles = result.rights;
		this._isAdmin = result.rights && $.inArray('ROLE_SUPER_SYSTEM_ADMINISTRATOR', result.rights) > -1;
		this.userRights = {};
		this.drivers = result.drivers;
		this.trackingTypes = result.trackingTypes;
		this.tourStatusTypes = result.tourStatusTypes;
		this.tourStatusTypesForCustom = result.tourStatusTypes.filter((t:string) => t !== 'PRIVATE');
		this.simProviders = result.simProviders;
		if(!this._isAdmin)
			this.forcedAccount = null;
		if(this.forcedAccount)
			this.LocalStorage.add(this.forcedAccountKey, this.forcedAccount);
		else
			this.LocalStorage.remove(this.forcedAccountKey);
		result.rights.forEach((val:string) =>  { this.userRights[val] = true; });
		this.saveUserSettings(result.settings);
//		if(this.isAdmin && !gaIsOptedOut()) {
//			Messages.error('You are still being tracked in Analytics, <a href="' + this.$state.href('notrack') + '">CLICK HERE</a> to disable that if you are using this computer regularly.');
//		}else if(gaIsOptedOut()) {
//			Messages.info('You are excluded from Analytics tracking');
//		}
	}
	private clearData() {
		this.loggedIn = false;
		this._isAdmin = false;
		this.userRights = {};
		this.user = null;
		this.account = null;
		this.forcedAccount = null;
		this.sessionId = null;
		this.defaultDriver = null;
		this._forceSelectAll = false;
		this.tryLoginPromise = null;
		this.LocalStorage.remove(this.authHashKey);
		this.LocalStorage.remove(this.sessionIdKey);
		this.LocalStorage.remove(this.forcedAccountKey);
		// TODO Respect that other code might have set default headers already
		let defHeaders = this.BackendRest.defaultHeaders;
		delete defHeaders.Authorization;
		delete defHeaders['X-Infostars-Session-Id'];
		this.BackendRest.setDefaultHeaders(defHeaders);
		defHeaders = this.FullResponseBackendRest.defaultHeaders;
		delete defHeaders.Authorization;
		delete defHeaders['X-Infostars-Session-Id'];
		this.FullResponseBackendRest.setDefaultHeaders(defHeaders);
		this.logout$.emit();
		this.loggedIn$.emit(false);
		this.accountId$.emit(null);
	}
	private loginSuccess(hash:string, result:any) {
		this.saveUserData(result, hash);
		this.loggedIn = true;
		this.loginCheckInProgress = false;
		if(this.loginSuccessResolve)
			this.loginSuccessResolve(); // Notify callers of getLoginSuccessPromise() waiting for a successful login
		this.login$.emit();
		this.loggedIn$.emit(true);
	}
	private loginFail(error?:any) {
		this.clearData();
		this.loginCheckInProgress = false;
		if(this.isInitTry)
			this.$router.navigate(['/', this.InfostarsTools.activeLang, 'login']);
		this.loggedIn$.emit(false);
	}
	private failTermsConfirmation() {
		this.InfostarsTools.closeAgbModal();
		this.loginFail();
	}
	private async tryLogin(hash:string) {
		this.loginCheckInProgress = true;
		// TODO Respect that other code might have set default headers already
		this.BackendRest.setDefaultHeaders($.extend(this.BackendRest.defaultHeaders, {'Authorization': 'Basic ' + hash}));
		this.FullResponseBackendRest.setDefaultHeaders($.extend(this.FullResponseBackendRest.defaultHeaders, {'Authorization': 'Basic ' + hash}));
		this.CookieService.delete('JSESSIONID', config.basePath); // Make sure we discard the spring session, which is associated with an authentication, to avoid triggering: NullPointerException at org.springframework.security.web.authentication.www.BasicAuthenticationFilter.authenticationIsRequired(BasicAuthenticationFilter.java:241)
		// The session ID is just for comparing with an already existing login, so we can invalidate sessions of other users on the old UI
		return this.tryLoginPromise = new Promise<void>((resolve, reject) => {
			this.BackendRest.one('login/user').get(this.sessionId ? {sessionId: this.sessionId} : {}).subscribe((result:any) =>  {
				this.isInitTry = false;
				if(result.confirmAGB) {
					// Show a modal dialog with the AGB text andlet the user confirm it
					let tcHtml = result.agbHtml;
					tcHtml = tcHtml.replace(/<!\[[^\]]*\]>/g, '');//.replace(/<!\[endif\]-->/, ''); // Fix fucked up HTML, probably copied from Word
					this.termsAndConditionsHtml$.emit(tcHtml);
					this.InfostarsTools.openAgbModal();
					this.tncCb = (accepted:boolean) => {
						if(accepted) {
							// We need to store the confirmation on the server
							this.BackendRest.one('user/confirmterms').post(null, null, null, {'X-Infostars-Session-Id': result.sessionId}).subscribe(() =>  {
								($('#agbModal') as any).foundation('reveal', 'close');
								this.loginSuccess(hash, result);
								resolve();
							}, () => {
								this.failTermsConfirmation();
								reject();
							});
						}else { // tnc declined
							this.failTermsConfirmation();
							reject();
						}
					}
				}else {
					this.loginSuccess(hash, result);
					resolve();
				}
			}, (error:any) => {
				this.loginFail(error);
				this.isInitTry = false;
				reject(error);
			});
		});
	}
	private reloadUserSettings() {
		this.BackendRest.one('login/user').get({sessionId: this.sessionId}).subscribe((result:any) =>  {
			this.saveUserSettings(result.settings);
		});
	}
	public onTncAccepted() {
		if(this.tncCb)
			this.tncCb(true);
		this.tncCb = null;
	}
	public onTncDeclined() {
		if(this.tncCb)
			this.tncCb(false);
		this.tncCb = null;
	}
	public isAdmin() {
		return this._isAdmin;
	}
	public setSuggestedAccountAndUser(accountName:string, userName:string) {
		this.suggestedAccountName = accountName;
		this.suggestedUserName = userName;
	}
	public getAndResetAccountAndUserSuggestion() {
		const res = {
			accountName: this.suggestedAccountName,
			userName: this.suggestedUserName
		};
		this.suggestedAccountName = null;
		this.suggestedUserName = null;
		return res;
	}
	/** Set a path to return to after successfully logging in (see login.js controller) */
	public setRedirectPath(path:string) {
		this.redirectPath = path;
	}
	public getAndResetRedirectPath() {
		let path = this.redirectPath;
		this.redirectPath = null;
		return path;
	}
	/** Wait for the initial login to happen. If you pass update = true  */
	public getLoginPromise(update?:boolean):Promise<void> {
		if(this.tryLoginPromise) {
//				console.log('this.loginCheckInProgress: ' + (this.loginCheckInProgress ? 'true' : 'false') + ' update: ' + (update ? 'true' : 'false') + ' this.storedHash ' + this.storedHash);
			if(!update || this.loginCheckInProgress) {
				return this.tryLoginPromise;
			}else if(this.storedAuthHash) {
				return this.tryLogin(this.storedAuthHash);
			}else {
				console.log('Error, logged in, but no stored hash!???');
				return this.tryLoginPromise;
			}
		}
		if(update && this.storedAuthHash)
			return this.tryLogin(this.storedAuthHash)
		return Promise.reject(new Error('Not logged in'));
	}
	public getLoginSuccessPromise():Promise<void> {
		if(!this.loginSuccessPromise) {
			this.loginSuccessPromise = new Promise<void>((resolve, reject) => {
				if(this.loggedIn && !this.loginCheckInProgress) {
					resolve();
				}else if(!this.loggedIn) {
					// Wait for a while before rejecting the promise if login does not complete
					setTimeout(() => {
						if(!this.loggedIn) {
							reject();
							this.loginSuccessPromise = null;
							this.loginSuccessResolve = null;
						}
					}, 15000);
				}
				this.loginSuccessResolve = resolve;
			})
		}
		return this.loginSuccessPromise;
	}
	public getAccountId() {
		return this.forcedAccount ? this.forcedAccount.id : this.account.id;
	}
	public getAccountName() {
		return this.forcedAccount ? this.forcedAccount.name : this.account.name;
	}
	public setAccount(account:any) {
		if(!this.hasAllRights(['ACCESS_ALL_ACCOUNTS']) || !account || !account.id)
			return false;
		if(this.account.id === account.id)
			account = null;
		this.forcedAccount = account;
		this.LocalStorage.add(this.forcedAccountKey, this.forcedAccount);
		this.accountId$.emit(account ? account.id : this.account.id);
		return true;
	}
	public isOwnAccount() {
		return this.forcedAccount ? this.forcedAccount.id === this.account.id : true;
	}
	public forceSelectAll() {
		return this._forceSelectAll;
	}
	public setForceSelectAll(force:boolean) {
		this._forceSelectAll = force;
	}
	public getUserName() {
		return this.user.username;
	}
	public getUser() {
		return this.user;
	}
	public getUserSettings() {
		return this.userSettings;
	}
	public getUserRights() {
		return {...this.userRights};
	}
	public notifyUserSettingsChanged() {
		this.reloadUserSettings();
	}
	public getAccount() {
		return this.account;
	}
	public getDefaultDriver() {
		return this.defaultDriver;
	}
	public setDefaultDriver(driver:any) {
		this.defaultDriver = driver;
	}
	public getAssignedDrivers() {
		return this.drivers || [];
	}
	public setAssignedDrivers(drivers:Array<any>) {
		this.drivers = drivers || [];
	}
	public getTrackingTypes() {
		return this.trackingTypes;
	}
	public getTourStatusTypes() {
		return this.tourStatusTypes;
	}
	public getTourStatusTypesForCustom() {
		return this.tourStatusTypesForCustom;
	}
	public isWebshopEnabled() {
		return this.account && this.account.webshopEnabled;
	}
	public getSimProviders() {
		return this.simProviders;
	}
	public getSessionId() {
		return this.sessionId;
	}
	public getAuthBasicHash() {
		return this.LocalStorage.get(this.authHashKey);
	}
	public getTrackType() {
		if(!this.account) return 'VEHICLE';
			let account;
		if (this.isOwnAccount()) account = this.account;
		else account = this.forcedAccount;
			let type = account === null ? 'VEHICLE' : account.trackingType;
		return type ? type : 'VEHICLE';
	}
	/** Check if the user has all rights. The param can be a comma separated string
	 * or an array of strings See UserRight.java */
	public hasAllRights(rights:any) {
		if(!rights)
			return true;
		rights = !Array.isArray(rights) ? (rights || '').split(/, ?/) : rights;
		let missing = false; // true if at least one right is missing
		rights.some((val:string) =>  {
			return missing = !this.userRights[val] ? true : false; // .some breaks on returning true, so break once we find a missing right
		});
		return !missing;
	}
	/** Check if the user has any right. The param can be a comma separated string
	 * or an array of strings See UserRight.java */
	public hasAnyRight(rights:any) {
		if (!rights)
			return true;
		rights = !Array.isArray(rights) ? (rights || '').split(/, ?/) : rights;
		let found = false;
		rights.some((val:string) =>  {
			return found = this.userRights[val] ? true : false;
		});
		return found;
	}
	/** NEVER use this from within directives like ngShow, angularjs would evaluate it on every digest cycle */
	public isColEnabled(section:string, key:string) {
		if(!this.userSettings || !key)
			return false;
		key = key.length > 0 ? (key.substring(0, 1).toUpperCase() + key.substring(1, key.length)) : key;
		return this.userSettings.settings[section + key];
	}
	/** Get an associative array of settings with the given prefix (section) */
	public getColEnabled(section:string) {
		let colEnabled:any = {};
		Object.keys(this.userSettings.settings).forEach((key:string) =>  {
			const val = this.userSettings.settings[key];
			if(key.indexOf(section) === 0)
				colEnabled[key.substring(section.length)] = val;
		});
		return colEnabled;
	}
	public getShortcutSettings():any {
		let isSmallScreen = (this.InfostarsTools.isScreenSmall || this.InfostarsTools.isScreenMedium) && !this.InfostarsTools.isScreenLarge;
		let dispolight = {
			onlyMap: this.hasAllRights('SHOW_DISPO_MAP'),
			onlyList: !this.hasAllRights('SHOW_DISPO_MAP') || isSmallScreen,
			both: this.hasAllRights('SHOW_DISPO_MAP') && !isSmallScreen,
			enabled: false,
		};
		dispolight.enabled = this.hasAllRights('SHOW_DISPO_TAB') && (dispolight.onlyMap || dispolight.onlyList || dispolight.both);
		return {
			fafb: {enabled: this.hasAllRights('SHOW_FAFB')},
			logbook: {enabled: this.hasAllRights('SHOW_DISPO_TAB,SHOW_DISPO_LOGBOOK')},
			dispolight: dispolight,
			routines: {enabled: this.hasAllRights('SHOW_ROUTINES') && this.hasAnyRight('EDIT_ROUTINES,EDIT_ROUTINE_ACTIVE_STATUS')}
		};
	}
	public login(user:string, password:string) {
		let hash = window.btoa(window.unescape(encodeURIComponent( (user || '').trim() + ':' + (password || '').trim() )));
		this.sessionId = null;
		return this.tryLogin(hash);
	}
	public logout() {
		this.BackendRest.one('login/session', this.sessionId).one('logout').post().subscribe(() =>  {
			console.log('Logout on server successful');
		});
		this.clearData();
	}
	public notifyUpdateAvailable(newVersionInfo: string) {
		this.updateAvailable$.emit(newVersionInfo);
	}
}
