import { InjectionToken, Inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Restangular } from "ngx-restangular";
import { config } from "../environments/environment";
import { MessagesService } from "./messages.service";

export function RestangularConfigFactory (RestangularProvider:any) {
// 	RestangularProvider.setBaseUrl('http://api.restngx.local/v1');
}
// Restangular service for our backend API
export const BackendRestService = new InjectionToken<any>('BackendRest');
export function RestangularBackendFactory(restangular: Restangular, Messages: MessagesService, $translate:TranslateService) {
	return restangular.withConfig((RestangularConfigurer:any) => {
		RestangularConfigurer.setBaseUrl(config.restBaseUrl);
		// Set this header to receive 403 instead of 401 status codes (custom behaviour of our server, see infostarsTapestryWeb.config.xml)
		// This is used to prevent username / pw popups
		RestangularConfigurer.setDefaultHeaders({'X-Requested-With': 'XMLHttpRequest'});
		RestangularConfigurer.setPlainByDefault(true); // Don't add restangular methods to data results
		// Required for DELETE to be application/json instead of text/plain (which the server does not like)
		RestangularConfigurer.setRequestInterceptor((elem:any, operation:string) => {
			if (operation === 'remove') {
				return undefined;
			}
			return elem;
		});
		// Remove null values from query parameters. Breaks e.g. /rest/logbook/level1 vehicle parameter
		RestangularConfigurer.addFullRequestInterceptor((element:any, operation:string, path:string, url:string, headers:any, params:any)=> {
			for(let key in params) {
				const val = params[key];
				if (val === null || val === undefined || val === '')
					delete params[key];
			}
			return {
				element: element,
				headers: headers,
				params: params,
			};
		});
		RestangularConfigurer.addResponseInterceptor((data:any, operation:string, what:any, url:string, response:any) => {
			var headers = response.headers;
			var level = headers.get('x-acoveo-errorlevel');
			if (level !== 'undefined' && level !== null) {
				var msgCode = headers.get('x-acoveo-errormessage');
				if(level === 'ERROR')	Messages.error($translate.instant(msgCode));
				else if(level === 'WARNING')	Messages.warning($translate.instant(msgCode));
				else if(level === 'INFO')	Messages.info($translate.instant(msgCode));
			}
			const currPage = headers.get('x-pagination-current-page'), perPage = headers.get('x-pagination-per-page'), totalPages = headers.get('x-pagination-total-pages');
			if(currPage) {
				data['_currPage'] = Number(currPage);
				data['_perPage'] = Number(perPage);
				data['_totalPages'] = Number(totalPages);
			}
			return data;
		});
	});
}
export const BackendRestNoAuthService = new InjectionToken<any>('BackendRestNoAuth');
export function RestangularBackendNoAuthFactory(restangular: Restangular, Messages: MessagesService, $translate:TranslateService) {
	return restangular.withConfig((RestangularConfigurer:any) => {
		RestangularConfigurer.setBaseUrl(config.restBaseUrl);
		// Set this header to receive 403 instead of 401 status codes (custom behaviour of our server, see infostarsTapestryWeb.config.xml)
		// This is used to prevent username / pw popups
		RestangularConfigurer.setDefaultHeaders({'X-Requested-With': 'XMLHttpRequest'});
		// Required for DELETE to be application/json instead of text/plain (which the server does not like)
		RestangularConfigurer.setRequestInterceptor((elem:any, operation:string) => {
			if (operation === 'remove') {
				return undefined;
			}
			return elem;
		})
	});
}
// Restangular service that uses setFullResponse
export const FullResponseBackendRestService = new InjectionToken<any>('FullResponseBackendRest');
export function RestangularFullResponseFactory(BackendRest:any) {
	return BackendRest.withConfig((RestangularConfigurer:any) => {
		RestangularConfigurer.setFullResponse(true);
	});
}
