<div class="logo-header-bar show-for-large-up hide-for-print" [ngStyle]="{flexShrink: 0, overflow: 'hidden', transition: 'max-height 1s', maxHeight: logoMaxHeight}">
	<div class="grid-block" style="overflow: hidden;">
		<div class="grid-block shrink" style="overflow: hidden;">
			<img [src]="imgBase + 'images/header_left.png'" style="height: 102px;">
		</div>
		<div class="grid-block" [ngStyle]="fillerStyle">
		</div>
		<div class="grid-block shrink" style="overflow: hidden;">
			<img [src]="imgBase + 'images/header_right.png'" style="height: 102px;">
		</div>
	</div>
</div>
