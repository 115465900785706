import { Injectable, Inject, Output, Input, EventEmitter } from '@angular/core';
import { DispoSettingsService } from './disposettings.service';

export interface OverrideProperties {
	/** Driver ids that should be selected, to make sure at least these drivers are shown e.g. in the financial logbook */
	drivers: Number[]
}

/** Used to (temporarily) override certain selections in filter.component.ts
 * At the moment only used for drivers. */
@Injectable()
export class FilterOverrideService {
	private overrideProperties:OverrideProperties = null;

	constructor(protected DispoSettings:DispoSettingsService) {}

	public setOverrideProperties(value:OverrideProperties) {
		this.overrideProperties = value;
		this.DispoSettings.filterUpdated();
	}

	public getAndClearOverrideProperties() {
		let temp = this.overrideProperties;
		this.overrideProperties = null;
		return temp;
	}
}
