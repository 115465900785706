// webpack imports that are related to module.rules in webpack.common.js
import jQuery from 'jquery';
import 'jquery-appear-original';

// Monkey patch the $.load function for foundation 5 , see https://stackoverflow.com/questions/38400081/zurb-foundation-5-and-jquery-3-incompatible
var jQueryAny = (jQuery as any).fn;
console.log('old load', jQueryAny.load);
jQueryAny.load = function(cb:any) {
	this.on('load', cb);
	return this;
}
console.log('new load', jQueryAny.load);

import 'lodash';
import 'foundation-sites';
import 'foundation-sites/js/foundation/foundation.reveal';
import 'foundation-sites/js/foundation/foundation.abide';
import 'foundation-sites/js/foundation/foundation.topbar';
import 'foundation-sites/js/foundation/foundation.alert';
import 'foundation-sites/js/foundation/foundation.offcanvas';
import 'foundation-sites/js/foundation/foundation.orbit';
import 'foundation-sites/js/foundation/foundation.dropdown';
import 'foundation-sites/js/foundation/foundation.tooltip';
import 'foundation-sites/js/foundation/foundation.tab';
import '../modernizr';
import 'moment';
import 'spectrum-colorpicker2';
