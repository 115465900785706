import { Component, ViewChild, Inject, Output, Input, EventEmitter, OnInit, OnDestroy, Injectable } from '@angular/core';
import { BaseComponent } from '../base.component';
import { BackendRestService } from './Restangular.service';
import { LoginService } from './login.service';
import { InfostarsToolsService } from './InfostarsTools.service';
import { Restangular } from 'ngx-restangular';
import { DispoSettingsService } from './disposettings.service';
import { of } from 'rxjs';

/** Keeps this.zones cached locally as the searchfilter changes */
@Injectable()
export class ZonesService extends BaseComponent {
	private searchFilter:any; // Kept up-to dat with DispoSettingService
	private fetchZonesSubsc = null as any;
	private zonesPromise:Promise<any> = null;
	private showZonesPromise:Promise<any> = null;
	private zones:{[key: string]: any} = {};

	constructor(
		@Inject(BackendRestService) public BackendRest: Restangular,
		@Inject(LoginService) public Login: LoginService,
		@Inject(InfostarsToolsService) public InfostarsTools: InfostarsToolsService,
		@Inject(DispoSettingsService) public DispoSettings: DispoSettingsService,
	) {
		super(InfostarsTools);
		// Watch for relevant searchFilter changes
		DispoSettings.getPromise().then((searchFilter) => {
			this.searchFilter = searchFilter;
			let currFilterGeoAreas = JSON.stringify(searchFilter.geoAreas);
			let currFilterShowGeoAreas = JSON.stringify(searchFilter.showGeoAreas);
			this.subscribe(DispoSettings.searchFilter$, (searchFilter) => {
				this.searchFilter = searchFilter;
				if(JSON.stringify(searchFilter.geoAreas) !== currFilterGeoAreas ||
						JSON.stringify(searchFilter.showGeoAreas) !== currFilterShowGeoAreas) {
					this.getData();
				}
			});
		});
	}

	private saveData(result:any) {
		result.forEach((res:any) =>  {
			this.zones[res.id] = res;
		});
	}
	private getSelectedZones() {
		let selectedZones = {} as any;
		this.searchFilter.geoAreas.forEach((zone:any) =>  {
			selectedZones[zone] = this.zones[zone];
		});
		return selectedZones;
	}
	private getSelectedShowZones() {
		let selectedShowZones = {} as any;
		this.searchFilter.showGeoAreas.forEach((zone:any) =>  {
			selectedShowZones[zone] = this.zones[zone];
		});
		return selectedShowZones;
	}
	private clearData() {
		this.zones = {};
		this.zonesPromise = null;
		this.showZonesPromise = null;
	}
	private getData() {
		if(this.fetchZonesSubsc)
			return; // Prevent multiple requests at once
		let deferredZones:any = { promise: null as Promise<any>, resolve: null as (val: unknown) => void, reject: null as (reason: unknown) => void};
		deferredZones.promise = new Promise((resolve, reject) => {
			deferredZones.resolve = resolve;
			deferredZones.reject = reject;
		});
		let deferredShow:any = { promise: null as Promise<any>, resolve: null as (val: unknown) => void, reject: null as (reason: unknown) => void};
		deferredShow.promise = new Promise((resolve, reject) => {
			deferredShow.resolve = resolve;
			deferredShow.reject = reject;
		});
		this.fetchZonesSubsc = (this.Login.hasAllRights('SHOW_ZONES') ? this.BackendRest.one('zone').get({accountId: this.Login.getAccountId(), usage:'ALL'}) : of([])).subscribe((zones:any) => {
			this.fetchZonesSubsc = null;
			this.saveData(zones);
			deferredZones.resolve(this.getSelectedZones());
			deferredShow.resolve(this.getSelectedShowZones());
		}, () => {
			this.fetchZonesSubsc = null;
			this.clearData();
			deferredZones.reject(false);
			deferredShow.reject(false);
		});
		this.zonesPromise = deferredZones.promise;
		this.showZonesPromise = deferredShow.promise;
	}
	public getZones(/*update*/) {
		if(this.zonesPromise)
			return this.zonesPromise;
		this.getData();
		return this.zonesPromise;
	}
	public getShowZones(/*update*/) {
		if(this.showZonesPromise)
			return this.showZonesPromise;
		this.getData();
		return this.showZonesPromise;
	}
}
