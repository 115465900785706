import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { filter, first } from 'rxjs/operators';
import { ActivationStart, NavigationEnd, Router } from '@angular/router';
import { BaseComponentWithPermission } from '../../base.component';
import { InfostarsToolsService } from "../../services/InfostarsTools.service";
import { LoginService } from "src/app/services/login.service";
import { ConfigService } from "src/app/services/config.service";
import { forkJoin, from } from "rxjs";
import { HotkeysService } from "src/app/services/hotkeys.service";

export interface NavbarFilterApi {
	onOpenMenu():void;
}
@Component({
	selector: 'navbar',
	templateUrl: 'navbar.html',
})
export class NavbarCtrl extends BaseComponentWithPermission implements OnInit, OnDestroy {
	@Input() filterComp: NavbarFilterApi;
	public enableFilter = false;
	public date = new Date();
	public isAnonLogin = false;
	public username = '';
	public forcedAccountName:string = null;
	public updateAvailable = false;

	private routeFilterConfig: {[name: string]: any};

	constructor(
		public InfostarsTools: InfostarsToolsService,
		public $router: Router,
		public Login: LoginService,
		private Config: ConfigService,
		public Hotkeys: HotkeysService,
	) { super(InfostarsTools, Login); }

	ngOnInit(): void {
		// Fill this.hide properties according to router state data
		this.subscribe(this.$router.events.pipe(filter(event => event instanceof NavigationEnd)), (ev: NavigationEnd) => {
			const rData = this.InfostarsTools.getAllRouteData(this.$router.routerState.snapshot.root);
			this.routeFilterConfig = rData.filterConfig;
			this.enableFilter = this.isEnableFilter();
		});
		this.subscribe(this.Login.accountId$, () => this.forcedAccountName = this.Login.isOwnAccount() ? false : this.Login.getAccountName());
		this.subscribe(this.Login.loggedIn$, () => this.updateFromLoginData());
		this.subscribe(this.Login.updateAvailable$, () => this.updateAvailable = true);
			// this.newMenuHandler('settings'));
		this.updateFromLoginData();
	}

	private isEnableFilter() {
		return (this.routeFilterConfig && this.Login.hasAllRights('SHOW_DISPO_FILTER')) ? true : false;
	}
	private updateFromLoginData() {
		forkJoin([this.Config.getConfig(), this.Login.getLoginPromise()]).subscribe((res: any) => {
			let config = res[0];
			let acc = this.Login.getAccount();
			this.enableFilter = this.isEnableFilter();
			this.forcedAccountName = this.Login.isOwnAccount() ? false : this.Login.getAccountName();
			this.username = this.Login.getUserName();
			this.isAnonLogin = config.anonLoginEnabled && acc && acc.name === config.anonAccount && this.Login.getUserName() === config.anonUser;
		}, () => {
			this.forcedAccountName = null;
			this.username = '';
			this.isAnonLogin = false;
		});
	}

	/** The topbar on mobile only closes the menu, if the href attribute is set to '#', but we have it set to the actual path that the link points to */
	public fixTopbarMobile(e: any) {
		let href = e.target.getAttribute('href');
		e.target.setAttribute('href', '#');
		setTimeout(() => e.target.setAttribute('href', href), 1); // Set it back to the actual value (useful for copying links)
	}
	public onOpenMenu(ev: Event) {
		//XXXAngularIO if($rootScope.filterControl.openMenu) // This is not available on *every* single page
		this.filterComp.onOpenMenu();
		ev.stopPropagation(); // Prevent the close handler to fire, as we are within st-content
	}
	public onLogout() {
		this.Login.logout();
		this.$router.navigate(['/', this.InfostarsTools.activeLang, 'login']);
	}
	public onUpdateApp() {
		window.location.reload();
	}
}
