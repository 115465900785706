import jQuery from 'jquery';
import { Injectable, Inject, Output, Input, EventEmitter } from '@angular/core';
import { config } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { StateParams, StateService } from '@uirouter/core';
import { Location } from '@angular/common';
import { MessagesService } from './messages.service';
import { LocalStorageService } from './LocalStorage.service';
import { LoginService } from './login.service';
import { BackendRestNoAuthService } from './Restangular.service';
import { Restangular } from 'ngx-restangular';

/**
 * @name infostars.service:Config
 * @description
 * # Config
 * Configuration data from the backend
 */
@Injectable()
export class ConfigService {
	private configPromise:Promise<any> = null;
	private requestInProgress = false;
	private config:any = null;

	constructor(
		@Inject(BackendRestNoAuthService) public BackendRestNoAuth: Restangular,
		@Inject(MessagesService) public Messages: MessagesService,
		@Inject(LocalStorageService) public localStorage: LocalStorageService,
		@Inject(LoginService) public Login: LoginService,
	) {
		this.getData();
	}

	private saveUserData(result:any) {
		this.config = result;
	}
	private clearData() {
		this.config = null;
		this.configPromise = null;
	}
	private getData() {
		this.requestInProgress = true;
		return this.configPromise = new Promise<any>((resolve, reject) => {
			this.BackendRestNoAuth.one('config').get().subscribe((result:any) =>  {
				this.saveUserData(result);
				this.requestInProgress = false;
				resolve(this.config);
			}, () => {
				this.clearData();
				this.requestInProgress = false;
				reject();
			});
		});
	}
	public getConfig(/*update*/) {
		if(this.configPromise)
			return this.configPromise;
		return Promise.reject();
	}
}
