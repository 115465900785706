import { Injectable, Inject, Output, Input, EventEmitter } from '@angular/core';
import { config } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { StateParams, StateService } from '@uirouter/core';
import { Location } from '@angular/common';
import { BaseComponent } from '../base.component';
import jQuery from 'jquery';
import { LoginService } from './login.service';
import { MessagesService } from './messages.service';
import { LocalStorageService } from './LocalStorage.service';
import { InfostarsToolsService } from './InfostarsTools.service';
import { Restangular } from 'ngx-restangular';
import { BackendRestService } from './Restangular.service';

@Injectable()
export class RoutineService extends BaseComponent {

	private currentRoutine:any = null;
	private routinesPromise:Promise<any> = null;
	private routinesRequestInProgress = false;

	private filteredRoutinesPromise:Promise<any> = null;
	private filteredRoutinesRequestInProgress = false;
	private currentFilter:any = null;

	// for pre-processing
	private listVehicleThreshold = 3;
	private notifications:Array<any> = [];
	private vehicles:Array<any> = [];
	private calendar:Array<any> = [];

	constructor(
		public InfostarsTools: InfostarsToolsService,
		public Messages: MessagesService,
		public LocalStorage: LocalStorageService,
		@Inject(BackendRestService) public BackendRest: Restangular,
		public Login: LoginService,
	) {
		super(InfostarsTools);
		// We need to reload if the selected account changes
		this.subscribe(this.Login.accountId$, accId => {
			this.filteredRoutinesPromise = null;
			this.getRoutinesPromisePriv();
		})
	}

	private preProcessRoutines(routines:Array<any>) {
		let keepGoing = true;

		routines.forEach((value:any) => {
			if(value.catflap)
				value.inputTypeAbbreviation = -1;

			// prepare this.notifications string
			if (value.notifications) {
				let counter:any = {};
				(value.notifications || []).forEach((value2:any) => {
					if (value2.lastName) {
						// new way see Support #8077: point 6
						if (!counter[value2.lastName]) {
							counter[value2.lastName] = 1;
						} else {
							counter[value2.lastName] = (counter[value2.lastName]+1);
						}
					}
				});
				// new way of assembling name string
				let resultString = '';
				for (const name in counter) {
					const counts = counter[name];
					if (counts === 1)
						resultString += name + ', ';
					else
						resultString += name + ' (' + counts + ')' + ', ';
				}
				this.notifications[value.id] = resultString;
			}

			// prepare this.vehicles string
			if (value.vehicles) {
				value.vehicles.some((value2:any) => {
					if (value2.name) {
						if (!this.vehicles[value.id]) {
							this.vehicles[value.id] = value2.name + ', ';
						} else {
							// maybe find a more elegant solution for too many entries in this column
							if (this.vehicles[value.id].split(', ').length-1 === this.listVehicleThreshold) {
								this.vehicles[value.id] += '...';
								return true; // break
							} else {
								this.vehicles[value.id] += value2.name + ', ';
							}
						}
					}
					return false;
				});
			}

			// prepare calendar
			let oneTime = false;
			if (value.timeConditions) {
				keepGoing = true;
				value.timeConditions.forEach((value2:any) => {
					if (keepGoing && value2.onetime) { // it is one time
						oneTime = true;
						this.calendar[value.id] = 'onetime';
						keepGoing = false;
					}
				});
				if (!oneTime) {
					let startDates = value.timeConditions.map((elem:any) => elem.startDate);
					let endDates = value.timeConditions.map((elem:any) => elem.endDate);
					let inverts = value.timeConditions.map((elem:any) => elem.invert);

					let startDatesU = Array.from(new Set(startDates));
					let endDatesU = Array.from(new Set(endDates));
					let invertsU = Array.from(new Set(inverts));

					let allSame = startDatesU.length <= 1 && endDatesU.length <= 1 && invertsU.length <= 1;
					let isDailyCode = value.timeConditions[0].weekday === 0;

					this.calendar[value.id] = ((value.timeConditions.length === 7 && allSame) || isDailyCode) ? 'daily' : 'days';
				}
			}
		});
	}

	public getNotificationType(notification:any) {
		if (notification.destination) {
			return 'alarmCentral';
		} else if (notification.phone) {
			return 'sms';
		} else {
			return 'email';
		}
	}

	public getCalendarCode(routine:any) {
		if (!routine.timeConditions || routine.timeConditions.length === 0) return '';
		if (routine.timeConditions[0].onetime) return 'onetime';

		let startDates = routine.timeConditions.map((elem:any) => elem.startDate);
		let endDates = routine.timeConditions.map((elem:any) => elem.endDate);
		let inverts = routine.timeConditions.map((elem:any) => elem.invert);

		let startDatesU = Array.from(new Set(startDates));
		let endDatesU = Array.from(new Set(endDates));
		let invertsU = Array.from(new Set(inverts));

		let allSame = startDatesU.length <= 1 && endDatesU.length <= 1 && invertsU.length <= 1;
		let isDailyCode = routine.timeConditions[0].weekday === 0;

		return ((routine.timeConditions.length === 7 && allSame) || isDailyCode) ? 'daily' : 'days';
	}

	private getRoutinesPromisePriv() {
		this.routinesRequestInProgress = true;

		return this.routinesPromise = new Promise<any>((resolve, reject) => {
			this.Login.getLoginPromise().then(() => {
				let params = {accountId: this.Login.getAccountId()};
				this.BackendRest.one('alarmstar/routine').get(params).subscribe((result:any) =>	{
					this.preProcessRoutines(result);
					resolve(result);
					this.routinesRequestInProgress = false;
				}, () => {
					this.routinesPromise = null;
					reject(false);
					this.routinesRequestInProgress = false;
				});
			});
		});
	}

	private getFilteredRoutinesPromisePriv(filter:any) {
		this.filteredRoutinesRequestInProgress = true;
		this.currentFilter = filter;
		return this.filteredRoutinesPromise = new Promise<any>((resolve, reject) => {
			this.BackendRest.one('alarmstar/routine/filteredByAccount').get(filter).subscribe((result:any) =>	{
				resolve(result);
				this.filteredRoutinesRequestInProgress = false;
			}, () => {
				this.filteredRoutinesPromise = null;
				reject(false);
				this.filteredRoutinesRequestInProgress = false;
			});
		});
	}

	public getNotifications(routineId:number) {
		if (!this.notifications)
			return '';
		let notificationString = this.notifications[routineId];
		if (notificationString)
			return notificationString.substring(0, notificationString.length-2);
		else
			return '';
	};

	public getVehicles(routineId:number) {
		if (!this.vehicles)
			return '';
		let vehicleString = this.vehicles[routineId];
		if (vehicleString) {
			if (vehicleString.split(', ').length-1 === this.listVehicleThreshold) {
				return vehicleString;
			} else {
				return vehicleString.substring(0, vehicleString.length-2);
			}
		} else {
			return '';
		}
	};

	public isCalendarOneTime(routineId:number) {
		if (!this.calendar)
			return false;
		return this.calendar[routineId] === 'onetime';
	};

	public isCalendarDaily(routineId:number) {
		if (!this.calendar)
			return false;
		return this.calendar[routineId] === 'daily';
	};

	public isCalendarDays(routineId:number) {
		if (!this.calendar)
			return false;
		return this.calendar[routineId] === 'days';
	};

	public setCurrentRoutine(routine:any) {
		this.currentRoutine = routine;
		//this.rootScope.$broadcast('ROUTINE_TAG', routine);
	};

	public getCurrentRoutine() {
		return this.currentRoutine;
	};

	public getRoutinesPromise(update?:boolean) {
		if(this.routinesPromise) {
			if(!update || this.routinesRequestInProgress)
				return this.routinesPromise;
			else if(update)
				return this.getRoutinesPromisePriv();
			else
				return this.routinesPromise;
		}
		return Promise.reject(false);
	}
	public getFilteredRoutinesPromise(filter:any) {
		let sameRequest = this.currentFilter === filter;
		if(sameRequest && this.filteredRoutinesPromise)
			return this.filteredRoutinesPromise;
		else
			return this.getFilteredRoutinesPromisePriv(filter);
	}
}
