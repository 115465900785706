import { Component, ChangeDetectorRef, NgZone, ViewChild, Inject, Output, EventEmitter, OnInit, OnDestroy, Injector } from '@angular/core';
import { config } from './environments/environment'
import { Router, ActivatedRoute } from '@angular/router';
import { InfostarsToolsService } from './services/InfostarsTools.service';

/** Used for enforcing valid languages */
@Component({
	template: '<router-outlet></router-outlet>',
	host: { class: 'grid-block' } // foundation apps grid-blocks need to be directly nested without other elements in between in the tree
})
export class LanguageRouteComponent implements OnInit, OnDestroy {
	constructor(
		@Inject(InfostarsToolsService) protected InfostarsTools: InfostarsToolsService,
		@Inject(ActivatedRoute) private $route: ActivatedRoute,
		@Inject(Router) private $router: Router,
	) {
	}

	ngOnInit() {
		// Enforce only valid languages (after initial redirects with no language are covered in AppInit.ts)
		this.$route.params.subscribe((routeParams) => {
			let lang = routeParams['lang'];
			if(!lang) { // Make sure we always have a language in the path, e.g. https://example.com => https://example.com/en
				this.$router.navigate([config.determineLanguage()]);
				return;
			}
			// Redirect to a valid language, if the language provided is unsupported
			const allowedLangs = Object.keys(config.supportedLangMap);
			if(!allowedLangs.includes(lang)) {
				// Covers the https://example.com/my/page => https://example.com/en/my/page case
				const commands = this.InfostarsTools.generateRoutePath(this.$route, {
					params: {
						lang: config.determineLanguage(),
					},
					preserveChildMatrix: true
				});
				this.$router.navigate(commands, { queryParamsHandling: 'preserve', relativeTo: this.$route.parent });
			}
		});
	}
	ngOnDestroy(): void {
	}
}
