import { Directive, Input, ElementRef, Output, Inject, OnInit, EventEmitter, OnDestroy, HostBinding, HostListener } from "@angular/core";
import jQuery from 'jquery';

/** Drag and drop support from http://codepen.io/parkji/pen/JtDro http://blog.parkji.co.uk/2013/08/11/native-drag-and-drop-in-angularjs.html */
@Directive({
	selector: '[ppDraggable]',
})
export class ppDraggableDirective {
	@Input('item') item:any;
	@HostBinding('draggable') draggable = true;
	constructor(
		private element: ElementRef,
	){}
	@HostListener('dragstart', ['$event']) onDragStart(e:DragEvent) {
		let el = e.currentTarget as any;
		console.log('dragstart on ' + el.id);
		e.dataTransfer.effectAllowed = 'move';
		if(!el.id || el.id === '')
			el.id = 'pDraggable-' + Date.now();
		e.dataTransfer.setData('text/plain', el.id);
		e.dataTransfer.setData('Item', this.item);
		el.classList.add('drag');
	}
	@HostListener('dragend', ['$event']) onDragEnd(e:DragEvent) {
		let el = e.currentTarget as any;
		console.log('dragend on ' + el.id);
		el.classList.remove('drag');
		return false;
	}
}
export interface HandleDropData {
	id: string;
	binId: string;
	data: any;
	bin: any;
	event: DragEvent
};
@Directive({
	selector: '[ppDroppable]',
})
export class ppDroppableDirective {
	/** Whether to append the dragged DOM element to the drop target */
	@Input('appendDraggable') appendDraggable:boolean = false;
	/** Identifies the droppable element to the onDrop handler, if you re-use the same handler for multiple targets */
	@Input('bin') bin:any;
	/** Receives */
	@Output('onDrop') onDropEmit = new EventEmitter<any>();
	constructor(
		private element: ElementRef,
	){}
	@HostListener('dragover', ['$event']) onDragOver(e:DragEvent) {
		let el = e.currentTarget as any;
		// console.log('dragover on ' + el.id);
		e.dataTransfer.dropEffect = 'move';
		// allows us to drop
		if (e.preventDefault)
			e.preventDefault();
		el.classList.add('over');
		return false;
	}
	@HostListener('dragenter', ['$event']) onDragEnter(e:DragEvent) {
		let el = e.currentTarget as any;
		// console.log('dragenter on ' + el.id);
		el.classList.add('over');
	}
	@HostListener('dragleave', ['$event']) onDragLeave(e:DragEvent) {
		let el = e.currentTarget as any;
		// console.log('dragleave on ' + el.id);
		el.classList.remove('over');
	}
	@HostListener('drop', ['$event']) onDrop(e:DragEvent) {
		let el = e.currentTarget as any;
		// console.log('drop on ' + el.id);
		// Stops some browsers from redirecting.
		if (e.stopPropagation)
			e.stopPropagation();
		
		el.classList.remove('over');
		
		var binId = el.id;
		var itemId = e.dataTransfer.getData('text/plain');
		var dragEl = document.getElementById(itemId);
		if(dragEl.id.indexOf('pDraggable-') === 0) // Remove the id that ppDraggable set on the dragged html element
			dragEl.id = null;
		var itemData = e.dataTransfer.getData('Item');
		try {
			if(this.appendDraggable && dragEl)
				el.appendChild(dragEl);
		}catch(ex) {
			// Might fail if dropped on itself
			e.preventDefault();
			return false;
		}
		// call the passed drop function
		this.onDropEmit.emit({id: dragEl ? dragEl.id : null, binId: binId, data: itemData, bin: this.bin, event: e} as HandleDropData);
		return false;
	}
}