import { config } from '../environments/environment';
import { Injectable } from '@angular/core';
import { load, Root, Type } from 'protobufjs';
import Long from 'long';

@Injectable()
/** Provides the functionality for parsing protocol buffer messages using the dcodeIO.ProtoBuf library */
export class ProtoBufService {
	private cache:{ [key: string]: Type } = {};

	constructor(
	) {}

	private buildProto(protoClass:string): Promise<Type> {
		let classObj = this.cache[protoClass];
		if(classObj)
			return Promise.resolve(classObj);
		// Build the package names as used by protobuf, e.g. org.acoveo.infostars.tapestry.rest.DispoLogbookLayer1Result
		// -> org_acoveo_infostars_tapestry_rest.DispoLogbookLayer1Result
		let lastDotIdx = protoClass.lastIndexOf('.');
		let pkgStr = protoClass.substring(0, lastDotIdx).replace(/\./g, '_');
		let simpleClassName = protoClass.substring(lastDotIdx + 1);
		let builderDefer = new Promise<Type>((resolve, reject) => {
			load('assets/protobuf/' + simpleClassName + '.proto', (err:any, root:Root) => {
				if(err)
					reject(err);
				classObj = root.lookupType(pkgStr + '.' + simpleClassName);
				this.cache[protoClass] = classObj;
				return resolve(classObj);
			});
		});
		return builderDefer;
	}
	public builderDispoLogbookLayer1Result(): Promise<Type> {
		return this.buildProto('org.acoveo.infostars.tapestry.rest.DispoLogbookLayer1Result');
	}
	public builderDispoLogbookLayer2Result(): Promise<Type> {
		return this.buildProto('org.acoveo.infostars.tapestry.rest.DispoLogbookLayer2Result');
	}
	public builderDispoLogbookLayer3Result(): Promise<Type> {
		return this.buildProto('org.acoveo.infostars.tapestry.rest.DispoLogbookLayer3Result');
	}
	public builderDispoLogbookLayer4Result(): Promise<Type> {
		return this.buildProto('org.acoveo.infostars.tapestry.rest.DispoLogbookLayer4Result');
	}
	public builderDispoLogbookRouteResult(): Promise<Type> {
		return this.buildProto('org_acoveo_infostars_service_persistence_entities.DispoLogbookRouteResult');
	}
	public builderDispoLogbookFuelRefillResult(): Promise<Type> {
		return this.buildProto('org.acoveo.infostars.tapestry.rest.DispoLogbookFuelRefillResult');
	}
	public builderAssetLogbookLayer1Result(): Promise<Type> {
		return this.buildProto('org.acoveo.infostars.tapestry.rest.AssetLogbookLayer1Result');
	}
	/** A wrapper for easy conversion when working on protobuf data */
	public toNumber(longObj:any) {
		if(longObj.toNumber)
			return longObj.toNumber();
		return new Long(longObj.high, longObj.low).toNumber();
	}
	/** A wrapper for easy conversion when working on protobuf data */
	public toString(longObj:any) {
		if(longObj.toString)
			return longObj.toString();
		return new Long(longObj.high, longObj.low).toString();
	}
}
