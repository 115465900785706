import { Inject, EventEmitter, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { config } from "../environments/environment";
import { Router, ActivatedRoute, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd } from '@angular/router';
import { DOCUMENT } from "@angular/common";
import { Title, Meta } from "@angular/platform-browser";
import { BaseComponent } from "../base.component";
import { InfostarsToolsService } from "./InfostarsTools.service";
import { filter } from "rxjs/operators";

/** Support for setting meta tags relevant for Search Engine Optimisation */
@Injectable()
export class SEOService extends BaseComponent {
	public seo = {defaultTitle: '', title: '', defaultDescription: '', description: '', defaultKeywords: '', keywords: ''};
	/** Emitted on any change to this.seo (except for default values) */
	public seo$ = new EventEmitter<void>();

	private description: HTMLMetaElement;

	constructor(
		@Inject(InfostarsToolsService) protected InfostarsTools: InfostarsToolsService,
		@Inject(Title) private title: Title,
		@Inject(Meta) private meta: Meta,
		@Inject(TranslateService) private $translate: TranslateService,
		@Inject(Router) public $router: Router,
	) {
		super(InfostarsTools);
		this.meta.addTag({ name: 'description', content: this.seo.defaultDescription});
		this.subscribe(this.$router.events.pipe(filter(event => event instanceof NavigationEnd)), (ev: NavigationEnd) => {
			this.seo.title = this.seo.defaultTitle;
			this.seo.description = this.seo.defaultDescription;
			this.seo.keywords = this.seo.defaultKeywords;
			this.title.setTitle(this.seo.title);
			this.meta.updateTag({ name: 'description', content: this.seo.description});
			this.meta.updateTag({ name: 'keywords', content: this.seo.keywords});
			this.seo$.emit();
		});
	}
	public setDefaultTitle(title:string) {
		this.seo.defaultTitle = title;
	}
	public setTitle(title:string) {
		this.seo.title = title;
		this.title.setTitle(title);
		this.seo$.emit();
	}
	public setDefaultDescription(description:string) {
		this.seo.defaultDescription = description;
	}
	public setDescription(description:string) {
		this.seo.description = description;
		this.meta.updateTag({ name: 'description', content: this.seo.description});
		this.seo$.emit();
	}
	public setDefaultKeywords(keywords:string) {
		this.seo.defaultKeywords = keywords;
	}
	public setKeywords(keywords:string) {
		this.seo.keywords = keywords;
		this.meta.updateTag({ name: 'keywords', content: this.seo.keywords});
		this.seo$.emit();
	}
}
