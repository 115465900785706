/** * This file is the main entry point for the hybrid angularjs/angular.io app.
 *
 * If the application is being bundled, this is where the bundling process
 * starts.  If the application is being loaded by an es6 module loader, this
 * is the entry point.
 *
 * Point Webpack or SystemJS to this file.
 *
 * This module imports all the different parts of the application and registers them with angular.
 * - Submodules
 *   - States
 *   - Components
 *   - Directives
 *   - Services
 *   - Filters
 *   - Run and Config blocks
 *     - Transition Hooks
 *
 * Then this module bootstraps the application
 */

// Include third party dependencies
import './app/vendor'
import { config } from "./app/environments/environment";
import { enableProdMode } from '@angular/core';
// Prevent errors like: Error: Syntax error, unrecognized expression: [data-"Times New Roman"-reveal]
(window as any).Foundation.global.namespace = '';

////////////// BOOTSTRAP ///////////////
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { InfostarsAppModuleAngular } from './app/angularIoModule';

if(config.mode !== 'development')
	enableProdMode();
// Manually bootstrap the Angular app, we need to wrap this within a DOMContentLoaded to enable server side rendering
document.addEventListener('DOMContentLoaded', () => {
	platformBrowserDynamic().bootstrapModule(InfostarsAppModuleAngular).then(platformRef => {
		// Intialize the Angular Module
		// get() the UIRouter instance from DI to initialize the router
		// const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;
		// urlService.listen();
		// urlService.sync();
	}).catch(err => console.error(err));
});;
