import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { config } from "../environments/environment";
import { BaseComponent } from '../base.component';

export const LOGO_HEADER_HIDE_TIMEOUT_MS = 5000;
@Component({
	selector: 'logo-header-bar',
	templateUrl: 'logo-header-bar.html',
})
export class LogoHeaderBarCtrl extends BaseComponent implements OnInit, OnDestroy {
	public imgBase = config.baseUrl + '/';
	public fillerStyle = {
		background: 'transparent url("' + config.baseUrl + '/images/header_bg.png") repeat scroll center center',
		height: '102px'
	};
	public logoMaxHeight = '300px';
	ngOnInit(): void {
		setTimeout(() => {
			this.logoMaxHeight = '0px';
		}, LOGO_HEADER_HIDE_TIMEOUT_MS);
	}
	ngOnDestroy(): void {
		super.ngOnDestroy();
	}
}
