import { Inject, Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import numeral from "numeral";

// .filter('formatMoney', function($translate){
// 	return function filterFormatMoney(value, arg) {
// 		/*jshint bitwise: false*/
@Pipe({name: 'formatMoney'})
export class FormatMoneyPipe implements PipeTransform {
	constructor(@Inject(TranslateService) protected $translate: TranslateService) {}
	transform(value:any, ...args: any[]) {
		if(typeof value === 'undefined')
			return '';
		if((!args || !(args.length > 0) || !args[0].forceFixed) && value === (value | 0 /* -> int*/))
			return numeral(value).format(this.$translate.instant('formatMoney')); // Skip the fraction part
		return numeral(value).format(this.$translate.instant('formatMoneyExact'));
	}
}
@Pipe({name: 'formatPercent'})
export class FormatPercentPipe implements PipeTransform {
	transform(pct0To1:any) {
		if(!pct0To1)
			return Number(0).toFixed(0) + '%';
		return Number(100.0 * pct0To1).toFixed(0) + '%';
	};
}
@Pipe({name: 'formatSpeedKmh'})
export class FormatSpeedKmhPipe implements PipeTransform {
	transform(value:any) {
		if(!value)
			return Number(0).toFixed(2);
		return Number(value).toFixed(0);
	}
}
@Pipe({name: 'formatDistanceKm'})
export class FormatDistanceKmPipe implements PipeTransform {
	transform(value:any) {
		if(!value)
			return Number(0).toFixed(2);
		return Number(value).toFixed(2);
	}
}
@Pipe({name: 'formatFuelUsageLiter'})
export class FormatFuelUsageLiterPipe implements PipeTransform {
	transform(value:any) {
		if(!value)
			return Number(0).toFixed(2);
		return Number(value).toFixed(2);
	};
}
@Pipe({name: 'formatTemperatureC'})
export class FormatTemperatureCPipe implements PipeTransform {
	transform(temperature:any) {
		return Number(temperature).toFixed(1);
	}
}
@Pipe({name: 'formatLatLon'})
export class FormatLatLonPipe implements PipeTransform {
	transform(value:any) {
		return Number(value).toFixed(4);
	}
}
@Pipe({name: 'formatBooleanStatus'})
export class FormatBooleanStatusPipe implements PipeTransform {
	constructor(@Inject(TranslateService) protected $translate: TranslateService) {}
	transform(bool:any) {
		return bool === true ? this.$translate.instant('on') : this.$translate.instant('off');
	};
};
@Pipe({name: 'formatDataMB'})
export class FormatDataMBPipe implements PipeTransform {
	transform(dataMB:any) {
		return Number(dataMB).toFixed(1);
	}
}
@Pipe({name: 'formatNumberLocale'})
export class FormatNumberLocalePipe implements PipeTransform {
	transform(input:any) {
		if(input === null || isNaN(input)) return input;
		return input.toLocaleString();
	}
}
@Pipe({name: 'capitalise'})
export class CapitalisePipe implements PipeTransform {
	transform(input:string) {
		return (!!input) ? (input.charAt(0).toUpperCase() + input.substr(1).toLowerCase()) : '';
	};
}
@Pipe({name: 'firstLetter'})
export class FirstLetterPipe implements PipeTransform {
	transform(value:string) {
		if (value === null || value.length === 0) return '';
		return value.substring(0, 1);
	}
}
