import { Pipe, PipeTransform, Inject } from "@angular/core";
import { UserRights } from '../services/login.service';
import PEG, { Parser } from "pegjs";

const grammar = `
// Simple Boolean logic grammar
// ==========================
//
// Accepts expressions like "(RIGHT_A AND RIGHT_B) OR RIGHT_C" and computes whether the user has all rights
{
	function hasRight(right) {
		return options.p.hasRight(right);
	}
}
Expression
	= head:Factor tail:(_ ("AND" / "OR") _ Factor)* {
			return tail.reduce(function(result, element) {
				if (element[1] === "AND") { return result && element[3]; }
				if (element[1] === "OR") { return result || element[3]; }
			}, head);
		}

Factor
	= "(" _ expr:Expression _ ")" { return expr; }
	/ UserRight

UserRight "right"
	= _ [A-Z_]+ { return hasRight(text()); }

_ "whitespace"
	= [ \\t\\n\\r]*
`;

/** Pipe for checking permissions in templates
 * Inherit from BaseComponentWithPermission and use with
 * `*ngIf="userRights | hasPermission:'SHOW_VEHICLES OR '"
 */
@Pipe({
	name: "hasPermission",
	pure: true,
})
export class HasPermissionPipe implements PipeTransform {
	parser:Parser;

	constructor() {
		this.parser = PEG.generate(grammar);
	}

	transform(userRights:UserRights, expression: string) {
		if(!userRights || !expression)
			return false;
		return this.parser.parse(expression, {p: {
			hasRight: (right:string) => userRights[right] || false
		} });
	}
}
