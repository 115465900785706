import { Pipe, PipeTransform } from "@angular/core";

/** Calling [].constructor.isArray() in a template will lead to excessive functions calls, we need a pure pipe to avoid that */
@Pipe({
	name: "isArray",
	pure: true,
})
export class IsArrayPipe implements PipeTransform {
	transform(value: any) {
		if (value === null) return false;
		return Array.isArray(value);
	}
}
