import { Directive, forwardRef, Input, OnChanges, SimpleChanges, Inject } from "@angular/core";
import { NG_VALIDATORS, FormControl, ValidatorFn, AbstractControl, Validator, Validators, AsyncValidator, ValidationErrors, NG_ASYNC_VALIDATORS } from "@angular/forms";
import moment from "moment";

let PATTERN_DATE_INPUT = /(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))/;
let PATTERN_TIME_INPUT = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;
/** Validates a date input to have a date after midnight of the current day */
@Directive({
	selector: '[ppDateFuture][ngModel],[ppDateFuture][formControl]',
	providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => ppDateFutureValidator), multi: true }]
})
export class ppDateFutureValidator {
	@Input() ppDateFutureTime: string;
	private rejectObj = { ppDateFutureValidator: { valid: false } };
	validate(c: FormControl) {
		if(!c.value)
			return null; // consider empty models to be valid
		if(!(PATTERN_DATE_INPUT.test(c.value)))
			return this.rejectObj;
		let midnight = new Date();
		midnight.setHours(0,0,0,0);
		let valueMidnight = new Date(c.value);
		if(this.ppDateFutureTime && PATTERN_TIME_INPUT.test(this.ppDateFutureTime)) {
			valueMidnight = new Date(c.value + " " + this.ppDateFutureTime)
		}
		return valueMidnight.getTime() >= midnight.getTime() ? null : this.rejectObj;
	}
}

/** Validates a time input to have a date after midnight of the current day */
@Directive({
	selector: '[ppTimeFuture][ngModel],[ppTimeFuture][formControl]',
	providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => ppTimeFutureValidator), multi: true }]
})
export class ppTimeFutureValidator {
	@Input() ppTimeFutureDate: string;
	private rejectObj = { ppTimeFutureValidator: { valid: false } };
	validate(c: FormControl): ValidationErrors | null {
		if(!c.value)
			return null; // consider empty models to be valid
		if(!(PATTERN_TIME_INPUT.test(c.value)))
			return this.rejectObj;
		let midnight = new Date();
		midnight.setHours(0,0,0,0);
		let valueDate = moment().format("YYYY-MM-DD");
		if(this.ppTimeFutureDate && PATTERN_DATE_INPUT.test(this.ppTimeFutureDate)) {
			valueDate = this.ppTimeFutureDate;
		}
		let valueTime = new Date(valueDate + " " + c.value)
		return valueTime.getTime() >= midnight.getTime() ? null : this.rejectObj;
	}
}

/** Validates that one array element has a checked = true property */
@Directive({
	selector: '[ppAtLeastNChecked][ngModelGroup]',
	providers: [{provide: NG_VALIDATORS, useExisting: forwardRef(() => ppAtLeastNCheckedValidator), multi: true}]
})
export class ppAtLeastNCheckedValidator implements Validator {
	@Input() ppAtLeastNChecked: number;
	validate(control: AbstractControl): ValidationErrors | null {
		// the value of the control is an object that holds the value of each checkbox
		// the value's signature looks like this, `{ 'check-one': false, 'check-two': true }`
		const selected = Object.values(control.value).filter(Boolean).length;
		if (selected < this.ppAtLeastNChecked) {
			return { ppAtLeastNCheckedGroup: { ppAtLeastNChecked: this.ppAtLeastNChecked } };
		}
		return null;
	}
}

function equalToValidator(val: string, ignoreNull: boolean): ValidatorFn {
	return (control: AbstractControl): {[key: string]: any} => {
		/* this is where you want to put all your custom validation logic
			 REMINDER: returning null means valid, so check for invalid */
		if (!val && ignoreNull)
			return null;
		if (control.value !== val && val !== '') {
			return {ppEqualTo: { valid: false, value: control.value }};
		}
		return null;
	};
}
/** Validates the model of an input to equal the given expression
 * If you set ppEqualToIgnoreNull to true, the validation will be ignored
 * if the ppEqualTo value is null.
 */
@Directive({
	selector: '[ppEqualTo][ngModel],[ppEqualTo][formControl]',
	providers: [{provide: NG_VALIDATORS, useExisting: forwardRef(() => ppEqualToValidator), multi: true}]
})
export class ppEqualToValidator implements Validator, OnChanges {
	@Input() ppEqualTo: any;
	@Input() ppEqualToIgnoreNull: boolean = false;
	private valFn = Validators.nullValidator;

	ngOnChanges(changes: SimpleChanges): void {
		const change = changes['ppEqualTo'];
		if (change) {
			this.valFn = equalToValidator(change.currentValue, this.ppEqualToIgnoreNull);
		} else {
			this.valFn = Validators.nullValidator;
		}
	}

	validate(control: AbstractControl): {[key: string]: any} {
		return this.valFn(control);
	}
}

/** Validate that all selected aux stati are only selected once */
@Directive({
	selector: '[veAuxStatiUnique]',
	providers: [{provide: NG_VALIDATORS, useExisting: veAuxStatiUniqueValidator, multi: true}]
})
export class veAuxStatiUniqueValidator implements Validator {
	@Input() veAuxStatiUnique: any; // Bind to the vehicle object (needs to have vehicleAuxNStatus properties with N:=[1,10])
	@Input() veAuxStatiUniqueNum: number; // Bind to the number of the aux status [1,10]

	validate(control: AbstractControl): ValidationErrors | null {
		if(!control.value)
			return null;
		for(let i = 1; i <= 10; i++) {
			if(i === this.veAuxStatiUniqueNum)
				continue;
			if(control.value === this.veAuxStatiUnique['vehicleAux' + i + 'Status']) {
				return { veAuxStatiUnique: true }; // Another aux status exists with the same status number
			}
		}
		return null;
	}
}
