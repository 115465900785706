import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "shortened",
	pure: true,
})
export class ShortenedPipe implements PipeTransform {
	transform(value: string) {
		if (value === null) return '';
		if (value.length <= 7) return value;
		return value.substring(0, 7) + '...';
	}
}
