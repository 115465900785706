import { Component, Inject, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { config, rendersInBrowser } from '../environments/environment';
import { BaseComponent } from '../base.component';
import { LoginService } from '../services/login.service';
import { InfostarsToolsService } from '../services/InfostarsTools.service';
@Component({
	templateUrl: './main.html',
	host: { class: 'grid-block' } // foundation apps grid-blocks need to be directly nested without other elements in between in the tree
})
export class MainCtrl extends BaseComponent implements OnInit {
	public mailAddress = config.infostarsSupportEmail;
	public phone = config.infostarsSupportPhone;
	public loggedIn = false;
	constructor(
		@Inject(LoginService) private Login: LoginService,
		@Inject(InfostarsToolsService) protected InfostarsTools: InfostarsToolsService,
	) { super(InfostarsTools); }
	ngOnInit(): void {
		this.subscribe(this.Login.loggedIn$, () => this.loggedIn = this.Login.loggedIn);
	}
}
