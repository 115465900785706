import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MessagesService, MessageInfo } from "../services/messages.service";
import { BaseComponent } from '../base.component';
import { InfostarsToolsService } from "../services/InfostarsTools.service";

@Component({
	selector: 'messages',
	templateUrl: './messages.html',
})
export class MessagesComponent extends BaseComponent implements OnInit, OnDestroy {
	public messages:Array<any> = [];

	constructor(
		@Inject(InfostarsToolsService) protected InfostarsTools: InfostarsToolsService,
		@Inject(MessagesService) private Messages: MessagesService,
	) { super(InfostarsTools); }
	ngOnInit() {
		this.subscribe(this.Messages.messages$, (msgInfo:any) => {
			this.doMsg(msgInfo.newMessage);
			this.messages = msgInfo.messages;
		});
	}
	ngOnDestroy(): void {
		super.ngOnDestroy();
	}
	private reApplyFoundation() {
		($('.messages-container') as any).foundation({bindings: 'events'});
	}
	private doMsg(msgObj:any) {
		let timeout = msgObj.timeout ? msgObj.timeout : (msgObj.level === 'alert' ? 10000 : (msgObj.level === 'warning' ? 4000 : 2500));
		setTimeout(() => {
			this.reApplyFoundation();
			var $msgEl = $('.messages-container #' + msgObj.id);
			$msgEl.one('close.fndtn.alert', () => {
				var idx = this.messages.indexOf(msgObj);
				if(idx >= 0)
					this.messages.splice(idx, 1);
			});
			setTimeout(() => {
				if($msgEl.length === 0) { // Might already be gone by manually closing it
					var idx = this.messages.indexOf(msgObj);
					if(idx >= 0)
						this.messages.splice(idx, 1);
					return;
				}else {
					$('a.close', $msgEl).trigger('click.fndtn.alert');
				}
			}, timeout);
		}, 0);
	}
}
