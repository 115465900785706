<div class="row">
	<div class="small-1 columns time-interval-lbl" style="text-align: center" *ngIf="prefix?.length>0">
		<label>{{prefix}}</label>
	</div>
	<div class="small-1 columns time-interval-lbl">
		<label>{{'from'|translate}}</label>
	</div>
	<div class="small-3 columns time-column">
		<input type="time" [(ngModel)]="time.from" (change)="updateDateFrom($event)" [name]="name + 'From'" placeholder="14:00"/>
	</div>
	<div class="small-1 columns time-interval-lbl">
		<label>{{'to'|translate}}</label>
	</div>
	<div class="small-3 columns time-column">
		<input type="time" [(ngModel)]="time.to" (change)="updateDateTo($event)" [name]="name + 'To'" placeholder="18:00"/>
	</div>
	<div class="small-2 columns" style="float:left">
		<input type="checkbox" [(ngModel)]="time.invert" (change)="updateInvert($event)" [name]="name + 'Invert'" class="checkbox-fix"/>
	</div>
</div>
