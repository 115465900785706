import { Pipe, PipeTransform } from "@angular/core";
import _ from "lodash";

/** Puts items with a property item[key] evaluating to false at the end of the array */
@Pipe({
	name: "emptyToEnd",
	pure: false,
})
export class EmptyToEndPipe implements PipeTransform {
	transform(array: any, key:string): any[] {
		if (!_.isArray(array)) return[];
		if (!key) return array;
		if (key.startsWith('-') && key.length > 0) key = key.substring(1);
		var present = array.filter((item) => {
			return item[key];
		});
		var empty = array.filter((item) => {
			return !item[key];
		});
		return present.concat(empty);
	}
}
