import { Injectable } from '@angular/core';
import { CanvasToPngComponent } from '../components/canvas-to-png.component';

@Injectable()
export class CanvasToPngService {
	protected cvToPng: CanvasToPngComponent;

	constructor() { }

	public setCanvasToPngComponent(cvToPng: CanvasToPngComponent) {
		this.cvToPng = cvToPng;
	}

	/** Converts from RGB (0-255) to HSL (°, %, %) */
	rgbToHsl(r:number, g:number, b:number) {
		r /= 255;
		g /= 255;
		b /= 255;
		const l = Math.max(r, g, b);
		const s = l - Math.min(r, g, b);
		const h = s
			? l === r
				? (g - b) / s
				: l === g
				? 2 + (b - r) / s
				: 4 + (r - g) / s
			: 0;
		return [
			60 * h < 0 ? 60 * h + 360 : 60 * h,
			100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
			(100 * (2 * l - s)) / 2,
		];
	}

	hexToRgba(hex:string):number[] {
		let alpha = false;
		hex = hex.slice(hex.startsWith('#') ? 1 : 0);
		if (hex.length === 3)
			hex = [...hex].map(x => x + x).join('');
		else if (hex.length === 8)
			alpha = true;
		let h = parseInt(hex, 16);
		return [
				(h >>> (alpha ? 24 : 16)),
				((h & (alpha ? 0x00ff0000 : 0x00ff00)) >>> (alpha ? 16 : 8)),
				((h & (alpha ? 0x0000ff00 : 0x0000ff)) >>> (alpha ? 8 : 0)),
				(alpha ? h & 0x000000ff : 255)
		];
	};

	/** Converts a vehicle picture string to a PNG URL, if it's a number based vehicle picture (i.e. start with '%PICNUM') */
	public vehiclePicToPngUrl(picture: string): {width: number, height: number, img: string} {
		// ATTENTION: Keep this code in sync with the backend (currently in LogbookUtils#transformPictureToUrlStatic)
		const STRING_PICTURE_PREFIX = "%PICNUM:";
		const NUMBER_ICON_BG_COLOR = "DB4E4E";
		const LOG_BOOK_ICON_FONT_COLOR = "FFFFFF";
		const LOG_BOOK_ICON_FONT_COLOR_DARK = "222222";
		if(!picture || !picture.startsWith(STRING_PICTURE_PREFIX))
			return null;
		let color = NUMBER_ICON_BG_COLOR;
		let fontColor = LOG_BOOK_ICON_FONT_COLOR;
		let colorIdx = picture.indexOf("#", STRING_PICTURE_PREFIX.length);
		if(colorIdx > -1) {
			color = picture.substring(colorIdx + 1);
			let rgb = this.hexToRgba(color);
			let hslColor = this.rgbToHsl(rgb[0], rgb[1], rgb[2]);
			if(hslColor[2] >= 50.0) // 50% Luminance
				fontColor = LOG_BOOK_ICON_FONT_COLOR_DARK;
		}else {
			colorIdx = picture.length;
		}
		// // The picture can be alphanumeric, not just numbers
		let picStr = picture.substring(STRING_PICTURE_PREFIX.length, colorIdx);
		let borderwidth = 0;
		let fontsize = 14;
		if(picStr.length >= 3) {
			borderwidth = 1;
			fontsize = 8;
		}
		if(picStr.length < 3) {
			borderwidth = 4;
			fontsize = 11;
		}
		if(picStr.length < 2) {
			borderwidth = 5;
			fontsize = 13;
		}
		return this.fillTextCircleToPng(picStr, `#${fontColor}`, fontsize, `#${color}`, borderwidth, `#${color}`);
	}

	/** Draw a circle with text and return as png
	 * @param radiusAddPixel the number of pixels between the text and the circle border (default 3)
	 */
	public fillTextCircleToPng(text: string, fontColour: string, fontSize: number, bgColour: string, borderWidth: number, borderColour: string, radiusAddPixel?: number): {width: number, height: number, img: string} {
		return this.cvToPng.fillTextCircleToPng(text, fontColour, fontSize, bgColour, borderWidth, borderColour, radiusAddPixel);
	}
}
