import { Inject, Directive, AfterViewInit } from '@angular/core';
import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { config } from './environments/environment'
import { InfostarsToolsService } from './services/InfostarsTools.service';
import { Destroyable } from './directives/destroyable.directive';
import { LoginService, UserRights } from './services/login.service';

/** distinctUntilChanged doesn't work with object references (i.e. when the .next() method is always called on the same object)
 * not even when piping through lodash isEqual. This helper does work. Use with rxjs .pipe(distinctUntilChangedObject()).
 */
export function distinctUntilChangedObject() {
	return function<T>(source: Observable<T>): Observable<T> {
		return new Observable(subscriber => {
			let prevStr: any;
			let first = true;
			source.subscribe({
				next(value) {
					const valueStr = JSON.stringify(value);
					if (first) {
						prevStr = valueStr;
						subscriber.next(value);
						first = false;
					} else if (prevStr !== valueStr) {
						prevStr = valueStr;
						subscriber.next(value);
					}
				},
				error(error) {
					subscriber.error(error);
				},
				complete() {
					subscriber.complete();
				}
			});
		});
	};
}

/** Similar to distinctUntilChangedObject() but doesn't fire for the first value, strictly only when changed */
export function untilChangedObject() {
	return function<T>(source: Observable<T>): Observable<T> {
		return new Observable(subscriber => {
			let prevStr: any;
			let first = true;
			source.subscribe({
				next(value) {
					const valueStr = JSON.stringify(value);
					if (first) {
						prevStr = valueStr;
						first = false;
					} else if (prevStr !== valueStr) {
						prevStr = valueStr;
						subscriber.next(value);
					}
				},
				error(error) {
					subscriber.error(error);
				},
				complete() {
					subscriber.complete();
				}
			});
		});
	};
}

/** Similar to distinctUntilChangedObject but uses a custom compare function which can be used to only compare a part of the value (e.g. one property) */
export function distinctUntilChangedObjectCmp<T>(compare?: (x: T, y: T) => boolean): MonoTypeOperatorFunction<T> {
	return function<T>(source: Observable<T>): Observable<T> {
		return new Observable(subscriber => {
			let prevObj: any;
			let first = true;
			source.subscribe({
				next(value) {
					const valueObj = JSON.parse(JSON.stringify(value));
					if (first) {
						prevObj = valueObj;
						subscriber.next(value);
						first = false;
					} else if (!compare(prevObj, valueObj)) {
						prevObj = valueObj;
						subscriber.next(value);
					}
				},
				error(error) {
					subscriber.error(error);
				},
				complete() {
					subscriber.complete();
				}
			});
		});
	};
}
/** You can inherit from this class to get language switching and subscription cleanup automatically
 * ATTENTION: You need to call super.ngOnDestroy() when using the OnDestroy interfaces
 */
@Directive()
export abstract class BaseComponent extends Destroyable implements AfterViewInit {
	public config:any;
	public activeLang = this.InfostarsTools.activeLang;
	private resolveAfterViewInit: (value?: void | PromiseLike<void>) => void;
	protected afterViewInitPromise = new Promise<void>(resolve => this.resolveAfterViewInit = resolve);
	constructor(
		protected InfostarsTools: InfostarsToolsService
	) {
		super();
		this.config = config;
		this.subscribe(this.InfostarsTools.activeLang$, lang => this.activeLang = lang);
	}
	ngAfterViewInit(): void {
		this.resolveAfterViewInit();
	}
}

@Directive()
export abstract class BaseComponentWithPermission extends BaseComponent {
	private loginPromise:Promise<any> = null;
	public loggedIn:Observable<boolean>;
	public userRights:UserRights = {};
	public trackType:string;

	constructor(
		protected InfostarsTools: InfostarsToolsService,
		protected Login: LoginService,
	) {
		super(InfostarsTools);
		this.loginPromise = Login.getLoginSuccessPromise();
		this.loginPromise.then(() => {
			this.userRights = this.Login.getUserRights();
			this.trackType = this.Login.getTrackType();
		}), () => {
			console.error(`Login failed, can't check for permissions`);
		};
		this.subscribe(this.Login.loggedIn$, () => {
			this.userRights = this.Login.getUserRights();
			this.trackType = this.Login.getTrackType();
		});
		// ATTENTION: The observable constructor is only invoked if someone actually subscribes to it
		this.loggedIn = new Observable<boolean>(observer => {
			this.loginPromise.then(() => {
				observer.next(true);
				observer.complete();
			}, () => {
				observer.next(false);
				observer.complete();
			});
		})
	}
}
