import { Component, ViewChild, Inject, Output, Input, EventEmitter, OnInit, OnDestroy, ElementRef, forwardRef } from '@angular/core';
import { config } from '../environments/environment';
import { BaseComponent } from '../base.component';;
import { InfostarsToolsService } from '../services/InfostarsTools.service';
import moment, { Moment } from 'moment';
// We have to load Modernizr using the imports-loader, so it will be available on window.Modernizr
// import Modernizr from 'foundation-sites/js/vendor/modernizr.js';
import Pikaday from 'pikaday';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/** A component for editing the date part of a js Date object with NgForm compatibility */
@Component({
	selector: 'date',
	templateUrl: './date.html',
	providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateComponent), multi:true }] // Register our ControlValueAccessor
})
export class DateComponent extends BaseComponent implements OnInit, OnDestroy, ControlValueAccessor {
	@Input() title:string;
	@Input() required:boolean = false;
	@Input() useMoment:boolean = true;
	@Input() id:string;
	@ViewChild('htmlEl') htmlEl: ElementRef;

	public data = { datePart: null as any }
	public disabled:boolean = false;
	public onTouchedFn = (_: any) => {};

	private date:Date | Moment; // Equivalent to what users bind ngModel to
	private onChangeFn = (_: any) => {};
	private Modernizr = (window as any).Modernizr;
	private inhibitDateChange = false;

	constructor(
		@Inject(InfostarsToolsService) protected InfostarsTools: InfostarsToolsService,
	) { super(InfostarsTools); }

	//// ControlValueAccessor methods ////
	writeValue(date: any): void { // External model value should be applied to DOM
		this.updateFromDate(date, this.date);
		this.date = date;
	}
	registerOnChange(fn: any): void {
		this.onChangeFn = fn; // Function to be called when DOM color value changed
	}
	registerOnTouched(fn: any): void {
		this.onTouchedFn = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
	//////////////////////////////////////

	ngOnInit() {
		if(!this.Modernizr.inputtypes.date) // Install the pikaday UI date picker, if no native widget is available
			new Pikaday({ field: this.htmlEl.nativeElement });
	}
	ngOnDestroy(): void {
	}
	// The UI model has changed, update the external date model
	public onUpdateDate(newVal:any) {
		try {
			this.inhibitDateChange = true;
			if(!newVal) {
				this.date = null;
				this.onChangeFn(null);
				return;
			}
			let newDate = moment(this.data.datePart + 'T00:00:00').locale(config.BACKEND_DATE_LANG);
			if(this.useMoment) {
				if(!this.date || !moment.isMoment(this.date) || newDate.valueOf() !== this.date.valueOf())
					this.date = newDate;
			}else {
				if(!this.date || !(this.date instanceof Date) || newDate.valueOf() !== this.date.getTime())
					this.date = newDate.toDate();
			}
			this.onChangeFn(this.date);
		} finally {
			this.inhibitDateChange = false;
		}
	}
	// The external date model has changed, update the internal model and UI
	private updateFromDate(newVal:any, oldVal:any) {
		if(this.inhibitDateChange)
			return;
		if(newVal === oldVal && this.data.datePart) // Probably being called from a handler of this.dateChange
			return;
		if(!newVal) {
			this.data.datePart = null;
			return;
		}
		let externalDate;
		if(this.useMoment)
			externalDate = moment.isMoment(this.date) ? this.date.toDate() : (this.date ? moment(this.date).toDate() : moment().toDate());
		else
			externalDate = this.date instanceof Date ? this.date : (this.date ? new Date(this.date as any) : new Date());
		this.data.datePart = moment(externalDate).format('YYYY-MM-DD');
	}
}
