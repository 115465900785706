<section class="grid-block claim main-section view-main">
	<div class="grid-content">
		<div class="row">
			<div class="collapse small-8 small-offset-2 medium-6 medium-offset-3 column" style="margin-top: 25px">
				<div class="text-center">
					<img src="assets/images/logo_infostars-293x92_5.png" alt="Infostars logo"><br/>
					<h4>{{'mainHeading1' | translate}}<br/>{{'mainHeading2' | translate}}</h4>
					<div [hidden]="loggedIn">
						<ul class="button-group">
							<li><a class="button radius" routerLink="/{{ activeLang }}/login">{{'login'|translate}}</a></li>
							<li><a class="button radius" routerLink="/{{ activeLang }}/setup">{{'setupTitle'|translate}}</a></li>
						</ul>
					</div>
					<p [innerHTML]="'mainWelcomeNotice' | translate:{mail: mailAddress, phoneNumber: phone}"></p>
					<p>
						<language-chooser></language-chooser>
					</p>
				</div>
			</div>
		</div>
	</div>
</section>
