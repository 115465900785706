import { Routes } from '@angular/router';
import { LanguageRouteComponent } from './language-route.component';
import { MainCtrl } from './main/main.controller';

// const mainPageChildren = [
	// { path: 'video', loadChildren: () => import('./appVideo.module').then(m => m.AppVideoModule) },
	// // We need to have a component assigned, strangely this is only a problem
	// { path: 'desktopApp', component: EmptyCtrl, children: [{ path: 'professionalSignup', component: EmptyCtrl }] },
	// { path: 'imprint'/*, component: EmptyCtrl*/}
// ];

export const angularRouterRoutes: Routes = [
{ path: '', component: LanguageRouteComponent }, // Will redirect to the right language page
{ path: ':lang', component: LanguageRouteComponent,
	children: [
		// ATTENTION: If you add pages here and want them to be served by Angular Universal, modify the regex in server.ts
		//            You also need to make sure your components are in InfostarsAppModuleAngular and not in AppPublicModule (lazy loaded module)


		{ path: '', component: MainCtrl,
			data: { isRootState: true, hideNav: true },  // Prevents a redirect to app.root for alternative landing pages if no location is set yet
			// children: mainPageChildren
		},
		{ path: '', loadChildren: () => import('./appPublic.module').then(m => m.AppPublicModule) },

		{ path: '**', redirectTo: '' },

		// Examples
		// { path: '', redirectTo: '/heroes', pathMatch: 'full' },
		// { path: '**', component: PageNotFoundComponent }
	]
}];
