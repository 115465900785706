import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '../base.component';

import moment, { Moment } from 'moment';
import { InfostarsToolsService } from '../services/InfostarsTools.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export class TimeIntervalDetails {
	fromDateTime: Moment | Date;
	toDateTime: Moment | Date;
	invert: boolean;
}

/** Input component that shows a from - to timestamp input and a checkbox to invert the time interval */
@Component({
	selector: 'time-interval',
	templateUrl: './time-interval.html',
	providers: [{provide: NG_VALUE_ACCESSOR, useExisting: TimeIntervalComponent, multi:true }] // Register our ControlValueAccessor
})
export class TimeIntervalComponent extends BaseComponent implements ControlValueAccessor {
	@Input() prefix:string;
	@Input() name:string; // The name attribute of the input field
	time = { from: null as string, to: null as string, invert: false }; // from/to will be in 'HH:mm' format

	public disabled:boolean = false;
	public onTouchedFn = (_: any) => {};
	private onChangeFn = (_: any) => {};
	private details: TimeIntervalDetails;

	constructor(
		protected InfostarsTools: InfostarsToolsService,
	) { super(InfostarsTools); }

	//// ControlValueAccessor methods ////
	writeValue(details: TimeIntervalDetails): void { // External model value should be applied to DOM
		this.updateDetails(details, this.details);
		this.details = details;
	}
	registerOnChange(fn: any): void {
		this.onChangeFn = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouchedFn = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
	//////////////////////////////////////

	// The UI model has changed, update the external date model
	public updateDateFrom(ev:Event) {
		if (this.time.from){
			this.details.fromDateTime = moment(moment().format('YYYY-MM-DD') + 'T' + this.time.from + ':00');
		} else if(this.time.from === null){
			this.details.fromDateTime = null;
		}
		this.onChangeFn(this.details);
	}
	public updateDateTo(ev:Event) {
		if (this.time.to){
			this.details.toDateTime = moment(moment().format('YYYY-MM-DD') + 'T' + this.time.to + ':00');
		} else if (this.time.to === null) {
			this.details.toDateTime = null;
		}
		this.onChangeFn(this.details);
	}
	public updateInvert(ev:Event) {
		if (typeof(this.time.invert) !== 'undefined' && typeof(this.time.invert) !== 'undefined')
			this.details.invert = this.time.invert;
		this.onChangeFn(this.details);
	}

	// The external date model has changed, update the internal model and UI
	private updateDetails(newVal:any, oldVal:any) {
		if (newVal === oldVal)
			return;
		if (this.isNotPresent(newVal) || this.isNotPresent(newVal.fromDateTime) || this.isNotPresent(newVal.toDateTime)) {
			this.time.from = null;
			this.time.to = null;
			this.time.invert = false;
		} else {
			this.time.from = moment.isMoment(newVal.fromDateTime) ? this.dateToStr(newVal.fromDateTime) : this.dateToStr(moment(newVal.fromDateTime));
			this.time.to = moment.isMoment(newVal.toDateTime) ? this.dateToStr(newVal.toDateTime) : this.dateToStr(moment(newVal.toDateTime));
			this.time.invert = newVal.invert;
		}
	}
	private isNotPresent(value:any) {
		return (typeof (value) === 'undefined' || value === null);
	}
	private dateToStr(date:Moment) {
		return date.format('HH:mm');
	}
}
