import { Inject, Injectable } from '@angular/core';
import { config } from '../environments/environment';
import { UserTranslationsService } from './usertranslations.service';
import { Restangular } from 'ngx-restangular';
import { BackendRestService } from './Restangular.service';
import { LoginService } from './login.service';
import { InfostarsToolsService } from './InfostarsTools.service';
import { forkJoin } from 'rxjs';
import { BaseComponent } from '../base.component';
;

/**
 * @ngdoc function
 * @name infostars.controller:AlarmStar
 * @description
 * # AlarmStart
 * Service of the infostarsApp
 */
@Injectable()
export class AlarmStarService extends BaseComponent {
	private alarmstarPromise:Promise<boolean> = null;
	private alarmTypes:any = {};
	private alarmZones:any[] = [];
	private alarmCentrals:any[] = [];

	constructor(
		protected UserTranslations: UserTranslationsService,
		@Inject(BackendRestService) protected BackendRest: Restangular,
		protected Login: LoginService,
		protected InfostarsTools: InfostarsToolsService,
	) { super(InfostarsTools); }

	private getData() {
		let deferred:any = { promise: null as Promise<any>, resolve: null as (val: unknown) => void, reject: null as (reason: unknown) => void};
		deferred.promise = new Promise((resolve, reject) => {
			deferred.resolve = resolve;
			deferred.reject = reject;
		});
		this.Login.getLoginPromise().then(() =>  {
			let params = {locale: this.activeLang, accountId: this.Login.getAccountId()};
			return forkJoin([this.BackendRest.one('usertranslation/datainterfacetype/used').get(params),
			              this.BackendRest.one('zone').get({accountId: this.Login.getAccountId()}),
			              this.BackendRest.one('alarmstar/routine/alarmZentralen').get()]).toPromise();
		}, (err) => {
			deferred.reject(err);
		}).then((results:any) =>  {
			this.alarmTypes = results[0];
			this.alarmZones = results[1];
			this.alarmCentrals = results[2];
			this.alarmTypes.abbrToTextMap = {};
			for(let key of this.alarmTypes) {
				const value = this.alarmTypes[key];
				if (value && value.abbreviation)
					this.alarmTypes.abbrToTextMap[value.abbreviation] = value.localeName;
			}
			this.alarmTypes.abbrToTextMap[-1] = 'CatFlap';
			deferred.resolve(true);
		}, (err) => {
			deferred.reject(err);
		});
		this.alarmstarPromise = deferred.promise;
		return this.alarmstarPromise;
	}
	public getPromise() {
		if(this.alarmstarPromise)
			return this.alarmstarPromise;
		return this.getData();
	}
	public getAlarmTypes() {
		return this.alarmTypes;
	}
	public getAlarmZones() {
		return this.alarmZones;
	}
	public getAlarmCentrals() {
		return this.alarmCentrals;
	}
	public getPictureUrl() {
		return config.baseUrl + '/images/';
	}
	public getAbbreviationTexts():{[id: string]:string} {
		return this.alarmTypes.abbrToTextMap;
	}
}
