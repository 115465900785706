<canvas-to-png #canvasToPng></canvas-to-png>

<filter #filterComp>
	<div class="grid-frame vertical">
		<logo-header-bar></logo-header-bar>

		<div class="grid-block shrink wrap navbar">
			<div class="grid-content collapse">
				<navbar [filterComp]="filterComp"></navbar>
			</div>
		</div>
		<messages></messages>
		<div class="grid-block">
			<router-outlet></router-outlet>
		</div>
	</div>
</filter>

<div class="hotkeys-hint" [ngClass]="{dimmed: dimHotkeysHint}">
	<div class="content">Press <kbd>Shift</kbd>&nbsp;<kbd>?</kbd> for keyboard shortcuts</div>
</div>

<div id="hotkeysModal" class="reveal-modal" data-reveal aria-labelledby="hotkeysModalTitle" aria-hidden="true" role="dialog">
	<h2 id="hotkeysModalTitle">{{'hotkeys'|translate}}</h2>
	<hotkey-list></hotkey-list>
	<div class="action-buttons">
		<a class="button" (click)="onCloseHotkeys()">{{'close'|translate}}</a>
	</div>
</div>

<div id="agbModal" class="reveal-modal" data-reveal aria-labelledby="agbModalTitle" aria-hidden="true" role="dialog">
	<h2 id="agbModalTitle">{{'termsAndConditions'|translate}}</h2>
	<div [innerHTML]="termsAndConditionsHtml"></div>
	<div class="action-buttons">
		<a class="button" (click)="onAcceptTerms()">{{'accept'|translate}}</a>
		<a class="button" (click)="onDeclineTerms()">{{'decline'|translate}}</a>
	</div>
</div>

<div id="ytPrivacyPolicyModal" class="reveal-modal" data-reveal aria-labelledby="ytPPModalTitle" aria-hidden="true" role="dialog">
	<h2 id="ytPPModalTitle">{{'ytPrivacyPolicy'|translate}}</h2>
	<div [innerHtml]="ytPrivacyPolicyHtml"></div>
	<div class="action-buttons">
		<a class="button" (click)="onClosePP()">{{'close'|translate}}</a>
	</div>
</div>
