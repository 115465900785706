<div class="row date-time" #htmlEl>
	<div class="small-8 columns date-column">
		<label *ngIf="label">{{label}}</label>
		<input type="date" [(ngModel)]="data.datePart" (ngModelChange)="onUpdateDate($event)" (blur)="onTouchedFn($event)" [name]="name + 'Date'" placeholder="2015-11-23" [required]="required" [attr.disabled]="disabled || null"/>
	</div>
	<div class="small-4 columns time-column" *ngIf="!selTimes">
		<label *ngIf="label">&nbsp;</label>
		<input type="time" [(ngModel)]="data.timePart" (ngModelChange)="onUpdateDate($event)" (blur)="onTouchedFn($event)" [name]="name + 'Time'" placeholder="21:30:20" [required]="required" [attr.disabled]="disabled || null" step="60"/>
	</div>
	<div class="small-4 columns time-column" *ngIf="selTimes">
		<label>&nbsp;</label>
		<select [(ngModel)]="data.timePart" [name]="name + 'Times'">
			<option *ngFor="let c of selTimes" [ngValue]="c.value">{{ c.title }}</option>
		</select>
	</div>
</div>
