import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'tourFieldToCode'})
export class TourFieldToCodePipe implements PipeTransform {
	transform(field:String) {
		field = field.replace('field.', '');
		switch (field) {
			case 'vehicle': return 'vehicle';
			case 'start.time': return 'from';
			case 'start.location': return 'startLocation';
			case 'start.mileage':	return 'startKm';
			case 'stop.time':	return 'to';
			case 'stop.location':	return 'stopLocation';
			case 'stop.mileage': return 'stopKm';
			case 'driver':	return 'driver';
			case 'tourStatus':	return 'tourStatus';
			case 'tourNumber': return 'tourNumber';
			case 'via':	return 'via';
			case 'purpose':	return 'purpose';
			case 'deleted':	return 'deleted';
			case 'nextTourAttached':	return 'nextTourAttached';
			case 'fictivePrivateKm':	return 'fictivePrivateKm';
			case 'homeWorkKm':	return 'homeWorkKm';
			default: return '';
		}
	}
}
