import { Component, ViewChild, Inject, Output, Input, EventEmitter, OnInit, ElementRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { config } from '../environments/environment';
import { BaseComponent } from '../base.component';;
import { InfostarsToolsService } from '../services/InfostarsTools.service';
import moment from 'moment';
// We have to load Modernizr using the imports-loader, so it will be available on window.Modernizr
// import Modernizr from 'foundation-sites/js/vendor/modernizr.js';
import Pikaday from 'pikaday';

@Component({
	selector: 'date-time',
	templateUrl: './date-time.html',
	providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateTimeComponent), multi:true }] // Register our ControlValueAccessor
})
export class DateTimeComponent extends BaseComponent implements OnInit, ControlValueAccessor {
	@Input() label:string;
	@Input() name:string; // The name attribute of the input field
	@Input() required:boolean = false;
	@Input() useMoment:boolean = true;
	@ViewChild('htmlEl') htmlEl: ElementRef;

	public data = { datePart: null as any, timePart: null as any };
	public selTimes:any[];
	public disabled:boolean = false;
	public onTouchedFn = (_: any) => {};

	private date:any;
	private Modernizr = (window as any).Modernizr;
	private inhibitDateChange = false;
	private onChangeFn = (_: any) => {};

	constructor(
		@Inject(InfostarsToolsService) protected InfostarsTools: InfostarsToolsService,
	) { super(InfostarsTools); }

	ngOnInit() {
		if(!this.Modernizr.inputtypes.date) // Install the pikaday UI date picker, if no native widget is available
			new Pikaday({ field: jQuery('input[type="date"]', this.htmlEl.nativeElement)[0] });
		if(!this.Modernizr.inputtypes.time) { // Install a simple dropdown, if no native widget is available
			this.selTimes = [];
			let curr = moment().year(1970).dayOfYear(1).startOf('day').millisecond(0);
			let end = moment().year(1970).dayOfYear(1).endOf('day').millisecond(0);
			do {
				this.selTimes.push({value: moment(curr), title: curr.format('HH:mm')});
				curr.add(15, 'minutes');
			}while(!curr.isAfter(end));
			this.selTimes.push({value: end, title: end.format('HH:mm')});
		}
	}

	//// ControlValueAccessor methods ////
	writeValue(date: any): void { // External model value should be applied to DOM
		this.updateFromDate(date, this.date);
		this.date = date;
	}
	registerOnChange(fn: any): void {
		this.onChangeFn = fn; // Function to be called when DOM value changed
	}
	registerOnTouched(fn: any): void {
		this.onTouchedFn = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
	//////////////////////////////////////

	// The UI model has changed, update the external date model
	public onUpdateDate(newVal:any) {
		try {
			this.inhibitDateChange = true;
			if(!newVal) {
				this.date = null;
				this.onChangeFn(null);
				return;
			}
			let newDate = this.InfostarsTools.combineDateAndTime(moment(this.data.datePart + 'T00:00:00'), moment(this.data.datePart + 'T' + this.data.timePart + ':00')).locale(config.BACKEND_DATE_LANG);
			if(this.useMoment) {
				if(!this.date || !moment.isMoment(this.date) || newDate.valueOf() !== this.date.valueOf())
					this.date = newDate;
			}else {
				if(!this.date || !(this.date instanceof Date) || newDate.valueOf() !== this.date.getTime())
					this.date = newDate.toDate();
			}
			this.onChangeFn(this.date);
		} finally {
			this.inhibitDateChange = false;
		}
	}
	// The external date model has changed, update the internal model and UI
	private updateFromDate(newVal:any, oldVal:any) {
		if(this.inhibitDateChange)
			return;
		if(newVal === oldVal && this.data.datePart && this.data.timePart) // Probably being called from a handler of this.dateChange
			return;
		if(newVal === null) {
			this.data = {datePart: null, timePart: null}
			return;
		}
		let externalDate;
		if(this.useMoment)
			externalDate = moment.isMoment(newVal) ? newVal.toDate() : (newVal ? moment(newVal).toDate() : moment().toDate());
		else
			externalDate = newVal instanceof Date ? newVal : (newVal ? new Date(newVal) : new Date());
		this.data.datePart = moment(externalDate).format('YYYY-MM-DD');
		if(this.selTimes) {
			let mExternalDate = moment(externalDate).year(1970).dayOfYear(1).millisecond(0);
			let found:any = false;
			this.selTimes.some((time) =>  {
				if(mExternalDate.isSame(time.value) || (mExternalDate.isAfter(time.value) && mExternalDate.isBefore(moment(time.value).add(15, 'minutes')))) {
					found = time.value;
					return true; // break
				}
				return false;
			});
			let last = this.selTimes[this.selTimes.length - 1];
			if(mExternalDate.isSame(last.value))
				found = last.value;
			this.data.timePart = moment(found).format('HH:mm');
		}else {
			this.data.timePart = moment(externalDate).millisecond(0).format('HH:mm');
		}
		this.data = {...this.data}; // Trigger change detection in <input type="date"> tags.
	}
}
