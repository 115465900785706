import { Component, ViewChild, Inject, Output, Input, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { config } from '../environments/environment';

@Component({
	selector: 'language-chooser',
	templateUrl: './language-chooser.html',
})
export class LanguageChooserCtrl {
	public supportedLangMap = config.supportedLangMap; // For the language chooser

	constructor(
	) {}
}
