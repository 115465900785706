import { Inject, EventEmitter, Injectable } from "@angular/core";

/** Wraps local storage with an api similar to angular-local-storage */
@Injectable()
export class LocalStorageService {
	private prefix = 'ls.';
	constructor(
	) {
	}
	public get(key:string):any {
		let value = window.localStorage.getItem(this.prefix + key);
		if(value === undefined)
			return null;
		if(typeof value !== 'string')
			return null;
		let valueObj = null;
		try {
			valueObj = JSON.parse(value);
		}catch(e) {
			return value; // It's a pure string
		}
		if(typeof valueObj !== 'string')
			return valueObj;
		// Now try to parse it again, to work around buggy old code, which stored some values double JSON encoded (ls.providerFilter for example)
		try {
			let valueObj2 = JSON.parse(valueObj);
			window.localStorage.setItem(this.prefix + key, valueObj); // Fix the double encoding right here
			return valueObj2;
		}catch(e) {
			return valueObj; // Okay, give up on that
		}
	}
	public add(key:string, value:any) {
		if(!key)
			return;
		if(value === null) {
			this.remove(key);
			return;
		}
		value = typeof value === 'object' ? JSON.stringify(value) : value;
		window.localStorage.setItem(this.prefix + key, value);
	}
	public remove(key:string) {
		window.localStorage.removeItem(this.prefix + key);
	}
}
