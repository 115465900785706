import { Component, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";
import { BaseComponent } from "../base.component";
import { InfostarsToolsService } from "../services/InfostarsTools.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { HotkeyConfig, HotkeysService, KEY_SEQ_TIMEOUT_MS } from "../services/hotkeys.service";
import { ByTrackTypePipe } from "../pipes/ByTrackType.pipe";

/** Renders the label of a menu item, by highlighting a character and registering a shortcut on it
 * When the shortcut is invoked, it will click the parent (or target if provided) element if it's a
 * <a> or add the hover class to it.
 */
@Component({
	selector: 'menu-label',
	templateUrl: './menu-label.html',
})
export class MenuLabelComponent extends BaseComponent implements OnChanges {
	@Input() label?:string;
	@Input() labelTransl?:string;
	@Input() trackType?:string;
	@Input() highlightChar:string;
	@Input() hotkeyCfg:Partial<HotkeyConfig>;
	@Input() addHover = false;
	@Input() target?:any;
	public labelHtml:SafeHtml;

	private hoverClass = 'hover';

	constructor(
		protected InfostarsTools: InfostarsToolsService,
		protected Hotkeys: HotkeysService,
		protected ByTrackTypePipe: ByTrackTypePipe,
		protected $translate: TranslateService,
		private $sanitizer: DomSanitizer,
		private elRef: ElementRef,
	) { super(InfostarsTools); }

	ngOnChanges(changes: SimpleChanges): void {
		this.setLabel();
	}

	ngOnInit() {
		this.setLabel();
		this.subscribe(this.Hotkeys.addShortcut(this.hotkeyCfg), () => {
			const targetEl = this.target || this.elRef.nativeElement.parentElement;
			if(!this.addHover && targetEl.tagName.toLowerCase() === 'a') {
				targetEl.click();
				return;
			}
			if(this.addHover && !targetEl.classList.contains(this.hoverClass)) {
				targetEl.classList.add(this.hoverClass);
				setTimeout(() => {
					targetEl.classList.remove(this.hoverClass);
				}, KEY_SEQ_TIMEOUT_MS);
			}
		});
	}

	protected setLabel() {
		const labelTrans = this.labelTransl && this.labelTransl !== '' ? (this.transl(this.labelTransl) || ''): this.label;
		this.labelHtml = labelTrans;
		if(this.highlightChar) {
			const regex = new RegExp(`(${this.highlightChar.toLocaleLowerCase()}|${this.highlightChar.toLocaleUpperCase()})`);
			const labelTransHtml = labelTrans.replace(regex, (matchStr, g1) => `<span class="label-highlight">${g1}</span>`);
			this.labelHtml = this.$sanitizer.bypassSecurityTrustHtml(labelTransHtml);
		}
	}
	protected transl(key:string): string {
		return this.$translate.instant(this.trackType ? this.ByTrackTypePipe.transform(key, this.trackType) : key);
	}
}
