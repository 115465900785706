import { Pipe, PipeTransform } from "@angular/core";
import { orderBy } from 'lodash';

@Pipe({
	name: "orderBy",
	pure: false,
})
export class OrderByPipe implements PipeTransform {
	transform(array: any, sortBy: string, order?: string): any[] {
		if(!array || !array.length || !sortBy)
			return array;
		// console.log(`orderBy pipe called with sortBy: ${sortBy} and order: ${order}`);
		let sortOrder:'asc'|'desc' = 'asc'; // setting default ascending order
		if(order) {
			order = order === 'desc' ? 'desc' : 'asc';
		}else if(!order && sortBy.indexOf('-') === 0) {
			sortBy = sortBy.substring(1);
			sortOrder = 'desc';
		}else if(!sortOrder) {
			sortOrder = 'asc';
		}
		return orderBy(array, [sortBy], [sortOrder]);
	}
}
