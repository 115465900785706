
import { Injectable, Inject } from '@angular/core';
import { MessagesService } from './messages.service';
import { LocalStorageService } from './LocalStorage.service';
import { Restangular } from 'ngx-restangular';
import { LoginService } from './login.service';
import { BackendRestService } from './Restangular.service';
import { InfostarsToolsService } from './InfostarsTools.service';
import { forkJoin } from 'rxjs';
import { BaseComponent } from '../base.component';
;

@Injectable()
/** Everything related to translations for configuration data entered by admin users */
export class UserTranslationsService extends BaseComponent {
	private translationsPromise:Promise<any> = null;
	private currLangAccId = null as string;
	private userTranslationsDIT = null as any;
	private userTranslationsDITBool = null as any;
	private userTranslationsUsedDIT = null as any;
	private userTranslationsUsedDITBool = null as any;

	constructor(
		@Inject(InfostarsToolsService) protected InfostarsTools: InfostarsToolsService,
		@Inject(MessagesService) public Messages: MessagesService,
		@Inject(LocalStorageService) public LocalStorageService: LocalStorageService,
		@Inject(BackendRestService) public BackendRest: Restangular,
		@Inject(LoginService) public Login: LoginService,
	) {
		super(InfostarsTools);
		this.getData();
		this.subscribe(Login.login$, () => this.getData())
		this.subscribe(Login.accountId$, () => this.getData())
	}

	private filterBool(target:any, targetBool:any, dits:any[]) {
		for(let i = 0; i < dits.length; i++) {
			let dit = dits[i];
			target[dit.id] = dit;
			if(dit.dataType === 'BOOLEAN')
				targetBool[dit.id] = dit;
		}
	}
	private saveUserData(result:any[]) {
		this.userTranslationsDIT = {};
		this.userTranslationsDITBool = {};
		this.filterBool(this.userTranslationsDIT, this.userTranslationsDITBool, result[0]);
		this.userTranslationsUsedDIT = {};
		this.userTranslationsUsedDITBool = {};
		this.filterBool(this.userTranslationsUsedDIT, this.userTranslationsUsedDITBool, result[1]);
	}
	private clearData() {
		this.currLangAccId = null;
		this.userTranslationsDIT = null;
		this.userTranslationsDITBool = null;
		this.userTranslationsUsedDIT = null;
		this.userTranslationsUsedDITBool = null;
		this.translationsPromise = null;
	}
	private getData() {
		this.translationsPromise = this.Login.getLoginPromise().then(() =>  {
			let langAccId = this.activeLang + '_' + this.Login.getAccountId();
			if(this.currLangAccId === langAccId) // Prevent redundant requests
				return Promise.resolve();
			let params = {locale: this.activeLang, accountId: this.Login.getAccountId()};
			return forkJoin([this.BackendRest.one('usertranslation/datainterfacetype').get(params),
							this.BackendRest.one('usertranslation/datainterfacetype/used').get(params)]).toPromise().then((result:any[]) => {
				this.saveUserData(result);
				this.currLangAccId = langAccId;
			}, () => {
				this.clearData();
			});
		}, () => {
			// Nothing todo, will re-try after login
		});
		return this.translationsPromise;
	}
	public getPromise(/*update*/) {
		if(this.translationsPromise)
			return this.translationsPromise;
		return this.getData();
	}
	public getDataInterfaceTypes() {
		return this.userTranslationsDIT;
	}
	public getDataInterfaceTypesBool() {
		return this.userTranslationsDITBool;
	}
	public getDataInterfaceTypesUsed() {
		return this.userTranslationsUsedDIT;
	}
	public getDataInterfaceTypesUsedBool() {
		return this.userTranslationsUsedDITBool;
	}
	public isBool(dit:any) {
		return dit.dataType === 'BOOLEAN';
	}
}
